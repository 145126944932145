import Decimal from "decimal.js";

/**
 * `new Decimal(1)`
 */
export const DecimalOne = new Decimal(1);

/**
 * `new Decimal(0)`
 */
export const DecimalZero = new Decimal(0);

export function roundToPrecision(big: Decimal, round = 2, precision = 9) {
  return big.lt(new Decimal(10).pow(precision - round))
    ? big.toFixed(round)
    : big.toPrecision(precision);
}
