'use strict';

angular.module('meanApp')
  .factory('DataM', function DataM($location, $rootScope, $http, ClientData, UserData, UserList, FindByEmail, UserWithId, PushFlex, ClientFiles, ClientFile, UpdateEmail, UserPermission, AdminSettings, SuitabilityData, LoanStructure, LoanStructureSnapshot, FormStack, ResetAuthenticator, IndustryDivision, IndustryClass, OptiMessageStatus /*, $cookieStore, $q*/) {

    return {
      getMe: function (callback) {
        var cb = callback || angular.noop;
        return UserData.getMe(
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      setViewing: function (csData, callback) {
        var cb = callback || angular.noop;
        return UserData.setViewing(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      createPartner: function (csData, callback) {
        var cb = callback || angular.noop;
        return UserData.createPartner(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getPartnerActivated: function (callback) {
        var cb = callback || angular.noop;
        return UserData.partnerActivated(
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getIsFuturePlansEnabled: function (csData, callback) {
        var cb = callback || angular.noop;
        return UserWithId.getIsFuturePlansEnabled(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      setIsFuturePlansEnabled: function (csData, callback) {
        var cb = callback || angular.noop;
        return UserWithId.setIsFuturePlansEnabled(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      // Wealth Speed Service
      getIsWealthSpeedEnabled: function(csData, callback) {
        var cb = callback || angular.noop;
        return UserWithId.getIsWealthSpeedEnabled(csData,
          function(csData) {
            return cb(csData);
          },
          function(err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      setIsWealthSpeedEnabled: function(csData, callback) {
        var cb = callback || angular.noop;
        return UserWithId.setIsWealthSpeedEnabled(csData,
          function(csData) {
            return cb(csData);
          },
          function(err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      // End - Wealth Speed Service
      getUserType: function(csData, callback) {
        var cb = callback || angular.noop;
        return UserWithId.getType(csData,
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getUserRole: function(csData, callback) {
        var cb = callback || angular.noop;
        return UserWithId.getRole(csData,
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getAuthenticator: function (csData, callback) {
        var cb = callback || angular.noop;
        return UserWithId.getAuthenticator(csData,
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getPrimaryPartnerDetails: function (csData, callback) {
        var cb = callback || angular.noop;
        return UserWithId.getPrimaryPartnerDetails(csData,
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getClientSurvey: function (callback) {
        var cb = callback || angular.noop;
        return ClientData.getClientSurvey(
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      saveClientSurvey: function (csData, callback) {
        var cb = callback || angular.noop;
        return ClientData.saveClientSurvey(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      getPersonalInfo: function (callback) {
        var cb = callback || angular.noop;
        return ClientData.getPersonalInfo(
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      savePersonalInfo: function (csData, callback) {
        var cb = callback || angular.noop;
        return ClientData.savePersonalInfo(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      getPersonalInfoWithFP: function (callback) {
        var cb = callback || angular.noop;
        return ClientData.getPersonalInfoWithFP(
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      savePersonalInfoWithFP: function (csData, callback) {
        var cb = callback || angular.noop;
        return ClientData.savePersonalInfoWithFP(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      saveClientAge: function (csData, callback) {
        var cb = callback || angular.noop;
        return ClientData.saveClientAge(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      getIncome: function (callback) {
        var cb = callback || angular.noop;
        return ClientData.getIncome(
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      saveIncome: function (csData, callback) {
        var cb = callback || angular.noop;
        return ClientData.saveIncome(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      getProperties: function (callback) {
        var cb = callback || angular.noop;
        return ClientData.getProperties(
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      saveProperties: function (csData, callback) {
        var cb = callback || angular.noop;
        return ClientData.saveProperties(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      getOtherAssets: function (callback) {
        var cb = callback || angular.noop;
        return ClientData.getOtherAssets(
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      saveOtherAssets: function (csData, callback) {
        var cb = callback || angular.noop;
        return ClientData.saveOtherAssets(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      getPropertyLoans: function (callback) {
        var cb = callback || angular.noop;
        return ClientData.getPropertyLoans(
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      savePropertyLoans: function (csData, callback) {
        var cb = callback || angular.noop;
        return ClientData.savePropertyLoans(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      getBillPayments: function (callback) {
        var cb = callback || angular.noop;
        return ClientData.getBillPayments(
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      saveBillPayments: function (csData, callback) {
        var cb = callback || angular.noop;
        return ClientData.saveBillPayments(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      getExpenses: function (callback) {
        var cb = callback || angular.noop;
        return ClientData.getExpenses(
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      saveExpenses: function (csData, callback) {
        var cb = callback || angular.noop;
        return ClientData.saveExpenses(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      getFutureHouseholdPlans: function (callback) {
        var cb = callback || angular.noop;
        return ClientData.getFutureHouseholdPlans(
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      saveFutureHouseholdPlans: function (csData, callback) {
        var cb = callback || angular.noop;
        return ClientData.saveFutureHouseholdPlans(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      getFutureIncomeChanges: function (callback) {
        var cb = callback || angular.noop;
        return ClientData.getFutureIncomeChanges(
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      saveFutureIncomeChanges: function (csData, callback) {
        var cb = callback || angular.noop;
        return ClientData.saveFutureIncomeChanges(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getPortfolioPlanning: function (callback) {
        var cb = callback || angular.noop;
        return ClientData.getPortfolioPlanning(
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      savePortfolioPlanning: function (csData, callback) {
        var cb = callback || angular.noop;
        return ClientData.savePortfolioPlanning(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      getSummary: function (callback) {
        var cb = callback || angular.noop;
        return ClientData.getSummary(
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      getMSSumarry: function (callback) {
        var cb = callback || angular.noop;
        return ClientData.getMSSumarry(
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      getClientDoc: function (csData, callback) {
        var cb = callback || angular.noop;
        return ClientData.getClientDoc(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getClientSummary: function (csData, callback) {
        var cb = callback || angular.noop;
        return ClientData.getClientSummary(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      clientMSSummary: function (csData, callback) {
        var cb = callback || angular.noop;
        return ClientData.clientMSSummary(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      saveSummary: function (csData, callback) {
        var cb = callback || angular.noop;
        return ClientData.saveSummary(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      saveShortFactFind: function (csData, callback) {
        var cb = callback || angular.noop;
        return ClientData.saveShortFF(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getSuitability: function (csData, callback) {
        var cb = callback || angular.noop;
        return SuitabilityData.getSuitability(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getSuitabilityList: function (csData, callback) {
        var cb = callback || angular.noop;
        return SuitabilityData.getSuitabilityList(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      saveSuitability: function (csData, callback) {
        var cb = callback || angular.noop;
        return SuitabilityData.saveSuitability(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getSuitabilityReferenceIdList: function (csData, callback) {
        var cb = callback || angular.noop;
        return SuitabilityData.getSuitabilityReferenceIdList(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      lockSuitability: function (csData, callback) {
        var cb = callback || angular.noop;
        return SuitabilityData.lockSuitability(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      updateReferenceId: function (csData, callback) {
        var cb = callback || angular.noop;
        return SuitabilityData.updateReferenceId(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      getDocumentCollectionSetting: function (csData, callback) {
        var cb = callback || angular.noop;
        return ClientData.getDocumentCollectionSetting(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      saveDocumentCollectionSetting: function (csData, callback) {
        var cb = callback || angular.noop;
        return ClientData.saveDocumentCollectionSetting(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getMBWLendingFlexSetting: function (csData, callback) {
        var cb = callback || angular.noop;
        return ClientData.getMBWLendingFlexSetting(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      saveMBWLendingFlexSetting: function (csData, callback) {
        var cb = callback || angular.noop;
        return ClientData.saveMBWLendingFlexSetting(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getMoneySmartsSetting: function (csData, callback) {
        var cb = callback || angular.noop;
        return ClientData.getMoneySmartsSetting(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      saveMoneySmartsSetting: function (csData, callback) {
        var cb = callback || angular.noop;
        return ClientData.saveMoneySmartsSetting(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      getPropertyPortfolioSetting: function (csData, callback) {
        var cb = callback || angular.noop;
        return ClientData.getPropertyPortfolioSetting(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      savePropertyPortfolioSetting: function (csData, callback) {
        var cb = callback || angular.noop;
        return ClientData.savePropertyPortfolioSetting(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      getMaternityVersionSetting: function (csData, callback) {
        var cb = callback || angular.noop;
        return ClientData.getMaternityVersionSetting(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      saveMaternityVersionSetting: function (csData, callback) {
        var cb = callback || angular.noop;
        return ClientData.saveMaternityVersionSetting(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      getSuitabilitySetting: function (csData, callback) {
        var cb = callback || angular.noop;
        return ClientData.getSuitabilitySetting(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      saveSuitabilitySetting: function (csData, callback) {
        var cb = callback || angular.noop;
        return ClientData.saveSuitabilitySetting(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getUserList: function (callback) {
        var cb = callback || angular.noop;
        return UserList.getList(
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      getUserWithId: function (id, callback) {
        var cb = callback || angular.noop;
        return UserWithId.getEmail({ id: id },
          function (data) {
            data.id = id;
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      findByEmail: function (email, callback) {
        var cb = callback || angular.noop;
        return FindByEmail.findByEmail(email,
          function (email) {
            return cb(email);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      getClientChanges: function (pageChanged, dateFrom, dateTo, tzOffset, offset, limit, callback) {
        var cb = callback || angular.noop;
        return ClientData.getClientChanges({ pageChanged, dateFrom, dateTo, tzOffset, offset, limit },
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      getAllClients: function (callback) {
        var cb = callback || angular.noop;
        return ClientData.getAllClients(
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      searchClients: function (searchTerm, callback) {
        var cb = callback || angular.noop;
        return ClientData.searchClients({ searchTerm: searchTerm },
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      pushFlex: function (userData, callback) {
        var cb = callback || angular.noop;

        return PushFlex.pushFlex(userData,
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      getFlexLog: function (userData, callback) {
        var cb = callback || angular.noop;

        return PushFlex.getFlexLog(userData,
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      getFlexMissingFields: function (userData, callback) {
        var cb = callback || angular.noop;

        return PushFlex.getFlexMissingFields(userData,
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      getFlexSummary: function (callback) {
        var cb = callback || angular.noop;

        return PushFlex.getFlexSummary(
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      getFlexSummaryOld: function (callback) {
        var cb = callback || angular.noop;

        return PushFlex.getFlexSummaryOld(
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      getClientFiles: function (clientData, callback) {
        var cb = callback || angular.noop;

        return ClientFiles.getList(clientData,
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      downloadClientFile: function (clientData, callback) {
        var cb = callback || angular.noop;

        return ClientFile.downloadFile(clientData,
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      deleteClientFile: function (clientData, callback) {
        var cb = callback || angular.noop;

        return ClientFile.deleteFile(clientData,
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      updateUserEmail: function (userData, callback) {
        var cb = callback || angular.noop;

        return UpdateEmail.updateEmail(userData,
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      checkPermission: function (userData, callback) {
        var cb = callback || angular.noop;

        return UserPermission.check(userData,
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getSettings: function (userData, callback) {
        var cb = callback || angular.noop;

        return AdminSettings.getList(
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getSetting: function (userData, callback) {
        var cb = callback || angular.noop;

        return AdminSettings.getPreference(userData,
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      saveSetting: function (userData, callback) {
        var cb = callback || angular.noop;
        return AdminSettings.savePreference(userData,
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      saveLoanStructure: function (userData, callback) {
        var cb = callback || angular.noop;
        return LoanStructure.save(userData,
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getLoanStructure: function (userData, callback) {
        var cb = callback || angular.noop;
        return LoanStructure.get(userData,
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getLoanStructureList: function (userData, callback) {
        var cb = callback || angular.noop;
        return LoanStructure.getList(userData,
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      getProposedProperty: function (userData, callback) {
        var cb = callback || angular.noop;
        return LoanStructure.getProperty(userData,
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      createProposedProperty: function (userData, callback) {
        var cb = callback || angular.noop;
        return LoanStructure.createProperty(userData,
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      updateProposedProperty: function (userData, callback) {
        var cb = callback || angular.noop;
        return LoanStructure.updateProperty(userData,
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      removeProposedProperty: function (userData, callback) {
        var cb = callback || angular.noop;
        return LoanStructure.deleteProperty(userData,
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getProposedPropertyList: function (userData, callback) {
        var cb = callback || angular.noop;
        return LoanStructure.getPropertyList(userData,
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      renameSnapshot: function (userData, callback) {
        var cb = callback || angular.noop;
        return LoanStructureSnapshot.rename(userData,
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      deleteSnapshot: function (userData, callback) {
        var cb = callback || angular.noop;
        return LoanStructureSnapshot.delete(userData,
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      getProvisioning: function (userData, callback) {
        var cb = callback || angular.noop;
        return ClientData.getProvisioning(userData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      putProvisioning: function (userData, callback) {
        var cb = callback || angular.noop;
        return ClientData.putProvisioning(userData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      getMonthlyBalance: function (userData, callback) {
        var cb = callback || angular.noop;
        return ClientData.getMonthlyBalance(userData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getSampleHistoryMerge: function (userData, callback) {
        var cb = callback || angular.noop;
        return ClientData.getSampleHistoryMerge(userData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      restoreClientWithHistory: function (userData, callback) {
        var cb = callback || angular.noop;
        return ClientData.restoreClientWithHistory(userData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      putMonthlyBalance: function (userData, callback) {
        var cb = callback || angular.noop;
        return ClientData.putMonthlyBalance(userData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getMBWarningEnabled: function (csData, callback) {
        var cb = callback || angular.noop;
        return ClientData.getMBWarningEnabled(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      saveMBWarningEnabled: function (csData, callback) {
        var cb = callback || angular.noop;
        return ClientData.saveMBWarningEnabled(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      saveUserType: function (csData, callback) {
        var cb = callback || angular.noop;
        return UserWithId.saveType(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      saveAuthenticator: function (csData, callback) {
        var cb = callback || angular.noop;
        return UserWithId.saveAuthenticator(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      submitFSForm: function (csData, callback) {
        var cb = callback || angular.noop;
        return FormStack.submit(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      submitInternalFSForm: function (csData, callback) {
        var cb = callback || angular.noop;
        return FormStack.submitInternal(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      saveFSForm: function (csData, callback) {
        var cb = callback || angular.noop;
        return FormStack.save(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getFSForm: function (csData, callback) {
        var cb = callback || angular.noop;
        return FormStack.getForm(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getFSFormWithId: function (csData, callback) {
        var cb = callback || angular.noop;
        return FormStack.getFormWithId(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getFSFormRefList: function (csData, callback) {
        var cb = callback || angular.noop;
        return FormStack.getFormRefList(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getFSFormHistoryList: function (csData, callback) {
        var cb = callback || angular.noop;
        return FormStack.getFormHistoryList(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      updateFormReferenceId: function (csData, callback) {
        var cb = callback || angular.noop;
        return FormStack.updateFormReferenceId(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      addFSForm: function (csData, callback) {
        var cb = callback || angular.noop;
        return FormStack.addForm(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getFSFormFields: function (csData, callback) {
        var cb = callback || angular.noop;
        return FormStack.getFormFields(csData,
          function (csData) {
            return cb(csData);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;

      },
      resetUser2FA: function (userData, callback) {
        var cb = callback || angular.noop;

        return ResetAuthenticator.resetUser2FA(userData,
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },

      // Get Industries
      getIndustryDivisions: function (userData, callback) {
        var cb = callback || angular.noop;

        return IndustryDivision.getIndustryDivisions(userData,
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getIndustryClasses: function (userData, callback) {
        var cb = callback || angular.noop;

        return IndustryClass.getIndustryClasses(userData,
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      getOptiMessageCurrentUserStatus: function (userData, callback) {
        var cb = callback || angular.noop;
        return OptiMessageStatus.getCurrentUserStatus(userData,
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      },
      updateOptiMessageAction: function (userData, callback) {
        var cb = callback || angular.noop;
        return OptiMessageStatus.updateAction(userData,
          function (data) {
            return cb(data);
          },
          function (err) {
            return cb(err);
          }.bind(this)
        ).$promise;
      }
    };
  });
