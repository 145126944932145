export function calcYearly(amount: number, frequency: string) {
  switch (frequency) {
    case "Weekly":
      return amount * 52;
    case "Fortnightly":
      return amount * 26;
    case "Monthly":
      return amount * 12;
    case "Every 2 months":
      return amount * 6;
    case "Every 3 months":
      return amount * 4;
    case "Every 6 months":
      return amount * 2;
    case "Yearly":
    default:
      return amount;
  }
}

export function calcMonthly(amount: number, frequency: string) {
  return calcYearly(amount, frequency) / 12;
}
