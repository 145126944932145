import queryApi from "./query-api";

type FeatureFlag = {
  identifier: string;
  config: string;
};

type FeatureFlagsQueryResponse = {
  featureFlags?: FeatureFlag[] | null;
  me?: {
    featureFlags: FeatureFlag[] | null;
  } | undefined;
  user?: {
    featureFlags: FeatureFlag[] | null;
  } | undefined
};

export default async function hasFlag(
  name: string,
  userId?: string
) {
  const flagQuery = `
      featureFlags {
        identifier
        config
      }
  `;

  const query = `query ${ userId ? '($userId: ObjectId)' : '' } {
    ${ userId ? 'user(id: $userId)' : 'me' }{
      ${ flagQuery }
    }
  }`

  // Call GraphQL
  const response = await queryApi<FeatureFlagsQueryResponse>(
    query,
    { userId: userId }
  );


  if (!response) return false;
  const flags = response.me?.featureFlags || response?.user?.featureFlags || response?.featureFlags;
  const identifier = name.toLowerCase();
  const feature = flags?.find((flag : any) => flag.identifier == identifier);
  return feature ? true : false;
}