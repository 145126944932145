import queryUserId from '../../components/data/moorr/queryUserId'

angular
  .module("meanApp")
  .controller("property-portfolio-controller", function(
    $scope,
    $stateParams,
    Auth,
    $rootScope,
    ReactWrapper
  ) {
    "use strict";

    // check if user is admin
    $scope.isAdmin = false;
    Auth.isAdmin().then((isAdmin) => {
      $scope.isAdmin = isAdmin;

      queryUserId($stateParams.clientId).then((uid) => {
        const wrapper = new ReactWrapper($scope);
        import('PortfolioPlanReport').then((PropertyPortfolio) => {
          wrapper.setup(PropertyPortfolio);
          wrapper.bind(document.getElementById("react-wrapper-portfolio-plan"));
          wrapper.mount(PropertyPortfolio.default, {
            clientUID: uid
          });
        });
      });
  });
});