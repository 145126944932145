// NOTE Useful to be copied into other repo that needs the access JWT

/* eslint-disable no-await-in-loop */
import { delayAsync } from "../time-lib";
import { getDecodedAccessJWT } from "./access-jwt-lib";
import getVerifiedRefreshJWT from "./refresh-jwt-lib";

const DEFAULT_INTERVAL_MS = (1000 / 3) * 2;

export async function tryToGetAccessJWT(intervalMs = DEFAULT_INTERVAL_MS) {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    /**
     * `null` if expired
     */
    const accessJWT = await getDecodedAccessJWT().then((result) => {
      if (result.decoded.exp * 1000 < Date.now()) {
        return null;
      }

      return result;
    });

    if (accessJWT) {
      return accessJWT;
    }

    await getVerifiedRefreshJWT();

    await delayAsync(intervalMs);
  }
}
