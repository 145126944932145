"use strict";

angular.module("meanApp").config(function($stateProvider,) {
  $stateProvider.state("mbworkbench", {
    url: "/mbworkbench/:clientId",
    params: {
      clientId: {
        value: null,
        squash: false
      }
    },
    templateUrl: "app/mbWorkbench/mbWorkbench.html",
    controller: "mbWorkbench-controller",
    authenticate: ["admin"]
    
  })
  .state('mbworkbench.dashboard', {
    url: '/dashboard/:clientId',
    template: ""
  })
  .state('mbworkbench.proposed-lending', {
    url: '/proposed-lending/:clientId',
    template: ""
  })
  .state('mbworkbench.lending-surveys', {
    url: '/lending-surveys/:clientId',
    template: ""
  })
  .state('mbworkbench.id-verification', {
    url: '/id-verification/:clientId',
    template: ""
  })
  .state('mbworkbench.pre-assessment', {
    url: '/pre-assessment/:clientId',
    template: ""
  })
  .state('mbworkbench.lending-form', {
    url: '/lending-form/:clientId',
    template: ""
  })
  .state('mbworkbench.client-notes', {
    url: '/client-notes/:clientId',
    template: ""
  })
})