import queryApi, { gql } from "../graphql/query-api";

const flexDetailsQuery = (wrapper: string, args: string) => `
query Query ${args} {
  ${wrapper} {
    client {
      personalInfo {
        client1 {
          flexId
          fName
          lName
        }
        client2 {
          fName
          lName
          flexId
        }
      }
    }
  }
}`;

export default async function queryFlexDetails(uid?: string) {
  const query = uid ? flexDetailsQuery("user(id: $userId)", "($userId: ObjectId)") : flexDetailsQuery("me", "");
  return queryApi<any>(
    query.trim().replace(/\s+/g, ' '),
    { userId: uid }
  ).then((response) => {
    const client = response.user?.client || response.me?.client;
    if (client) {
      return client;
    } else {
      return undefined;
    }

    return Promise.reject(new Error("invalid-response"));
  });
}
