import queryHomeDashboard from '../../components/data/moorr/queryHomeDashboard'
import hasFlag from '../../components/data/graphql/featureFlags'

angular
  .module("meanApp")

  .controller(
    "dashboard-controller-v2",
    function (
      $scope,
      DataM,
      Auth,
      $state,
      $stateParams,
      empowerDataService,
      EmpowerWealthHelper,
      $rootScope,
      $timeout,
      $q,
      EventHelper,
      ReactWrapper,
    ) {
      "use strict";
      import('FieldValidation').then((FieldValidation) => {
        const loadComponent = () => {
          const wealthWrapper = new ReactWrapper($scope);
          wealthWrapper.setup(FieldValidation);
          wealthWrapper.bind(document.getElementById("react_wrapper_wealth_dashboard"));
          wealthWrapper.mount(FieldValidation.HomeDashboardWealthSpeed, {
            clientUID: $stateParams.clientId,
            navigate: (path) => {
              window.dispatchEvent(new CustomEvent("sideBar.setActiveItem", {
                detail: {
                  item: "wealth-dashboard"
                }
              }));

              $state.go(path, { clientId: $stateParams.clientId });
            }
          });

          hasFlag("home.dashboard.propertyplandashboard").then(isActive => {
            if (isActive){
              const propDashboardWrapper = new ReactWrapper($scope);
              propDashboardWrapper.setup(FieldValidation);
              propDashboardWrapper.bind(document.getElementById("react_wrapper_property_plan_dashboard"));
              propDashboardWrapper.mount(FieldValidation.PropertyPlanDashboard, {
                clientUID: $stateParams.clientId,
              });
            }
          });
        };

        // Process pre-loaded data
        loadComponent();
      });

      $scope.homeDashboard = { isLoaded: false };
      $scope.renderUserSettingsModal = (params, callback_func) => {
        let module;
        const tasks = [];
        tasks.push(import('FieldValidation').then((FieldValidation) => {
          module = FieldValidation;
        }));

        return $q.all(tasks).then(function () {
          if (!module)
            return $q.reject();

          new EventHelper($scope).$trigger("openModal", {
            modal: "userSettings",
            props: params,
            then: callback_func,
          });
        });
      };

        // User settings
        function RenderNoLinkAccountsModal(params) {
          let module;
          const tasks = [];
          tasks.push(import('FieldValidation').then((FieldValidation) => {
            module = FieldValidation;
          }));
  
          return $q.all(tasks).then(function () {
            if (!module)
              return $q.reject();
  
            new EventHelper($scope).$trigger("openModal", {
              modal: "noLinkAccountsModal",
              props: params,
            });
  
          });
        };
        

      $scope.openNoLinkAccountsModal = function () {

        const handleOpenCardTray = () => {
          const rootElement = document.querySelector("#react-root");
          rootElement?.dispatchEvent(
            new CustomEvent("cardsPanel.setIsOpen", {
              detail: {
                isOpen: true
              }
            })
          )
        }

        let params = {
          showModal: true,
          handleOpenCardTray: handleOpenCardTray
        };

        RenderNoLinkAccountsModal(params);
      };

      $scope.decimals = 0;
      $scope.format = (value, negativeBrackets) => {
        if (negativeBrackets){
          const numberFormatter = new Intl.NumberFormat('en-AU', {
            style: 'decimal',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          });

          const formatted = numberFormatter.format(Math.abs(value));
          return value < 0 ? '($'+formatted+')' : '$'+formatted;
        }

        return Math.round(value || 0);
      };
      $scope.isAdmin = false;
      $scope.isLoaded = false;

      const loadDashboard = (isAdmin) => {
        queryHomeDashboard(isAdmin ? $stateParams.clientId : undefined).then(data => {
          // Onboarding redirect
          if (!isAdmin && !data.surveyComplete){
            if (data.userType === "ew"){
              $state.go('lite-fact-find');
              return;
            } else {
              $state.go('landing');
              return;
            }
          }

          // Load home dashboard
          $timeout(function (){
            if (data){
              $scope.homeDashboard = data.homeDashboard;
              $scope.propertyDashboard = data.propertyDashboard;
              $scope.homeDashboard.isLoaded = true;


              // inject client 1 & 2 total ownership percentage to properties
              if ($scope.propertyDashboard?.properties && Array.isArray($scope.propertyDashboard.properties)
                  && $scope.propertyDashboard.properties.length > 0) {
                var client1Id = data.homeDashboard?.personalInfo?.client1?._id
                var client2Id = data.homeDashboard?.personalInfo?.client2?._id

                $scope.propertyDashboard.properties.forEach((property) => {
                  var owners = property?.ownership?.owners || []
                  var client1OwnershipPercentage = owners.find(({owner}) => owner === client1Id)?.percentage || 0
                  var client2OwnershipPercentage = owners.find(({owner}) => owner === client2Id)?.percentage || 0

                  property.combinedOwnershipPercentage = client1OwnershipPercentage + client2OwnershipPercentage
                })
              }
            }

            $scope.isLoaded = true;
            $scope.generateCharts();

            // Handle show skip2FA dialog
            if (!isAdmin && sessionStorage["showSettingsModalOnDashboard"]){
              delete sessionStorage["showSettingsModalOnDashboard"];

              $scope.renderUserSettingsModal({
                uid: undefined,
                showModal: true,
              }, () => {});
            }
          });
        });
      };

      Auth.isAdmin().then((isAdmin) => {
        $scope.isAdmin = isAdmin;

        // Check for redirect
        hasFlag("home.dashboard.twodecimals").then(isActive => {
          if (isActive){
            $scope.decimals = 2;
            $scope.format = (value, negativeBrackets) => {
              if (negativeBrackets){
                const numberFormatter = new Intl.NumberFormat('en-AU', {
                  style: 'decimal',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                });

                const formatted = numberFormatter.format(Math.abs(value));
                return value < 0 ? '($'+formatted+')' : '$'+formatted;
              }

              return Math.round((value || 0) * 100) / 100
            };
          }

          loadDashboard(isAdmin);

          const reactRoot = $('#react-root');
          if (reactRoot.length) {
            const handler = () => { loadDashboard(isAdmin); };
            reactRoot[0].addEventListener("cardsPanel.onClose", handler);
            $scope.$on('$destroy', () => {
              reactRoot[0].removeEventListener("cardsPanel.onClose", handler);
            });
          }
        });
      });

      let propertyColorIndex = 0;
      const propertyColorAssignments = {};
      const propertyColors = ["#563761", "#A7425C", "#F3825F", "#A9C52F", "#228896", "#283739"];

      $scope.getPropertyColor = (id) => {
        if (propertyColorAssignments[id])
          return propertyColorAssignments[id];

        return propertyColorAssignments[id] = propertyColors[propertyColorIndex++ % propertyColors.length];
      }

      const propertyNameIndex = {};
      $scope.getPropertyName = (id) => {
        if (propertyNameIndex[id])
          return propertyNameIndex[id];

        const p = $scope.propertyDashboard?.properties?.find((p) => p._id === id);
        return propertyNameIndex[id] = p?.address || p?.name;
      }

      $scope.incomeByType = (type, client) => {
        if (!client)
          return ($scope.incomeByType(type, "client1Income") || 0) + ($scope.incomeByType(type, "client2Income") || 0);

        return $scope.homeDashboard?.[client]?.types?.find(item => item.key === type)?.value;
      }


      $scope.deductionsByType = (type, client) => {
        if (!client)
          return ($scope.deductionsByType(type, "client1Income") || 0) + ($scope.deductionsByType(type, "client2Income") || 0);

        return $scope.homeDashboard?.[client]?.deductions?.find(item => item.key === type)?.value;
      }

      $scope.assetByType = (type) => {
        return $scope.homeDashboard?.assets?.types?.find(item => item.key === type)?.value;
      }

      $scope.expenseByType = (unit, type) => {
        const amount = $scope.homeDashboard?.expenses?.yearly?.breakdown?.find(item => item.key === type)?.total;
        return unit === "yearly" ? amount : amount / 12;
      }

      $scope.outstandingByType = (type) => {
        return $scope.homeDashboard?.borrowings?.outstanding?.types?.find(item => item.key === type)?.value;
      }

      $scope.grossIncome = (total) => {
        // Income
        const income = [
          $scope.incomeByType("PAYG"),
          $scope.incomeByType("Other Income"),
          $scope.incomeByType("Business"),
          $scope.incomeByType("Self Employed"),
          $scope.incomeByType("Investment Property"),
          $scope.incomeByType("Investment"),
          $scope.homeDashboard.client1Income?.nonTaxable +  // Note must be a single array item (do not split)
          $scope.homeDashboard.client2Income?.nonTaxable,
        ].map(v => $scope.format(v));

        return total ? income.reduce((last, current) => last + current, 0) : income;
      }

      $scope.generateCharts = () => {
        $scope.data = $scope.grossIncome().map((v) => $scope.format(v / 12));
        $scope.labels = ["Gross PAYG Income","Gross Other Income","Gross Business Income","Gross Self Employed Income","Gross Rental Income", "Gross Investment Income", "Non-Taxable Income"];
        $scope.colors = ["#A3D12D","#8CC230", "#75B334","#5DA437","#46953B", "#2F863E", "#2F863E"];

        // Expenses
        const expenses = $scope.homeDashboard?.expenses?.yearly?.breakdown || [];
        $scope.data2 = [];
        $scope.labels2 = [];
        for (const t of expenses){
          $scope.data2.push($scope.format(t.total / 12));
          $scope.labels2.push(t.key);
        }
        $scope.colors2 = ["#f22525","#E02323","#CD2121","#b51b1b","#b21e1e","#b21e1f","#b21e1f","#b21e1f"];

        const assets = $scope.homeDashboard?.assets?.types || [];
        const assetTypes = {
          "assets.bankAccounts": "Bank Accounts",
          "assets.properties": "Properties",
          "assets.investments": "Investments",
          "assets.vehicles": "Vehicles",
          "assets.superFunds": "Super Funds",
          "assets.otherAssets": "Other Assets",
        };

        // Do not show in assets graph:
        // "assets.lifeInsurance": "Life Insurance",

        $scope.data3 = [];
        $scope.labels3 = [];
        for (const t of assets){
          $scope.data3.push($scope.format(t.value));
          $scope.labels3.push(assetTypes[t.key] || "");
        }
        $scope.colors3 = ["#FC580C","#FC6F18","#FC8624","#FD9C31","#FDB33D","#FDBB41","#FDCA49","#FDCA49","#FDCA49"];
      }

      $scope.chartOption = {
        cutoutPercentage: 64,
        tooltipEvents: [],

        tooltips: {
          bodyFontSize: 18,
          callbacks: {
            label: function (tooltipItem, data) {
              var value = data.datasets[0].data[tooltipItem.index];
              var datasetLabel =
                data.datasets[tooltipItem.datasetIndex].label || "Other";
              var label = data.labels[tooltipItem.index];
              var hello = value
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              return "$" + hello;
            },
            title: function (tooltipItem, data) {
              var title = data.labels[tooltipItem[0].index];
              return title;
            },
          },
        },
        animation: {
          duration: 1500,
          onComplete: function () { },
        },
        maintainAspectRatio: false,
      };
    }
  )
  .directive("linearChart", function ($window) {
    return {
      restrict: "EA",
      template: "<svg width='850' height='200'></svg>",
      link: function (scope, elem, attrs) {
        var salesDataToPlot = scope[attrs.chartData];
        var padding = 20;
        var pathClass = "path";
        var xScale, yScale, xAxisGen, yAxisGen, lineFun;

        var d3 = $window.d3;

        var rawSvg = elem.find("svg");
        var svg = d3.select(rawSvg[0]);

        function setChartParameters() {
          xScale = d3
            .scaleLinear()
            .domain([
              salesDataToPlot[0].hour,
              salesDataToPlot[salesDataToPlot.length - 1].hour,
            ])
            .range([padding + 5, rawSvg.attr("width") - padding]);

          yScale = d3
            .scaleLinear()
            .domain([
              0,
              d3.max(salesDataToPlot, function (d) {
                return d.sales;
              }),
            ])
            .range([rawSvg.attr("height") - padding, 0]);

          xAxisGen = d3
            .axisBottom()
            .scale(xScale)
            .ticks(salesDataToPlot.length - 1);

          yAxisGen = d3.axisLeft().scale(yScale).ticks(5);

          lineFun = d3
            .line()
            .x(function (d) {
              return xScale(d.hour);
            })
            .y(function (d) {
              return yScale(d.sales);
            })
            .curve(d3.curveBasis);
        }

        function drawLineChart() {
          setChartParameters();

          svg
            .append("svg:g")
            .attr("class", "x axis")
            .attr("transform", "translate(0,180)")
            .call(xAxisGen);

          svg
            .append("svg:g")
            .attr("class", "y axis")
            .attr("transform", "translate(20,0)")
            .call(yAxisGen);
          svg
            .append("svg:path")
            .attr("d", lineFun(salesDataToPlot))
            .attr("stroke", "blue")
            .attr("fill", "none")
            .attr("stroke-width", 2)
            .attr("class", pathClass);
        }

        drawLineChart();
      },
    };
  });
