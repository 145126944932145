angular.module("meanApp")
    .controller("lending-form-controller", function (
        $scope, $rootScope, Auth, DataM, $stateParams, $uibModal, $state, DataHelper, $timeout) {
        "use strict";

        const default_visible_sections = [
            'Opportunity and Application Instructions',
            'Supporting Documents',
            'Application Funds to Complete',
            'Opportunity Build Instructions',
            'Compliance',
            'Requirements and Objectives',
            'Face to Face',
            'Living Expenses',
            'Broker Fact Find',
            'Income Breakdown',
            'Notes for the Bank',
            'Lender Specific Requirements',
            'General Instructions and Tasks',
            'Final Reminders'
        ];

        $scope.assets = {};
        $scope.result = {};
        $scope.formFieldWith_ = [];
        $scope.formData = {};
        $scope.formData_fresh = {}; // unmerged data fetched from DB for submitted summary. mutated by removing the last character if `_` for the review.
        $scope.owners = []
        $scope.formFields = [];
        $scope.referenceIdList = [];
        $scope.existing_and_proposed_properties = []
        $scope.showSpinner = false;
        $scope.isAdmin = Auth.isAdmin();
        $scope.savedText = 'Save';
        $scope.initReferenceId = "Lending Form " + new Date().toLocaleDateString("en-AU");
        $scope.resubmitpopupinfo = "<strong>Note:</strong> <p>Please be advised to email Smart Move about the double submission</p>"
        $scope.clientInfo = {
            client1Name: '',
            client2Name: ''
        }
        $scope.selectedFormstackData = {
            referenceId: $scope.initReferenceId
        }
        Auth.isAdmin().then(isAdmin => ($scope.isAdmin = isAdmin));
        $scope.readyToSubmit = false;

        /* ===============================================[ Scope Methods ]==============================*/
        $scope.add_number_of_lodgements_to_build = () => {
            $scope.formData.number_of_lodgements_to_build = parseInt($scope.formData.number_of_lodgements_to_build) + 1;
        }
        $scope.minus_number_of_lodgements_to_build = () => {
            const index = $scope.formData.number_of_lodgements_to_build;
            for (const key in $scope.formData) {
                if (key.indexOf('l' + index) >= 0 || key.indexOf('_' + index) >= 0) {
                    delete $scope.formData.total_loan_amount
                    delete $scope.formData[key]
                }
            }
            $scope.formData["property_with_proposed_loans_l" + index] = null;
            $scope.formData["number_of_loan_splits_for_lodgement_" + (index) + "_l" + (index)] = null;
            $scope.formData.number_of_lodgements_to_build--;
        }
        $scope.autoExpand = e => {
            let element = typeof e === 'object' ? e.target : document.getElementById(e);
            const scrollHeight = element.scrollHeight; // replace 60 by the sum of padding-top and padding-bottom
            element.style.height = scrollHeight + "px";
        };
        $scope.numberOnBlurHandler = e => {
            let element = e.target
            if(Math.round(Number(e.target.value)) !== Number(e.target.value)) {
                const newVal = Number(e.target.value).toFixed(2)
                element.value = newVal
            }
        }
        $scope.getReferenceItemStatus = data => {
            if (data.submissionId) {
                return 'SUBMISSION ID - ' + data.submissionId + ' - ' + moment(data.timestamp).format('DD/MM/YYYY')
            }
            else if(data.isSubmittedInternally) {
                return 'ACTIVE - Submitted Internally ' + moment(data.timestamp).format('DD/MM/YYYY - h:mma')
            }
            else {
                return 'ACTIVE - Last saved: ' + moment(data.timestamp).format('DD/MM/YYYY - h:mma')
            }
        }
        $scope.openResult = () => {
            $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: 'app/lendingForm/modals/response-popup.html',
                controller: 'ResponseCtrl',
                resolve: {
                    result: () => {
                        return $scope.result;
                    }
                }
            });
        }
        $scope.backToList = () => {
            $state.go('lendingForm', {
                clientId: $stateParams.clientId
            });
        }
        $scope.showEditRefId = () => {
            const modalInstance = $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: 'app/lendingForm/modals/edit-ref-id.html',
                controller: 'EditReferenceIdCtrl',
                resolve: {
                    currentReferenceId: () => {
                        return $scope.selectedFormstackData.referenceId;
                    }
                }
            });
            modalInstance.result.then(result => {
                $scope.selectedFormstackData.referenceId = result.newReferenceId;
            }, () => { });
        }
        $scope.loadInternalSubmitReview = function(selected_id) {
            $state.go('internalSubmitReview', {
                formId: selected_id,
                clientId: $stateParams.clientId
            });
        }
        $rootScope.loadLendingForm = selected_id => {
            $state.go('lendingFormUpdate', {
                formId: selected_id,
                clientId: $stateParams.clientId
            });
        }
        $scope.showFormHistory = () => {
            $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: 'app/lendingForm/modals/history-list.html',
                controller: 'LendingFormHistoryListCtrl',
                resolve: {
                    referenceId: () => {
                        return $scope.selectedFormstackData.referenceId;
                    },
                    clientId: () => {
                        return $stateParams.clientId;
                    },
                    formId: () => {
                        return $scope.selectedFormstackData.id;
                    }
                }
            });
        }
        $scope.showFormList = () => {
            $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: 'app/lendingForm/modals/form-list.html',
                controller: 'ReferenceIdListCtrl',
                resolve: {
                    clientId: () => {
                        return $stateParams.clientId;
                    },
                    currentReferenceId: () => {
                        return $scope.selectedFormstackData.referenceId;
                    }
                }
            });
        }
        $scope.submit = () => {
            if(!$scope.readyToSubmit) return;

            $scope.showSpinner = true;
            filterFormData();
            for (let k in $scope.formData) {
                $scope.formData[k] = (!isNaN(Number($scope.formData[k]))) ? Number($scope.formData[k]) : $scope.formData[k];
            }
            let payload = {
                formData: $scope.formData,
                referenceId: $scope.selectedFormstackData.referenceId
            };
            if ($scope.selectedFormstackData.submissionId && $scope.selectedFormstackData.submissionId.length > 0) {
                payload.submissionId = $scope.selectedFormstackData.submissionId;
            }
            DataM.submitFSForm(payload).then(data => {
                $scope.showSpinner = false;
                $scope.savedText = 'Saved';
                $scope.result = {
                    success: true,
                    submissionId: data.submissionId
                }
                loadFormData(data);
                $scope.openResult();
            }).catch(error => {
                $scope.showSpinner = false;
                $scope.result = {
                    success: false,
                    error: error
                }
                $scope.openResult();
            });
        }
        $scope.save = () => {
            $scope.showSpinner = true;
            filterFormData();
            for (let k in $scope.formData) {
                $scope.formData[k] = (!isNaN(Number($scope.formData[k]))) ? Number($scope.formData[k]) : $scope.formData[k]; // Making sure submitted types are number if number
            }

            // clear loan splits based on number of lodgements to build
            for (let x = ($scope.formData.number_of_lodgements_to_build + 1); x <= 5; x++) {
                delete $scope.formData["number_of_loan_splits_for_lodgement_" + x + "_l" + x]
            }

            let payload = {
                formData: $scope.formData,
                referenceId: $scope.selectedFormstackData.referenceId
            }
            if ($scope.selectedFormstackData.submissionId && $scope.selectedFormstackData.submissionId.length > 0) {
                payload.submissionId = $scope.selectedFormstackData.submissionId;
            }
            DataM.saveFSForm(payload).then(data => {
                $scope.showSpinner = false;
                $scope.savedText = 'Saved';
                $scope.result = {
                    success: true,
                    submissionId: null
                }
                loadFormData(data);
                $scope.openResult();
            }).catch(error => {
                $scope.showSpinner = false;
                $scope.result = {
                    success: false
                }
                $scope.showSpinner = false;
                $scope.result = {
                    success: false,
                    error: error
                }
                $scope.openResult();
            });
        };
        $scope.submitInternally = () => {
            if(!$scope.readyToSubmit) return;
            $scope.showSpinner = true;
            filterFormData();
            for (let k in $scope.formData) {
                $scope.formData[k] = (!isNaN(Number($scope.formData[k]))) ? Number($scope.formData[k]) : $scope.formData[k];
            }
            let payload = {
                formData: $scope.formData,
                referenceId: $scope.selectedFormstackData.referenceId
            };
            if ($scope.selectedFormstackData.submissionId && $scope.selectedFormstackData.submissionId.length > 0) {
                payload.submissionId = $scope.selectedFormstackData.submissionId;
            }
            DataM.submitInternalFSForm(payload).then(function (data) {
                loadFormData(data);
                $state.go('internalSubmitReview', {
                    clientId: $stateParams.clientId,
                    formId: data._id
                });
                $scope.showSpinner = false;
            }).catch(function (error) {
                $scope.showSpinner = false;
                $scope.result = {
                    success: false,
                    error: error
                }
            });
        }  
        $scope.resubmit = () => {
            if(!$scope.readyToSubmit) return;
            $scope.showSpinner = true;
            filterFormData();
            for (let k in $scope.formData) {
                $scope.formData[k] = (!isNaN(Number($scope.formData[k]))) ? Number($scope.formData[k]) : $scope.formData[k];
            }
            let payload = {
                formData: $scope.formData,
                referenceId: $scope.selectedFormstackData.referenceId
            };
            DataM.submitFSForm(payload).then(function (data) {
                $scope.showSpinner = false;
                $scope.savedText = 'Saved';
                $scope.result = {
                    success: true,
                    submissionId: data.submissionId
                }
                loadFormData(data);
                $scope.openResult();
            }).catch(function (error) {
                $scope.showSpinner = false;
                $scope.result = {
                    success: false,
                    error: error
                }
                $scope.openResult();
            });
        }
        $scope.duplicate = () => {
            filterFormData();
            $uibModal.open({
                animation: $scope.animationsEnabled,
                templateUrl: 'app/lendingForm/modals/add-form.html',
                controller: 'DuplicateFormCtrl',
                resolve: {
                    clientId: function () {
                        return $stateParams.clientId
                    },
                    formData: () => {
                        return $scope.formData
                    },
                    payload : function() {
                        let payload = {
                            formData: $scope.formData,
                            referenceId: $scope.selectedFormstackData.referenceId
                        }
                        if ($scope.selectedFormstackData.submissionId && $scope.selectedFormstackData.submissionId.length > 0) {
                            payload.submissionId = $scope.selectedFormstackData.submissionId;
                        }
                        return payload 
                        // return {}
                    }
                }
            });
        }

        /* ========================[ Controller Functions ]==============================*/
        function expand() {
            $scope.autoExpand('TextArea');
        }
        function filterFormData() {
            let section = $scope.formFields ? Object.values($scope.formFields) : null;
            for (let o = 0; o < section.length; o++) {
                const children = section[o].children
                for (let n = 0; n < children.length; n++) {
                    const formField = section[o].children[n];
                    if (formField.name.match(/number_of_loan_splits_for_lodgement_/)) {
                        continue;
                    }

                    // Removing hidden children from formData
                    if (formField.isHidden) {
                        delete $scope.formData[formField.name];
                    }
                    if (formField.type === "datetime" && $scope.formData[formField.name]) {
                        $scope.formData[formField.name] = moment($scope.formData[formField.name], ['DD/MM/YYYY']).utc().format()
                    }
                    // Removing children of hidden section from formData
                    if (section[o].isHidden) {
                        delete $scope.formData[formField.name];
                    }
                    if (formField.type === "checkbox") {
                        $scope.formData[formField.name] = Object.values(
                            Object.keys(formField.options)
                                .filter(item => $scope.formData[formField.name] && 
                                           item in $scope.formData[formField.name] && 
                                           $scope.formData[formField.name][item]
                                )
                                .reduce((obj, key) => {
                                    obj[key] = formField.options[key].label;
                                    return obj;
                                }, {})
                            ).join('\n')
                    }
                }
            }
            for (let o = 0; o < section.length; o++) {
                const children = section[o].children
                for (let n = 0; n < children.length; n++) {
                    const formField = section[o].children[n];
                    // check field if exists in $scope.formFieldWith_ then change variable name
                    if( $scope.formFieldWith_.includes(formField.name + '_') ) {
                        $scope.formData[formField.name + "_"] = $scope.formData[formField.name]
                    }
                }
            }
        }
        function handleSectionHidden(inc) {
            $scope.formFields.map((v, k) => {
                if (parseInt($scope.formData.number_of_lodgements_to_build) === 0) {
                    $scope.formFields[k].isHidden = !default_visible_sections.includes(v.section_heading.trim());
                    for (let x = 1; x <= 5; x++) {
                        $scope.formData["number_of_loan_splits_for_lodgement_" + x + "_l" + x] = 0;
                    }
                }
                if (v.section_heading.includes('Lodgement')) {
                    let z = parseInt(v.section_heading[10]);
                    if (z > $scope.formData.number_of_lodgements_to_build) {
                        $scope.formFields[k].isHidden = true;
                    }
                }
                inc.map((v2) => {
                    if (v.section_heading.includes(v2)) {
                        $scope.formFields[k].isHidden = false;
                    }
                });
            });
        }
        function getCalculationsData(data) {
            // This function gets the totalbaselending as well
            let totalBaseLending = 0;
            for (let i = 0; i < data.loan_structure.proposedLoan.loans.length; i++) {
                const proposedLoan = data.loan_structure.proposedLoan.loans[i];
                totalBaseLending += parseFloat(proposedLoan.baseLoan || 0);
            }
            let lvr = (totalBaseLending / (data.currentValue || totalBaseLending)) * 100; // javascript does not understand N/null, answer must be 1 so this devides by to itself to get 1.
            return {
                lvr: isNaN(lvr) ? 0 : lvr,
                totalBaseLending
            }
        }
        function mapFormFields() {
            let formFields = [];
            $scope.formFields.map((v) => {
                if (v.type) {
                    switch (v.type) {
                        case 'section':
                            v.isHidden = !default_visible_sections.includes(v.section_heading.trim());
                            v.children = [];
                            v.filled = 0;
                            v.showingFields = 0;
                            if (v.section_heading === "Final Reminders") {
                                v.children.push({
                                    name: 'text',
                                    type: 'richtext',
                                    value: "<h5>BEFORE YOU SUBMIT – have you checked?</h5>" +
                                        "<ul><li><strong>Customer Information (check &amp; confirm)</strong>" +
                                        "<ul><li>Check client name &amp; DOB in portal is matching ID documentation</li>" +
                                        "<li>Check Living Expense match servicing calculator</li>" +
                                        "<li>3 Years Residential Address – ensure not over lapping dates</li>" +
                                        "<li>3 Years Employment History</li>" +
                                        "<li>Projected OO or INV Utilities</li>" +
                                        "<li>Car Make, Model and Value</li>" +
                                        "<li>Ownership of Assets and Liabilities (ie, Savings)</li>" +
                                        "<li>Ensure account balances match supporting documents held</li></ul></li></ul>" +

                                        "<hr /><ul><li><strong>Form Submission (send with form submission)</strong></li>" +
                                        "<ul><li>Lender Servicing Calculator Current &amp; completed</li>" +
                                        "<li>Supporting Documents organised</li></ul></li></ul>"
                                })
                            }
                            formFields.push(v);
                            break;
                        default:
                            v.className = ['field',v.name.replace(/[^A-Za-z]+/g, '')].filter(Boolean).join('-')
                            v.isHidden = (v.name.indexOf('security_') === 0);
                            v.isReadOnly = v.name === "net_proceeds_aud" || 
                                         v.name === "total_loan_amount" ||
                                         v.name.match(/total_loan_amount_for_lodgement_/) ||
                                         v.name.match(/lvr__for_lodgement_/) ||
                                         v.name.match(/base_loan_amount_aud_/) ||
                                         v.name.match(/lender_mortgage_insurance_/) ||
                                         v.name.match(/total_loan_amount_aud_/) ||
                                         v.name.match(/total_loan_term_years_/) || 
                                         v.name.match(/interest_only_term_years_/) || 
                                         v.name.match(/repayment_type_/) ||
                                         v.name.match(/do_they_need_an_offset_account_/) || 
                                         v.name.match(/fixed_discount/) || 
                                         v.name.match(/final_fixed_interest_rate/) ||
                                         v.name.match(/variable_discount/) ||
                                         v.name.match(/final_variable_interest_rate/) || 
                                         v.name.match(/product_name/)
                                         
                            if (v.name === "number_of_lodgements_to_build") {
                                v.children = [];
                                for (let x = 1; x <= 5; x++) {
                                    v.children.push({
                                        name: 'property_with_proposed_loans_l' + x,
                                        type: 'select',
                                        label: 'Property details with proposed lending available',
                                        options: $scope.existing_and_proposed_properties
                                    })
                                }
                            }
                            if (v.name === "please_explain_why_and_how_you_are_comfortable_with_the_figure_being_low") {
                                v.info = "<h6>Guide for Brokers (HEM Guideline)</h6>" +
                                    "1. Analyse the lender servicing calculator which generally lets you know the HEM guide.<br />" +
                                    "2. If the client stated HEM is below the lender guide, politely contact the client and explain being<br />" +
                                    "under the HEM guideline makes it harder to approve the loan and recommend a detailed<br />" +
                                    "conversation (documented in notes) and review of the expenses.<br />" +
                                    "3. Go through the expenses line by line.  Customers can underestimate their living expenses<br />" +
                                    "(for eg, it may be utility costs or medical includes pharmacy purchases)<br />" +
                                    "4. It’s important to document the number of conversations and length you have gone to<br />" +
                                    "analysing the living expense – typically it will involve 2 to 3 conversations to ensure the clients" +
                                    "are accurate."
                            }
                            // Notes for the Bank 
                            if (v.name === "deal_comments") {
                                v.info = "Breakdown of loan purposes: \
                                MA/SMA – What do the clients want to achieve through the proposed \
                                lending?\
                                Loan purposes for each loan split or details breakdown for cash out?\
                                Additional notes of funds to complete.\
                                If not straight from savings ie Gift, sales proceed or shares/property etc\
                                MA/SMA"
                            }
                            if (v.name === "income") {
                                v.info = "Notes regarding type of income used, method of verification, i.e –\
                                MA PAYG base $xx plus bonus $xx lower of two years verified by payslips.\
                                FA PAYG part time casual $xx verified by average last 3 months net salary credits to CBA\
                                account…\
                                FA Self employed verified by most recent 2 quarter of BAS (Lowers of the three supporting\
                                docs) Total income XXX, expenses.\
                                Rental income $xx verified by latest rental statement\
                                Additional notes such as: probation, employment gap, maternity leave\
                                How will they fun shortfall\
                                What’s the servicing gap etc?"
                            }
                            if (v.name === "current_financial_state_not_reflected_in_application_lender_cover_notes") {
                                v.info = "Detail the assets and liabilities that will be changing in the future state and\
                                therefore will not be disclosed in full FLEX opp App &amp; AOLM including all\
                                details (EG lender limit balances etc)"
                            }
                            if (v.name === "possible_future_state_offered_for_the_lender_to_advise_if_a_condition_of_the_loan_lender_cover_notes_") {
                                v.info = "Liabilities to pay out/refinance/close/reduce or sale of asset – lodgement of 2018 TR etc"
                            }
                            if (v.name === "significant_changes_if_applicable") {
                                v.info = "Changes to the customer financial situation that may impact ability to\
                                repay loan\
                                Temporary/Permanent employment/income/family changes\
                                Large expenditure – renovations/education/parental/medical\
                                Exit strategy"
                            }
                            if (v.name === "application_mitigants") {
                                v.info = "Where we vary from standard policy\
                                For refinance – why extension of loan term?\
                                Notes regarding account conducts/credit impairments/defaults/late payments or\
                                dishonoured payments\
                                VOI – exceptiosn? Certified ID/ZipID used, face to face meetings to be completed at\
                                formal approval of docs sign (within lender’s policy/approval)\
                                Why OI on owner occupied?"
                            }
                            if (v.name === "any_unusual_features_that_the_bank_needs_to_be_aware_of") {
                                v.info = "Medico, rebates etc\
                                Different title owners to Borrowers\
                                Stamp duty exemptions"
                            }
                            if (v.name === "settlement_date") {
                                v.info = "Please note that settlement date for new purchases and in flex, please provide any details\
                                around simultaneous settlement or second mortgage require that can create delays."
                            }
                            if(v.name.endsWith('_')) {
                                $scope.formFieldWith_.push(v.name);
                                v.name = v.name.slice(0, -1);
                            }
                            formFields[formFields.length - 1].children.push(v);
                    }
                }
            })
            return formFields;
        }
        function initForm() {
            DataM.getFSForm().then(function (data) {
                loadFormData(data);
                return data;
            }).then(function (data) {
                initPersonalInfo();
            })
                .catch(function (error) {
                    console.log(error);
                });
        }
        function getFormWithId(formId) {
            let payload = {
                formId: formId
            };
            DataM.getFSFormWithId(payload).then(function (data) {
                $scope.isSubmittedInternally = data.isSubmittedInternally
                if(data.formData) {
                    for(const [key,val] of Object.entries(data.formData)) {
                        $scope.formData_fresh[key.replace(/_\s*$/, "")] = val;
                    }
                }
                loadFormData(data);
            }).then(initPersonalInfo())
                .catch(function (error) {
                    console.log(error);
                });
        }
        function loadFormData(data) {
            if (data._id) {
                if (data.formData) {
                    // format Date correctly
                    const section = $scope.formFields ? Object.values($scope.formFields) : null;
                    for (let o = 0; o < section.length; o++) {
                        const children = section[o].children
                        for (let n = 0; n < children.length; n++) {
                            const formField = section[o].children[n];
                            
                            if (formField.type === "datetime") {
                                data.formData[formField.name] = data.formData[formField.name] ? moment.utc(data.formData[formField.name]).toDate() : null;
                            }

                            if (formField.type === "checkbox") {
                                data.formData[formField.name] = Object.keys(formField.options)
                                    .filter(_temp => (
                                        !_.isNil(data.formData[formField.name]) &&
                                        data.formData[formField.name] !== 0 && 
                                        typeof data.formData[formField.name] === 'string' &&
                                        data.formData[formField.name].match(formField.options[_temp].label)
                                    )
                                ).reduce((total,key)=> (total[key]=true,total),{});
                            }
                        }
                    }
                }
                $scope.formData = data.formData
                $scope.timestamp = data.timestamp;
                $scope.selectedFormstackData.referenceId = data.referenceId;
                $scope.selectedFormstackData.id = data._id;
                if (data.submissionId && data.submissionId.length > 0) {
                    $scope.selectedFormstackData.submissionId = data.submissionId;
                } else {
                    $scope.selectedFormstackData.submissionId = data.submissionId;
                }
            }
        }
        function initPersonalInfo() {
            DataM.getPersonalInfo().then(function (data) {
                $scope.clientInfo.client1Name = _.get(data, 'personalInfo.client1.fName', '') + ' ' + _.get(data, 'personalInfo.client1.lName', '');
                $scope.clientInfo.client2Name = _.get(data, 'personalInfo.client2.fName', '') + ' ' + _.get(data, 'personalInfo.client2.lName', '');
                if (_.isNil($scope.formData.client_names)) {
                    $scope.formData.client_names = [$scope.clientInfo.client1Name, $scope.clientInfo.client2Name].filter(Boolean).join(' and ');
                }
            }).then(function () {
                DataM.getMe().then(function (userData) {
                    $scope.adminEmail = userData.email;
                    $scope.adminName = userData.fullName;
                    initPersonalInfoFields();
                });
            }).catch(function (error) {
                console.log(error);
            });
        }
        function initPersonalInfoFields() {
            if (_.isNil($scope.formData)) {
                $scope.formData = {};
            }
            if (_.isNil($scope.formData.loan_writer)) {
                $scope.formData.loan_writer = $scope.adminName;
            }
            if (_.isNil($scope.formData.please_confirm_loan_writers_email_address)) {
                $scope.formData.please_confirm_loan_writers_email_address = $scope.formData.please_confirm_loan_writers_email_address || $scope.adminEmail;
            }
            if (_.isNil($scope.formData.please_advise_what_has_changed)) {
                $scope.formData.please_advise_what_has_changed = "Childcare:\n" +
                    "Clothing & Personal Care:\n" +
                    "Education:\n" +
                    "Groceries:\n" +
                    "Insurance:\n" +
                    "Medical & Health (Ex Insurance):\n" +
                    "Other Living Expenses:\n" +
                    "Recreation & Entertainment:\n" +
                    "Telephone & Internet:\n" +
                    "Transport:\n" +
                    "Utilities & Rates - INV:\n" +
                    "Utilities & Rates - OO:";
            }
        }
        async function get_loan_datas() {
            await DataM.getPropertyLoans().then(async function (data) {
                if (data.assets && data.assets.properties) {
                    await data.assets.properties.map((v, k) => {
                        DataM.getLoanStructure({
                            uid: $stateParams.clientId,
                            propertyId: v._id,
                        }).then(function (_data) {
                            data.assets.properties[k].label = data.assets.properties[k].address.number + " " + data.assets.properties[k].address.street + " " + data.assets.properties[k].address.country
                            data.assets.properties[k].value = data.assets.properties[k]._id
                            data.assets.properties[k].loan_structure = _data;
                            if (_data.proposedLoan && _data.proposedLoan.loans.length > 0) {
                                $scope.existing_and_proposed_properties.push(v);
                            }
                        });
                    })
                    $scope.assets = data.assets
                    $scope.owners = DataHelper.getOwnerList(data, false, true, true);
                }
            });
            await DataM.getProposedPropertyList({ uid: $stateParams.clientId }).then(async function (data) {
                if (data.properties) {
                    await data.properties.map((v, k) => {
                        DataM.getLoanStructure({
                            uid: $stateParams.clientId,
                            propertyId: v._id,
                        }).then(function (_data) {
                            data.properties[k].label = data.properties[k].address.number + " " + data.properties[k].address.street + " " + data.properties[k].address.country
                            data.properties[k].value = data.properties[k]._id
                            data.properties[k].loan_structure = _data;
                            if (_data.proposedLoan && _data.proposedLoan.loans.length > 0) {
                                $scope.existing_and_proposed_properties.push(v);
                            }
                        });
                    })
                }
            });
            return $scope.existing_and_proposed_properties;
        }
        function assignWhenMatched(subj, pat, obj, key, val) {
            if (subj.match(pat)) {
                obj[key] = val
            }
        }
        function getLoanData(x) {
            return $scope.existing_and_proposed_properties.find(loan =>
                loan._id === $scope.formData["property_with_proposed_loans_l" + x]
            )
        }
        function security_watcher_func(x) {
            if ($scope.formData) {
                const loan_data = getLoanData(x)

                // set securities_ x from 0 to show
                const section_index = $scope.formFields.findIndex((ele) => {
                    return ele.section_heading === "Lodgement " + x + " (L" + x + ")"
                });
                if ($scope.formFields[section_index]) {
                    if (typeof $scope.formFields[section_index].children !== "undefined") {
                        $scope.formFields[section_index].children.map((ele, key) => {
                            if (ele.name.indexOf("security_") === 0) {
                                $scope.formFields[section_index].children[key].isHidden = true;
                            }
                        })
                    }
                }
                const security_count = $scope.formData["number_of_securities_for_lodgement_" + x + "_l" + x];
                if (security_count > 4) {
                    alert('Max value available is (4) since formstack only provided 4 security objects.')
                    $scope.formData["number_of_securities_for_lodgement_" + x + "_l" + x] = 4;
                }
                else if (security_count > 0) {
                    // set formfield to show up
                    if ($scope.formFields[section_index]) {
                        $scope.formFields[section_index].children.map((ele, key) => {
                            for (let y = 1; y <= security_count; y++) {
                                if (ele.name.indexOf("security_" + y) === 0) {
                                    $scope.formFields[section_index].children[key].isHidden = false;
                                }
                                // This will retrieve the property data for existing properties
                                const prop_asset = $scope.assets.properties.find(property => property._id === $scope.formData["property_with_proposed_loans_l" + x]);
                                const ownership = prop_asset && prop_asset.ownership.ownershipType !== "Other"
                                    ? prop_asset.ownership.owners
                                        .map(propertyOwner => $scope.owners.find(owner => propertyOwner.owner === owner.id))
                                        .filter(Boolean)
                                        .map(owner => owner.name)
                                        .reduce((namesString, name) => (namesString + " and " + name))
                                    : '-';
                                if(loan_data) {
                                    const address = loan_data.address;
                                    const sec = {
                                        value: loan_data.currentValue || '-',
                                        address: [[address.number, address.street, address.streetType].filter(Boolean).join(' '), 
                                                address.suburb, address.postcode, address.state].filter(Boolean).join(', ') || '-',
                                        prop_primary_purpose: loan_data.label + " - " + (prop_asset ? prop_asset.purpose : '-'),
                                        holding_title: ownership
                                    }
                                    const security_val = "Value $: " + sec.value + " \n" +
                                        "Address: " + sec.address + "\n\n" +
                                        "Property Primary Purpose (O/O or Investment): " + sec.prop_primary_purpose + "\n\n" +
                                        "Is this Property a New Purchase (New Build)?\n\n" +
                                        "Holding Title (eg Joint Tenants, Tenants in Common or Sole Ownership): " + sec.holding_title + "\n\n" +
                                        "If ownership is different to borrowers or Tenants in Common, please advise?\n" +
                                        "Name 1 on Title:-\n" +
                                        "Name 1 ownership %:-\n" +
                                        "Name 2 on Title:-\n" +
                                        "Name 2 ownership %:-";
                                    
                                    $scope.formData["security_" + y + "_l" + x] = $scope.formData["security_" + y + "_l" + x] || security_val
                                }
                            }
                        })
                    }
                }
            }
        }
        /* ======================================[ Listeners ]==============================*/
        function triggerListeners() {
            let initializing = true
            for (let x = 1; x <= 5; x++) {
                $scope.$watch('formData.number_of_loan_splits_for_lodgement_' + x + '_l' + x, () => {
                    if ($scope.formData) {
                        const split_count = $scope.formData['number_of_loan_splits_for_lodgement_' + x + '_l' + x];
                        $scope.formFields.map((ele, key) => {
                            if (ele.section_heading.includes("Lodgement " + x + " - Loan Split")) {
                                $scope.formFields[key].isHidden = true;
                            }
                        })
                        for (let y = split_count; y > 0; y--) {
                            $scope.formFields.map((ele, key) => {
                                if (ele.section_heading.includes("Lodgement " + x + " - Loan Split " + y + " (L" + x + "S" + y + ")")) {
                                    $scope.formFields[key].isHidden = false;
                                }
                            })
                        }
                        let borrower_val = '';
                        if ($scope.clientInfo.client1Name.length > 0) {
                            borrower_val = "Borrower 1:- " + $scope.clientInfo.client1Name + '\n';
                        }
                        if ($scope.clientInfo.client2Name.length > 0) {
                            borrower_val += "Borrower 2:- " + $scope.clientInfo.client2Name + '\n';
                        }
                        $scope.formData["borrower_l"+ x] = $scope.formData["borrower_l" + x] || borrower_val
                    }
                })
                $scope.$watch('formData.number_of_securities_for_lodgement_' + x + '_l' + x, () => security_watcher_func(x))
            }
            let total_base_lending_array = [];
            for (let x = 1; x <= 5; x++) {
                // listener for Security n field
                $scope.$watch('formData.property_with_proposed_loans_l' + x, () => {
                    initPersonalInfoFields();
                    if ($scope.formData) {
                        const loan_data = getLoanData(x)
                        if (loan_data) {
                            const calculations_data = getCalculationsData(loan_data);
                            const loan_data_structure = loan_data.loan_structure
                            const loan_splits = (loan_data_structure.proposedLoan) ? loan_data_structure.proposedLoan.loans : [];
                            if ($scope.formData["property_with_proposed_loans_l" + x] === null) {
                                total_base_lending_array[x - 1] = 0
                            }
                            else {
                                total_base_lending_array[x - 1] = calculations_data.totalBaseLending
                            }
                            $scope.formData.total_loan_amount = total_base_lending_array.reduce((a, b) => a + b)
                            // Setting Lender field data
                            $scope.formData["number_of_loan_splits_for_lodgement_" + x + "_l" + x] = loan_splits.length;
                            $scope.formData["total_loan_amount_for_lodgement_" + x] = calculations_data.totalBaseLending;
                            security_watcher_func(x)
                            // Splits
                            for (let z = 0; z < loan_splits.length; z++) {
                                const loan_split = loan_splits[z];
                                const d = (z + 1);
                                const repayment_type = (loan_split.repaymentType === "Interest Only") ? "Interest Only (IO)" : "Principle & Interest (P&I)";
                                const do_they_need_an_offset_account_l = (loan_split.offsets.length > 0) ? "Yes" : "No";
                                const num_words = ['No', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten'];
                                const offset = (loan_split.offsets.length > 1) ? " Offsets" : ' Offset';
                                const any_further_instruction = num_words[loan_split.offsets.length] + offset

                                $scope.formData["total_loan_term_years_l" + x + "s" + (z + 1)] = loan_split.totalLoanTerm;
                                $scope.formData["interest_only_term_years_l" + x + "s" + (z + 1)] = loan_split.interestOnlyTerm;
                                $scope.formData["lender_mortgage_insurance_l" + x + "s" + (z + 1)] = loan_split.capLMI;
                                $scope.formData["base_loan_amount_aud_l" + x + "s" + (z + 1)] = loan_split.baseLoan;
                                $scope.formData["total_loan_amount_aud_l" + x + "s" + (z + 1)] = loan_split.totalLoan;
                                $scope.formData["pi_repayment_cycle_l" + x + "s" + (z + 1)] = $scope.formData["pi_repayment_cycle_l" + x + "s" + (z + 1)] || 'Monthly';
                                $scope.formData["lvr__for_lodgement_" + x + "_l" + x + "s" + (z + 1)] = calculations_data.lvr;
                                $scope.formData["any_further_specific_instructions_ie_multiple_offset_multiple_loan_splits_with_different_offsets_accounts_with_different_names_what_have_we_missed_are_there_any_further_specific_notes_or_instructions_to_the_lender_including_for_postsettlement_l" + x + "s" + (z + 1)] = $scope.formData["any_further_specific_instructions_ie_multiple_offset_multiple_loan_splits_with_different_offsets_accounts_with_different_names_what_have_we_missed_are_there_any_further_specific_notes_or_instructions_to_the_lender_including_for_postsettlement_l" + x + "s" + (z + 1)] || any_further_instruction

                                // these always merge data from settlement_form every page loads
                                $scope.formData["repayment_type_l" + x + "s" + (z + 1)] = repayment_type; 
                                $scope.formData["do_they_need_an_offset_account_l" + x + "s" + (z + 1)] = do_they_need_an_offset_account_l;
                                
                                if (loan_split.initialLoanType) {
                                    let current_productName = $scope.formData["product_name_l" + x + "s" + (z + 1)];

                                    $scope.formData["fixed_discount__l" + x + "s" + (z + 1)] = loan_split.discountRate;
                                    $scope.formData["final_fixed_interest_rate__l" + x + "s" + (z + 1)] = loan_split.interestRate;
                                    if (loan_split.initialLoanType.includes('Fixed Rate')) {
                                        if (loan_split.fixedTerm > 0) {
                                            $scope.formData["product_name_l" + x + "s" + (z + 1)] = current_productName || loan_split.fixedTerm + " Year Fixed";
                                        }
                                    }
                                    else if (loan_split.initialLoanType.includes('Standard Variable')) {
                                        $scope.formData["product_name_l" + x + "s" + (z + 1)] = current_productName || 'SVR'
                                        $scope.formData["variable_discount__l" + x + "s" + (z + 1)] = loan_split.discountRate
                                        $scope.formData["final_variable_interest_rate__l" + x + "s" + (z + 1)] = loan_split.interestRate
                                    }
                                    else if (loan_split.initialLoanType.includes('Basic Variable')) {
                                        $scope.formData["product_name_l" + x + "s" + (z + 1)] = current_productName || 'Basic';
                                        $scope.formData["variable_discount__l" + x + "s" + (z + 1)] = loan_split.discountRate
                                        $scope.formData["final_variable_interest_rate__l" + x + "s" + (z + 1)] = loan_split.interestRate
                                    }
                                    else if (loan_split.initialLoanType.includes('Line of Credit')) {
                                        $scope.formData["product_name_l" + x + "s" + (z + 1)] = current_productName || 'Line of Credit';
                                        $scope.formData["variable_discount__l" + x + "s" + (z + 1)] = loan_split.discountRate
                                        $scope.formData["final_variable_interest_rate__l" + x + "s" + (z + 1)] = loan_split.interestRate
                                    }
                                }
                            } // end split loop
                        }
                    }
                })
            } // end for loop


            $scope.$watch('formData.loan_writer', function () {
                if (initializing) {
                    $timeout(function () { initializing = false; });
                }
                else {
                    if ($scope.formData) {
                        const loan_writer = $scope.formData.loan_writer;
                        const please_confirm_loan_writers_email_address = $scope.formData.please_confirm_loan_writers_email_address;

                        if ($scope.formData.loan_writer) {
                            assignWhenMatched(loan_writer, /Matthew Styles/, $scope.formData, 'please_confirm_loan_writers_email_address', "matthew@empowerwealth.com.au")
                            assignWhenMatched(loan_writer, /Brett Caine/, $scope.formData, 'please_confirm_loan_writers_email_address', "brett@empowerwealth.com.au")
                            assignWhenMatched(loan_writer, /Sarah King/, $scope.formData, 'please_confirm_loan_writers_email_address', "sarah@empowerwealth.com.au")
                            assignWhenMatched(loan_writer, /Simon Gillespie/, $scope.formData, 'please_confirm_loan_writers_email_address', "simon@empowerwealth.com.au")
                            assignWhenMatched(loan_writer, /Dean Wills/, $scope.formData, 'please_confirm_loan_writers_email_address', "dean@empowerwealth.com.au")
                            assignWhenMatched(loan_writer, /Bede Taylor/, $scope.formData, 'please_confirm_loan_writers_email_address', "bede@empowerwealth.com.au")
                            assignWhenMatched(loan_writer, /Luke Oxenham/, $scope.formData, 'please_confirm_loan_writers_email_address', "luke@empowerwealth.com.au")
                        }
                    } // end ($scope.formData)
                }
            })
            $scope.$watchCollection("formData.final_reminder", (a,b) => {
                $scope.readyToSubmit = _.last(Object.values($scope.formData.final_reminder || {}))
            })
            $scope.$watch("formData.number_of_lodgements_to_build", (_, isChanged) => {
                if ($scope.formData) {
                    let arr = []
                    for (let x = 1; x <= 5; x++) {
                        if (x <= $scope.formData.number_of_lodgements_to_build) {
                            arr.push('Lodgement ' + x + ' (L');
                            let loan_data = getLoanData(x);
                            loan_data = loan_data ? loan_data.loan_structure.proposedLoan.loans.length : 0;
                            if (loan_data === 0) {
                                $scope.formData["property_with_proposed_loans_l" + x] = null
                            }
                            else {
                                $scope.formData["number_of_loan_splits_for_lodgement_" + x + "_l" + x] = loan_data.toString();
                            }
                        }
                        else {
                            $scope.formData["property_with_proposed_loans_l" + x] = null
                        }
                    }
                    handleSectionHidden(arr);
                    // initPersonalInfoFields();
                }
            })

            // Calculations for Net Proceed $AUD
            $scope.$watchGroup([
                'formData.deposit_paid_aud',
                'formData.cashsavings_aud',
                'formData.gift_aud',
                'formData.fhog_grant_aud'
            ], (_, isChanged) => {
                if (isChanged) {
                    const deposit_paid_aud = ($scope.formData.deposit_paid_aud) || 0;
                    const cashsavings_aud = ($scope.formData.cashsavings_aud) || 0;
                    const gift_aud = ($scope.formData.gift_aud) || 0;
                    const fhog_grant_aud = ($scope.formData.fhog_grant_aud) || 0;

                    $scope.formData.net_proceeds_aud =
                        deposit_paid_aud +
                        cashsavings_aud +
                        gift_aud +
                        fhog_grant_aud;
                }
            })
            $scope.$watchCollection("formData",
                function (a, b) {
                    let data = $scope.formData || [];
                    let formField = $scope.formFields || [];
                    let storer = [];
                    let counter = 0;
                    let properties_and_loans = Object.values($scope.existing_and_proposed_properties);
                    let lodgement = [];
                    let number_of_lodgements_to_build = 0;

                    for (let x = 1; x <= 5; x++) { //push all property with proposed loans in lodgement array
                        if (data["property_with_proposed_loans_l" + x] === '' && data.number_of_lodgements_to_build > 0) {
                            $scope.minus_number_of_lodgements_to_build();
                        }
                        if (data["property_with_proposed_loans_l" + x]) {
                            number_of_lodgements_to_build++;
                        }
                        lodgement.push(data["property_with_proposed_loans_l" + x]);
                    }
                    if ($scope.formData) {
                        $scope.formData.number_of_lodgements_to_build = number_of_lodgements_to_build;
                        for (const o in formField) {
                            const section = formField[o];
                            storer.push([])
                            for (const n in section.children) {
                                const child = section.children[n]
                                if (!_.isNil(data[child.name])) {
                                    storer[o].push(data[child.name]) // this is for the counter
                                }
                                if(child.type==="number") {
                                    if($scope.formData[child.name]) {
                                        if(Math.round($scope.formData[child.name]) !== $scope.formData[child.name]) {
                                            $scope.formData[child.name] = +$scope.formData[child.name].toFixed(2)
                                        }
                                    }
                                }
                                switch (section.section_heading.toLowerCase()) {
                                    case "Opportunity and Application Instructions".toLowerCase(): {
                                        assignWhenMatched(child.name, /opportunity_id_for_similar_or_duplication_application_to_a_new_lender/,
                                            child, 'isHidden', !(data.applicationtype === "Similar or Duplicate Application to a New Lender"))
                                        assignWhenMatched(child.name, /previous_lender_opportunity_id_to_be_cloned/,
                                            child, 'isHidden', (!(data.opportunity_id_for_similar_or_duplication_application_to_a_new_lender === "Previous Lender Opportunity ID to be Cloned") || !(data.applicationtype === "Similar or Duplicate Application to a New Lender")))
                                        assignWhenMatched(child.name, /new_primary_opportunity_id/,
                                            child, 'isHidden',
                                            !(data.opportunity_id_for_similar_or_duplication_application_to_a_new_lender === "New Primary Opportunity ID") ||
                                            !(data.applicationtype === "Similar or Duplicate Application to a New Lender")
                                        )
                                        break;
                                    }
                                    case "Lender Specific Requirements".toLowerCase(): {
                                        if (child.name === "retirement_age_of_guarantors") {
                                            for (let x = 1; x <= 5; x++) {
                                                if (x === 1) {
                                                    child.isHidden = true
                                                }
                                                if ($scope.formData['is_there_a_guarantor_l' + x] === "Yes") {
                                                    child.isHidden = false
                                                }
                                            }
                                        }
                                        break;
                                    }
                                    case "Broker Fact Find".toLowerCase(): {
                                        assignWhenMatched(child.name, /rebate_details/, child, 'isHidden', !(data.any_lender_rebates_applicable === "Yes"))
                                        break;
                                    }
                                    case "Income Breakdown".toLowerCase(): {
                                        switch (child.name) {
                                            case "1st_income_type":
                                            case "1st_amount_annually":
                                            case "2nd_income_type":
                                            case "2nd_amount_annually":
                                                child.isHidden = true;
                                                break;
                                        }
                                        break;
                                    }
                                    case "Face to Face".toLowerCase(): {
                                        if (child.name === "have_we_met_the_applicants") {
                                            child.isHidden = false
                                        }
                                        else if (child.name === "date_of_meeting_with_applicants" ||
                                            child.name === "location_of_meeting_with_applicants") {
                                            child.isHidden = !($scope.formData.have_we_met_the_applicants === "Yes")
                                        }
                                        else { // else will be hidden if _have_we_met_the_applicants === Yes
                                            child.isHidden = ($scope.formData.have_we_met_the_applicants !== "No")
                                        }
                                        if ($scope.formData.have_we_met_the_applicants === "No") {
                                            assignWhenMatched(child.name, /please_confirm_why_is_there_no_afg_sign_off_for_this_application/, child, 'isHidden',
                                                !(data.do_we_have_afg_sign_off_saved_in_the_client_folder === "No"))
                                            assignWhenMatched(child.name, /how_did_we_complied_with_cert_id/, child, 'isHidden',
                                                !(data.how_did_we_comply_with_the_lenders_voi_requirements === "Cert ID"))
                                            assignWhenMatched(child.name, /why_havent_we_complied_before_submitting_the_request_or_when_and_where_do_you_plan_to_meet_the_clients/, child, 'isHidden',
                                                !(data.how_did_we_comply_with_the_lenders_voi_requirements === "No"))
                                        }
                                        break;
                                    }
                                    case "Living Expenses".toLowerCase(): {
                                        assignWhenMatched(child.name, /please_explain_why_and_how_you_are_comfortable_with_the_figure_being_low/, child, 'isHidden',
                                            !(data.are_the_living_expenses_declared_by_the_borrowers_significantly_less_than_the_guide_figures === "Yes"))
                                        assignWhenMatched(child.name, /please_advise_what_has_changed/, child, 'isHidden',
                                            !(data.has_the_living_expense_declaration_advised_by_the_applicants_changed_at_any_time_in_the_process === "Yes"))
                                        assignWhenMatched(child.name, /explain_the_reason_for_low_living_expenses/, child, 'isHidden',
                                            !(data.are_there_any_expenses_that_are_0_or_unusually_low === "Yes"))
                                        break;
                                    }
                                    case "Refinance".toLowerCase(): {
                                        assignWhenMatched(child.name, /how_much_is_the_break_cost_figure/, child, 'isHidden',
                                            !(data.have_the_finer_loan_details_been_discussed_in_detail_rates_repayments_exit_fees_ongoing_fees_structure_and_break_cost_figure === "Yes"))
                                        assignWhenMatched(child.name, /have_you_explained_that_there_are_additional_costs_of_interest_over_the_life_of_the_loan/, child, 'isHidden',
                                            !(data.are_you_are_extending_the_loan_term === "Yes"))
                                        break;
                                    }
                                    case "Requirements and Objectives".toLowerCase(): {
                                        assignWhenMatched(child.name, /why_variable/, child, 'isHidden', !(data.interest_rate === "Variable"))
                                        assignWhenMatched(child.name, /why_fixed/, child, 'isHidden', !(data.interest_rate === "Fixed"))
                                        assignWhenMatched(child.name, /why_combination_of_variable_and_fixed/, child, 'isHidden', !(data.interest_rate === "Combination"))

                                        assignWhenMatched(child.name, /why_pi/, child, 'isHidden', !(data.repayment === "Principal & Interest"))
                                        assignWhenMatched(child.name, /why_io/, child, 'isHidden', !(data.repayment === "Interest Only"))
                                        assignWhenMatched(child.name, /why_combination_of_pi_and_io/, child, 'isHidden', !(data.repayment === "Combination"))
                                        break;
                                    }
                                    case "General Instructions and Tasks".toLowerCase(): {
                                        assignWhenMatched(child.name, /bank_branch_to_pick_up_loan_documents/, child, 'isHidden', !(data.how_do_you_want_to_receive_the_loan_documents === "Bank Branch"))
                                        assignWhenMatched(child.name, /please_enter_clients_nominated_address/, child, 'isHidden', !(data.how_do_you_want_to_receive_the_loan_documents === "Client's Nominated Address"))
                                        assignWhenMatched(child.name, /surplus_fund_account/, child, 'isHidden',
                                            !(data.discharge === "CES to prepare (send to incoming & outgoing lender upon Submission)" ||
                                                data.discharge === "CES to prepare (to be returned for either LW or client action and submission)"))
                                        break;
                                    }
                                } // end switch
                                //hide number_of_loan_splits_for_lodgement_n_Ln
                                assignWhenMatched(child.name, /number_of_loan_splits_for_lodgement_/, child, 'isHidden', true)
                                assignWhenMatched(child.name, /loan_document_reminder_optional/, child, 'isHidden', true)
                            }
                            section.filled = storer[o].length; // filling up the counter for filled datas
                            section.showingFields = section.children.filter(child => (!child.isHidden && child.type !== "richtext")).length
                        }
                    }
                    if (properties_and_loans.length > 0) {
                        const lodgements = lodgement.filter(Boolean).map(val => properties_and_loans.find(data => val.includes(data._id)) )
                        /* This loop is intended for Lodgement and Splits only, and also for other sections that has conditions based on lodgement and splits e.g. Refinance */
                        for (let _x in lodgements) {
                            const x = parseInt(_x) + 1
                            const splits = lodgements[_x].loan_structure.proposedLoan.loans;
                            for (let _d in splits) {
                                const d = parseInt(_d) + 1
                                //Set repayment type to 'Principle & Interest (P&I)' if undefined
                                for (const o in formField) {
                                    const section = formField[o];
                                    for (const n in section.children) {
                                        const child = section.children[n]
                                        let productName = ($scope.formData["product_name_l" + x + "s" + d] === 'SVR' ||
                                            $scope.formData["product_name_l" + x + "s" + d] === 'Variable Rate' ||
                                            $scope.formData["product_name_l" + x + "s" + d] === 'Line of Credit' ||
                                            $scope.formData["product_name_l" + x + "s" + d] === 'Basic');
                                        assignWhenMatched(child.name, "fixed_discount__l" + x + "s" + d, child, 'isHidden', productName)
                                        assignWhenMatched(child.name, "final_fixed_interest_rate__l" + x + "s" + d, child, 'isHidden', productName)
                                        assignWhenMatched(child.name, "rate_lock_required_l" + x + "s" + d, child, 'isHidden', productName)

                                        assignWhenMatched(child.name, "variable_discount__l" + x + "s" + d, child, 'isHidden', !productName)
                                        assignWhenMatched(child.name, "final_variable_interest_rate__l" + x + "s" + d, child, 'isHidden', !productName)
                                        assignWhenMatched(child.name, "final_variable_interest_rate__l" + x + "s" + d, child, 'isHidden', !productName)

                                        assignWhenMatched(child.name, "credit_card_requirement_l" + x, child, 'isHidden', !($scope.formData["do_they_need_a_new_credit_card_l" + x] === "Yes"))
                                        assignWhenMatched(child.name, "link_package_to_clients_existing_credit_card_l" + x, child, 'isHidden', !($scope.formData["do_they_need_a_new_credit_card_l" + x] === "No new card but request to link client’s existing credit card to package"))
                                        assignWhenMatched(child.name, "guarantor_type_l" + x, child, 'isHidden', !($scope.formData["is_there_a_guarantor_l" + x] === "Yes"))
                                        assignWhenMatched(child.name, "guarantors_name_l" + x, child, 'isHidden', !($scope.formData["is_there_a_guarantor_l" + x] === "Yes"))
                                        assignWhenMatched(child.name, "guarantor_security_property_l" + x, child, 'isHidden', !($scope.formData["is_there_a_guarantor_l" + x] === "Yes"))

                                        if (section.section_heading.match(/Refinance/)) {
                                            if (x === 1 && d === 1) { // always set to hidden everytime the first lodgement is checked
                                                section.isHidden = true
                                            }
                                            if ($scope.formData["loan_type_l" + x + "s" + d]) {
                                                if ($scope.formData["loan_type_l" + x + "s" + d].match(/Refinance/)) {
                                                    section.isHidden = false
                                                }
                                            }
                                        }
                                        assignWhenMatched(child.name, "interest_only_term_years_l" + x + "s" + d, child, 'isHidden', ($scope.formData["repayment_type_l" + x + "s" + d] === 'Principle & Interest (P&I)'))
                                        assignWhenMatched(child.name, "pi_repayment_cycle_l" + x + "s" + d, child, 'isHidden', ($scope.formData["repayment_type_l" + x + "s" + d] !== 'Principle & Interest (P&I)'))

                                        assignWhenMatched(child.name, "direct_debit_account_to_be_linked_l"+x+"s"+d, child, 'isHidden', ($scope.formData["do_they_need_a_direct_debit_account_l"+x+"s"+d] !== 'Yes'))
                                        assignWhenMatched(child.name, "existing_direct_debit_account_details_l"+x+"s"+d, child, 'isHidden', 
                                            ($scope.formData["direct_debit_account_to_be_linked_l"+x+"s"+d] !== 'Existing: One Account') || ($scope.formData["do_they_need_a_direct_debit_account_l"+x+"s"+d] !== 'Yes'))
                                        
                                        assignWhenMatched(child.name, "name_of_package_owner_l" + x, child, 'isHidden', ($scope.formData["package_owner_l" + x ] !== 'New Package for Nominated Applicant as the Sole Package Owner'))
                                        assignWhenMatched(child.name, "existing_package_number_l" + x, child, 'isHidden', ($scope.formData["package_owner_l" + x ] !== 'Existing Package'))

                                        assignWhenMatched(child.name, "offset_account_to_be_linked_l" + x + "s" + d, child, 'isHidden', ($scope.formData["do_they_need_an_offset_account_l" + x + "s" + d] !== 'Yes'))
                                        assignWhenMatched(child.name, "existing_direct_debit_account_details_for_offset_l" + x + "s" + d, child, 'isHidden', ($scope.formData["do_they_need_an_offset_account_l" + x ] !== 'Yes'))
                                    } // loop for children
                                } // loop for section
                            } // loop for splits

                            // Setting Default Value under lodgements
                            $scope.formData["do_they_need_a_direct_debit_account_l" + x] = $scope.formData["do_they_need_a_direct_debit_account_l" + x] || 'Yes'
                            $scope.formData["number_of_securities_for_lodgement_" + x + "_l" + x] = $scope.formData["number_of_securities_for_lodgement_" + x + "_l" + x] || 1;

                        } // loop for lodgements
                    }
                }
            );
        } 
        /* ========================[ Init ]==============================*/
        if ($stateParams.formId) {
            $scope.formId = $stateParams.formId
            $scope.showSpinner = true;
            DataM.getFSFormFields().then(async function (data) {
                await get_loan_datas();
                $scope.formFields = data.fields;
                $scope.formFields = mapFormFields();
                $scope.$watch('formFields', function () {
                    $scope.showSpinner = false;
                    if ($stateParams.formId) {
                        $scope.selectedFormstackData.id = $stateParams.formId;
                        getFormWithId($scope.selectedFormstackData.id);
                        triggerListeners()
                    } else {
                        initForm();
                    }
                });
            }).then();
        }
        else {
            DataM.getFSFormRefList().then(function (data) {
                if (data.referenceIdList && data.referenceIdList.length > 0) {
                    $scope.referenceIdList = data.referenceIdList;
                }
            })
        }
    })