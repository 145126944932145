'use strict';

(function (){
  var logNavigation = function (type, url){
    var url = new URL(url);
    url.pathname = url.pathname.replace(/\/[567][a-z0-9]{23}\b/g, '');

    var title = '';
    if (type === 'navigate'){
      title = url.pathname;
      title = title.replace(/(\/.)/g, function (v){ return ' '+v.substr(1).toUpperCase() }).replace(/^\s+/, '').replace(/\//g, '');

      gtag('event', 'page_view', {
        page_title: title,
        page_location: url.toString()
      });
    } else {
      title = type+' '+url.pathname;
      gtag('event', 'submit', {
        'event_label': title,
        'event_category': type,
        'value': url.pathname
      });
    }
  }

  angular.module('meanApp')
  .run(function($rootScope) {
    $rootScope.$on("$locationChangeStart", function(event, next, current) { 
      logNavigation('navigate', next);
    });
  });

  // Not required - handled by locationChangeStart
  // logNavigation('load', location.href);

  // XHR Inteceptor
  (function(XHR) {
    "use strict";

    // JA - 2022-10-25 (disabled XHR logging)
    return;

    var open = XHR.prototype.open;
    var send = XHR.prototype.send;

    XHR.prototype.open = function(method, url, async, user, pass) {
        this._url = url;
        this._method = method;
        open.call(this, method, url, async, user, pass);
    };

    XHR.prototype.send = function(data) {
        var self = this;
        var oldOnReadyStateChange;
        var url = this._url;
        var method = this._method.toUpperCase();

        function onReadyStateChange() {
            if(self.readyState == 4) {
                if (method == 'POST' || method == 'PATCH' || method == 'PUT'){
                  logNavigation(method, this.responseURL);
                }
            }

            if(oldOnReadyStateChange) {
                oldOnReadyStateChange();
            }
        }

        if(!this.noIntercept) {            
            if(this.addEventListener) {
                this.addEventListener("readystatechange", onReadyStateChange, false);
            } else {
                oldOnReadyStateChange = this.onreadystatechange; 
                this.onreadystatechange = onReadyStateChange;
            }
        }

        send.call(this, data);
    }
  })(XMLHttpRequest);
})();