'use strict';
import queryApi, { gql } from "../data/graphql/query-api";

angular.module('meanApp')
  .factory('GraphQL', function ($http) {
    return {
      query: function (p_query, p_variables){
        return queryApi(p_query, p_variables);
      }
    };
  });

