angular
  .module("meanApp")
  .controller("futurePlansBeta-controller", function($scope, $stateParams, Auth, ReactWrapper) {
    "use strict";

    const wrapper = new ReactWrapper($scope);
    import('FuturePlans').then((FuturePlans) => {
      Auth.isAdmin().then(isAdmin => ($scope.isAdmin = isAdmin));

      wrapper.setup(FuturePlans);
      wrapper.bind(document.getElementById("react-wrapper-future-plans"));
      wrapper.mount(FuturePlans.default, {
          id: $stateParams.clientId,
          url: process.env.API_ENDPOINT
        });
    });
  });
