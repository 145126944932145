import { tryToGetAccessJWT } from "../util/auth/for-interceptor-lib";

function AuthService($location, $http, $cookies, $window, $q, appConfig, Util, User, appHostnames) {
  'ngInject';

  var safeCb = Util.safeCb;
  var currentUser = {};
  var userRoles = appConfig.userRoles || [];

  var tpcHosts = appConfig.tpcHosts || [];
  var ewHosts = appConfig.ewHosts || [];
  const monthInMilliSeconds = 2629800000
  if ($cookies.get('token') && $location.path() !== '/logout') {
    currentUser = User.get();
  }

  function setLocalStorageWithExpiry(key, value, ttl) {
    const now = new Date()

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    }
    secureSessionStorage.setItem(key, JSON.stringify(item))
  }

  function setLoggedUserEmail(email) {
    secureSessionStorage.setItem('loggedEmail', email)
  }

  function getWithExpiry(key) {
    const itemStr = secureSessionStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      secureSessionStorage.removeItem(key)
      return null
    }
    return item.value
  }

  var Auth = {
    /**
     * Authenticate user and save token
     *
     * @param  {Object}   user     - login info
     * @param  {Function} callback - optional, function(error, user)
     * @return {Promise}
     */
    login({
      email,
      password
    }, callback) {
      const loggedInBeforeUser = secureSessionStorage.getItem('loggedEmail');
      // will remove token to avoid issues like invalid token
      if (email !== loggedInBeforeUser) {
        $cookies.remove('sameOriginToken')
        secureSessionStorage.removeItem('sameOriginToken')
        setLoggedUserEmail(email)
      }
      const sameOriginTokenLocal = getWithExpiry('sameOriginToken')
      const sameOriginToken = typeof $cookies.get('sameOriginToken') === 'undefined' && sameOriginTokenLocal ? sameOriginTokenLocal : $cookies.get('sameOriginToken')
      return $http.post('/auth/local', {
        email: email,
        password: password
      }, {
        headers: {
          'sameOriginToken': sameOriginToken,
        }
      })
        .then(res => {
          $cookies.put('token', res.data.token);
          $cookies.put('tokenExpiry', res.data.tokenExpiry);
          if (res.data.sameOriginToken && !res.data.isTokenExpired) {
            $cookies.put('sameOriginToken', res.data.sameOriginToken)
            setLocalStorageWithExpiry('sameOriginToken', res.data.sameOriginToken, monthInMilliSeconds)
          }
          if(res.data.isTokenExpired) {
            secureSessionStorage.removeItem('sameOriginToken');
            $cookies.remove('sameOriginToken');
          }
          currentUser = User.get();
          return currentUser.$promise;
        })
        .then(user => {
          // Store User Info
          if (user && user._id)
            gtag('set', 'user_properties', {
              user_role: user.role,
              user_type: user.role.match(/admin/) ? 'admin' : user.type,
              user_id: user._id,
              moorr_id: user._id,
            });

          else
            gtag('event', "profile_error", {});

          // Callback
          safeCb(callback)(null, user);
          return user;
        })
        .catch(err => {
          if (Auth.getFFType() === 'Full') {
            Auth.logout(true);
          } else {
            Auth.logout();
          }


          safeCb(callback)(err.data);
          return $q.reject(err.data);
        });
    },

    /**
     * Delete access token and user info
     */
    logout(fromError) {
      if (fromError !== true) {
        $cookies.remove('ffType');
      }
      currentUser = {};
    },


    /**
     * Register user for 2FQ
     *
     * @param  {Object}  phone details
     * @param  {Function} callback - optional, function(error, user)
     * @return {Promise}
     */
    register({
      countryCode,
      mobileNumber
    }, callback) {
      return $http.post('/auth/2fa/register', {
        countryCode: countryCode,
        mobileNumber
      })
        .then(res => {
          currentUser = User.get();
          return currentUser.$promise;
        })
        .then(user => {
          safeCb(callback)(null, user);
          return user;
        })
        .catch(err => {
          safeCb(callback)(err.data);
          return $q.reject(err.data);
        });
    },

    /**
     * Change phone number
     *
     * @param  {Object}  phone details
     * @param  {Function} callback - optional, function(error, res)
     * @return {Promise}
     */
    change({
      countryCode,
      mobileNumber,
      uid
    }, callback) {
      return $http.post('/auth/2fa/change', {
        countryCode: countryCode,
        mobileNumber: mobileNumber,
        uid: uid
      })
        .then(res => {
          safeCb(callback)(null, res);
          return res;
        })
        .catch(err => {
          safeCb(callback)(err.data);
          return $q.reject(err.data);
        });
    },

    /**
     * Verify code for 2FQ
     *
     * @param  {Object}  code
     * @param  {Function} callback - optional, function(error, user)
     * @return {Promise}
     */
    verifyCode({
      verificationCode
    }, callback) {
      return $http.post('/auth/2fa/verify', {
        verificationCode: verificationCode
      })
        .then(res => {
          $cookies.put('token', res.data.token);
          $cookies.put('sameOriginToken', res.data.sameOriginToken, {
            expires: new Date(new Date().setMonth(new Date().getMonth()+1))
          });
          setLocalStorageWithExpiry('sameOriginToken', res.data.sameOriginToken, monthInMilliSeconds)
          currentUser = User.get();
          return currentUser.$promise;
        })
        .then(user => {
          safeCb(callback)(null, user);
          return user;
        })
        .catch(err => {
          safeCb(callback)(err.data);
          return $q.reject(err.data);
        });
    },
    /**
     * send verification code for 2FQ
     *
     * @param  {Object}  email
     * @param  {Function} callback - optional, function(error, user)
     * @return {Promise}
     */
    resendVerificationCode({
      email
    }, callback) {
      return $http.post('/auth/2fa/requestNewPin', {
        userEmail: email
      })
        .then(res => {

          return res;
        })
        .catch(err => {
          safeCb(callback)(err);
          return $q.reject(err);
        });
    },
    /**
     * Create a new user
     *
     * @param  {Object}   user     - user info
     * @param  {Function} callback - optional, function(error, user)
     * @return {Promise}
     */
    createUser(user, callback) {
      return User.save(user, function (data) {
        $cookies.put('token', data.token);
        currentUser = User.get();
        return safeCb(callback)(null, user);
      }, function (err) {
        Auth.logout();
        return safeCb(callback)(err);
      })
        .$promise;
    },

    /**
     * Change password
     *
     * @param  {String}   oldPassword
     * @param  {String}   newPassword
     * @param  {Function} callback    - optional, function(error, user)
     * @return {Promise}
     */
    changePassword(oldPassword, newPassword, callback) {
      return User.changePassword({
        id: currentUser._id
      }, {
        oldPassword: oldPassword,
        newPassword: newPassword
      }, function () {
        return safeCb(callback)(null);
      }, function (err) {
        return safeCb(callback)(err);
      })
        .$promise;
    },

    /**
     * Accept TC
     *
     * @param  {Boolean}   isTCAccepted
     * @param  {Function} callback    - optional, function(error, user)
     * @return {Promise}
     */
    acceptTC(isTCAccepted, callback) {
      return User.acceptTC({
        id: currentUser._id
      }, {
        isTCAccepted: isTCAccepted
      }, function () {
        return safeCb(callback)(null);
      }, function (err) {
        return safeCb(callback)(err);
      })
        .$promise;
    },


    /**
     * Reset forgot password
     *
     * @param  {String}   email
     * @param  {Function} callback    - optional, function(error, user)
     * @return {Promise}
     */
    forgotPassword(email, callback) {
      return User.forgotPassword(
        {
          email: email
        }, function () {
          return safeCb(callback)(null);
        }, function (err) {
          return safeCb(callback)(err);
        })
        .$promise;
    },

    /**
     * Gets all available info on a user
     *   (synchronous|asynchronous)
     *
     * @param  {Function|*} callback - optional, function(user)
     * @return {Object|Promise}
     */
    getCurrentUser(callback) {
      if (arguments.length === 0) {
        return currentUser;
      }

      var value = currentUser.hasOwnProperty('$promise') ? currentUser.$promise : currentUser;
      return $q.when(value)
        .then(user => {
          safeCb(callback)(user);
          return user;
        }, () => {
          safeCb(callback)({});
          return {};
        });
    },

    checkCurrentUser(){
      return this.getCurrentUser(null).then((user) => {
        if (user && user._id) return user;
        currentUser = User.get();
        return currentUser.$promise;
      })
    },

    /**
     * Check if a user is logged in
     *   (synchronous|asynchronous)
     *
     * @param  {Function|*} callback - optional, function(is)
     * @return {Bool|Promise}
     */
    isLoggedIn(callback) {
      if (arguments.length === 0) {
        return currentUser.hasOwnProperty('role');
      }

      return Auth.getCurrentUser(null)
        .then(user => {
          var is = user.hasOwnProperty('role');
          safeCb(callback)(is);
          return is;
        });
    },

    /**
     * Check if a user has a specified role or higher
     *   (synchronous|asynchronous)
     *
     * @param  {String}     role     - the role to check against
     * @param  {Function|*} callback - optional, function(has)
     * @return {Bool|Promise}
     */
    hasRole(role, callback) {
      var hasRole = function (r, h) {
        return userRoles.indexOf(r) >= userRoles.indexOf(h);
      };

      if (arguments.length < 2) {
        return hasRole(currentUser.role, role);
      }

      return Auth.getCurrentUser(null)
        .then(user => {
          var has = user.hasOwnProperty('role') ? hasRole(user.role, role) : false;
          safeCb(callback)(has);
          return has;
        });
    },

    /**
     * Check if a user is an admin
     *
     * @return {Promise<Bool>}
     */
    isAdmin() {
      return tryToGetAccessJWT().then(({ decoded: { role } }) => role.endsWith('admin'));
    },



    /**
     * Get User Type
     *
     * @return {Promise<Bool>}
     */
    getUserType() {
      return currentUser.$promise
        ? currentUser.$promise.then(user => user.type)
        : Promise.resolve(false);
    },

    /**
     * Get User Role
     *
     * @return {Promise<Bool>}
     */
    getUserRole() {
      return currentUser.$promise
        ? currentUser.$promise.then(user => user.role)
        : Promise.resolve(false);
    },

    /**
     * Check if a user has registered to 2FA
     *
     * @param  {Function|*} callback - optional, function(has)
     * @return {Bool|Promise}
     */
    hasRegistered2FA(callback) {

      if (arguments.length < 2) {
        if (currentUser.authyConfirmed !== false) {
          return currentUser.authId;
        }
      }

      return Auth.getCurrentUser(null)
        .then(user => {
          var has = user.hasOwnProperty('authId') ? currentUser.authId != null : false;

          safeCb(callback)(has);
          return has;
        });
    },

    /**
     * Get auth token
     *
     * @return {String} - a token string used for authenticating
     */
    getToken() {
      return $cookies.get('token');
    },

    /**
     * Get same origin token
     *
     * @return {String} - a token string
     */
    getSameOriginToken() {
      return $cookies.get('sameOriginToken');
    },

    // Get same origin token in local storage
    getSameOriginTokenInLocal(key) {
      return getWithExpiry(key)
    },

    /**
     * Get Fact find type
     *
     * @return {String} - fact find type
     */
    getFFType() {
      return $cookies.get('ffType');
    },

    /**
     * Set Fact find type
     *
     * @return {String} - fact find type
     */
    setFFType(ffType) {
      return $cookies.put('ffType', ffType);
    },

    /**
     * Checks if the url is for TPC
     */
     getIsTPCHost() {
      // Deprecated, do not use!
      console.error('Invalid call to check TPC Host!');
      return false;
      
      var hostname = $location.$$host;
      return appHostnames.tpcHosts.indexOf(hostname) > -1;
    },

    /**
     * Checks if TPC User
     */
    getIsTPCUser() {
      return currentUser.$promise
        ? currentUser.$promise.then(user => {
          // Store User Info
          if (user && user._id)
            gtag('set', 'user_properties', {
              user_role: user.role,
              user_type: user.role.match(/admin/) ? 'admin' : user.type,
              user_id: user._id,
              moorr_id: user._id
            });

          else
            gtag('event', "profile_error", {});

          // Return
          return user.role == 'user' && user.type == "tpc";
        })
        : Promise.resolve(false);
    },
    
    /**
     * Checks if the url is for production environment (TPC or EW)
     */
    isProdHost() {
      var hostname = $location.$$host;
      return (appHostnames.ewHosts.indexOf(hostname) === 2 || appHostnames.tpcHosts.indexOf(hostname) === 2);
    },
    /**
     * Checks the url to know which type of app to show
     *
     * @return {String}
     */
    getURLType() {
      var host = window.location.hostname;

      if (tpcHosts.indexOf(host) > -1) {
        return 'tpc';
      }

      if (ewHosts.indexOf(host) > -1) {
        return 'ew';
      }
      return '';
    },

    /**
     * Register user for 2FQR
     *
     * @param  {Function} callback - optional, function(error, user)
     * @return {Promise}
     */
    register2faqr(callback) {
      return $http.get('/auth/2fa/register2faQR')
        .then(res => {
          currentUser = User.get();
          return currentUser.$promise;
        })
        .then(user => {
          safeCb(callback)(null, user);
          return user;
        })
        .catch(err => {
          safeCb(callback)(err.data);
          return $q.reject(err.data);
        });
    },

    /**
     * Get QR code data and secret key
     */
    getQRcode(callback) {
      return $http.get('/auth/2fa/qrcode')
        .then(res => {
          return res.data;
        })
        .catch(err => {
          safeCb(callback)(err.data);
          return $q.reject(err.data);
        });
    },
    /**
     * Verify code for 2FQ
     *
     * @param  {Object}  code
     * @param  {Function} callback - optional, function(error, user)
     * @return {Promise}
     */
    verifyCode2faqr({
      verificationCode
    }, callback) {
      return $http.post('/auth/2fa/verify2faqr', {
        verificationCode: verificationCode
      })
        .then(res => {
          $cookies.put('token', res.data.token);
          $cookies.put('sameOriginToken', res.data.sameOriginToken, {
            expires: new Date(new Date().setMonth(new Date().getMonth()+1))
          });
          setLocalStorageWithExpiry('sameOriginToken', res.data.sameOriginToken, monthInMilliSeconds)
          currentUser = User.get();
          return currentUser.$promise;
        })
        .then(user => {
          safeCb(callback)(null, user);
          return user;
        })
        .catch(err => {
          safeCb(callback)(err.data);
          return $q.reject(err.data);
        });
    },

  };

  return Auth;
}

export default AuthService;
