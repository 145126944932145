import queryApi, { gql } from "../graphql/query-api";

const homeDashboardQuery = (wrapper: string, args: string) => `
query Query ${args} {
  ${wrapper} {
    type
    client {
      surveyComplete
      homeDashboard {
        client1Income: income(owner:"client1") {
          gross
          types {
            key
            value
          }
          deductions {
            key
            value
          }
          grossTaxable
          nonTaxable
          netTaxable
          takeHomePay
          net
          tax
        }
        client2Income: income(owner:"client2") {
          gross
          types {
            key
            value
          }
          deductions {
            key
            value
          }
          grossTaxable
          nonTaxable
          netTaxable
          takeHomePay
          net
          tax
        }
        personalInfo {
          client1 {
            _id
            fName
            lName
          }
          client2 {
            _id
            fName
            lName
          }
          hasClient2
        }
        assets(owner: "both", frequency: "yearly") {
          contributions {
            total
            types {
              key
              value
            }
          }
          investment
          nonInvestment
          total
          types {
            key
            value
          }
        }
        borrowings(owner: "both", frequency: "yearly") {
          outstanding {
            types {
              key
              value
            }
            total
          }
          repayments {
            total
          }
        }
        cashflow(owner: "both", frequency: "yearly") {
          moneyIn
          moneyOut
          surplus
        }
        expenses {
          yearly {
            total
            breakdown {
              key
              total
            }
          }
        }
      }
      propertyDashboard(owner: "both", frequency: "yearly") {
        properties {
          _id
          name
          address
          purpose
          currentValue
          totalCurrentValue
          ownership {
            owners {
              owner
              percentage
            }
          }
          lvr
          equity80
          equity100
          interest {
            monthly
            yearly
          }
          securedLoans {
            name
            provider
            type
            outstanding
            repayments {
              monthly
              yearly
            }
            purposeProperties
            securedProperties
            primaryPurpose
            limit
            interestRate
          }
          purposeLoans {
            name
            provider
            type
            outstanding
            purposeProperties
            securedProperties
            primaryPurpose
            limit
            interestRate
          }
        }
        equity80
        equity100
        lvr
        loans {
          limit
          outstanding
          repayments
          interest
        }
      }
    }
  }
}`;

export default async function queryHomeDashboard(uid?: string) {
  const query = uid ? homeDashboardQuery("user(id: $userId)", "($userId: ObjectId)") : homeDashboardQuery("me", "");
  return queryApi<any>(
    query.trim().replace(/\s+/g, ' '),
    { userId: uid }
  ).then((response) => {
    const client = response.user?.client || response.me?.client;
    if (client) {
      client.userType = response.user?.type || response.me?.type;
      return client;
    } else {
      return undefined;
    }

    return Promise.reject(new Error("invalid-response"));
  });
}
