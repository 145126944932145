angular
  .module("meanApp")
  .controller("pwp-workbench-controller", function($scope, $stateParams, ReactWrapper) {
    "use strict";

    const wrapper = new ReactWrapper($scope);
    import('PWPWorkbench').then((PWPWorkbench) => {
      wrapper.setup(PWPWorkbench);
      wrapper.bind(document.getElementById("react-wrapper-pwp-workbench"));
      wrapper.mount(PWPWorkbench.default, { uid: $stateParams.clientId });
    });
  });
