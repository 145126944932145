import { NEW_TO_OLD_CATEGORIES_MAP } from ".";

// FLEX categories
export const FLEX_CATEGORIES = {
  otherPayments: [
    "Groceries and Non-alcoholic Beverages",
    "Clothing, Footwear, Dry cleaning, Repairs",
    "Education - Out-of-pocket expenses",
    "Medical and Health Care expenses",
    "Personal Care",
    "Entertainment",
    "Sport, recreation and hobbies",
    "Gambling and Lotto",
  ],
  livingExpense: [
    "Rent",
    "House and Contents Insurance",
    "Telephone - Fixed and Mobile",
    "Internet",
    "Digital Subscriptions",
    "Health Insurance",
    "Life Insurance",
    "Vehicle Insurance",
    "Education - School Fees",
    "Child Care Fees",
  ],
  transportExpenses: [
    "Vehicle Registration",
    "Motoring Organisations",
    "Fares",
    "Fuel",
    "Parking and Tolls",
    "Vehicle Maintenance",
  ],
  utitilitiesOOExpenses: [
    "Electricity",
    "Gas",
    "Local Government Rates",
    "Water and Sewerage Rates and Charges",
  ],
  otherExpenses: [
    "Accountant / Tax agent fees",
    "Alcohol and Tobacco",
    "Books, Magazines and Newspapers",
    "Dining out / Takeaway food",
    "Donations",
    "Furniture",
    "Holidays",
    "House and Garden maintenance",
    "Appliances and Tools",
    "Household Furnishings",
    "Pets and Animals",
    "Presents and Gifts",
    "Digital / Video Purchases or Hire ",
    "Bank Fees",
  ],
  expenseTypeOther: "Other Living Expenses",
  expenseTypeLiving: "Living Expenses",
  rent: "Rent",
  payTelevision: "Digital Subscriptions",
  internet: "Internet",
};

export const FLEX_CATEGORY_INDEX = {
  education: "Education",
  insurance: "Insurance",
  entertainment: "Entertainment",
  recreation: "recreation",
  clothing: "Clothing",
  groceries: "Groceries",
  medical: "Medical",
  telephone: "Telephone",
  child: "Child",
};

export const OLD_FLEX_CATEGORIES = {};

Object.entries(FLEX_CATEGORIES).forEach(([key, categories]) => {
  if (Array.isArray(categories)) {
    OLD_FLEX_CATEGORIES[key] = categories.map((category) => {
      return NEW_TO_OLD_CATEGORIES_MAP[category];
    });
  } else if (typeof categories === "string") {
    OLD_FLEX_CATEGORIES[key] =
      NEW_TO_OLD_CATEGORIES_MAP[OLD_FLEX_CATEGORIES[key]];
  }
});
