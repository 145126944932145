'use strict';
angular.module('meanApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('step2', {
        url: '/step2/:clientId',
        params: {
          clientId: {
              value: null,
              squash: false
          }
        },
        templateUrl: 'app/tpcPortal/step2/step2.html',
        controller: 'step2-controller',
        authenticate: ['user','admin']
      })

  });
