import { EXPENSES_DEFAULT, EXPENSES_DEFAULT_ARRAY } from ".";

// used in factfind controller
export const billItemCategory = EXPENSES_DEFAULT.bills.map((expense) => ({
  value: expense.category,
  label: expense.category,
  isSelectable: true,
}));

export const spendingItemCategory = EXPENSES_DEFAULT.spending.map(
  (expense) => ({
    value: expense.category,
    label: expense.category,
    isSelectable: true,
  })
);

export const billDefault = EXPENSES_DEFAULT.bills.map((expense) => ({
  desc: expense.category,
  name: expense.category,
  category: expense.jar,
  expenseGroup: "Bills",
  type: "Bills",
  customExpense: false,
  frequency: "Monthly",
  basic: 0,
  discretionary: 0,
  amount: 0,
  totalString: "",
  monthly: "",
  amountStr: "",

  tier1: expense.tier1,
  tier2: expense.tier2,
  tier3: expense.tier3,
  tier4: expense.tier4,
}));

export const billDefaults = billDefault;

export const spendingDefault = EXPENSES_DEFAULT.spending.map((expense) => ({
  desc: expense.category,
  name: expense.category,
  category: expense.jar,
  expenseGroup: "Spending",
  type: "Spending",
  customExpense: false,
  frequency: "Monthly",
  basic: 0,
  discretionary: 0,
  amount: 0,
  totalString: "",
  monthly: "",
  amountStr: "",

  tier1: expense.tier1,
  tier2: expense.tier2,
  tier3: expense.tier3,
  tier4: expense.tier4,
}));

export const spendingDefaults = spendingDefault;

export const defaultExpensesBills = EXPENSES_DEFAULT.bills.map((expense) => ({
  tier1: expense.tier1,
  tier2: expense.tier2,
  tier3: expense.tier3,
  tier4: expense.tier4,
  desc: expense.category,
  amount: null,
  discretionary: null,
  frequency: "Monthly",
  owner: "Joint",
  category: expense.jar,
}));

export const defaultExpensesSpending = EXPENSES_DEFAULT.spending.map(
  (expense) => ({
    desc: expense.category,
    tier4: expense.tier4,
    amount: null,
    category: expense.jar,
    frequency: "Monthly",
  })
);

// used in step1.controller
export const defaultExpenses = EXPENSES_DEFAULT_ARRAY.map((expense) => ({
  desc: expense.category,
}));

// used in step2.controller & step3.controller
export const defaultExpensesDropdown = [...billDefaults, ...spendingDefaults];

// used in basicExpenses controller
export const defaultBills = EXPENSES_DEFAULT.bills.map((expense) => ({
  tier1: expense.tier1,
  tier2: expense.tier2,
  tier3: expense.tier3,
  tier4: expense.tier4,
  desc: expense.category,
  amount: null,
  frequency: "",
  owner: "Joint",
}));

export const defaultSpendings = EXPENSES_DEFAULT.spending.map((expense) => ({
  tier4: expense.tier4,
  desc: expense.category,
  amount: 0,
  frequency: "",
}));
