"use strict";

angular.module("meanApp")
  .config(function($stateProvider) {
    $stateProvider
      .state("moneysmarts", {
        url: "/moneysmarts/:clientId?{v1:bool}",
        params: {
          clientId: {
            value: null,
            squash: false
          },
          showTour: {
            value: false,
            squash: false
          },
          v1: {
            value: false,
            squash: false
          },
          tabValue:{
            value: 0
          }
        },
        templateUrl: "app/moneysmarts/moneysmarts.html",
        controller: "moneysmarts-controller",
        authenticate: ["user", "admin"]
      })
  })
  .config(function($stateProvider) {
    $stateProvider
      .state("moneysmartsFit", {
        url: "/moneyfit/:clientId",
        params: {
          clientId: {
            value: null,
            squash: false
          },
          showTour: {
            value: false,
            squash: false
          },
          tabValue:{
            value: 1
          }
        },
        templateUrl: "app/moneysmarts/moneysmarts.html",
        controller: "moneysmarts-controller",
        authenticate: ["user", "admin"]
      })
  })
  .config(function($stateProvider) {
    $stateProvider
      .state("moneysmartsStretch", {
        url: "/moneystretch/:clientId",
        params: {
          clientId: {
            value: null,
            squash: false
          },
          showTour: {
            value: false,
            squash: false
          },
          tabValue:{
            value: 2
          }
        },
        templateUrl: "app/moneysmarts/moneysmarts.html",
        controller: "moneysmarts-controller",
        authenticate: ["user", "admin"]
      })
  });
