import queryApi, { gql } from "../graphql/query-api";

const mutateClient = (wrapper: string, args: string) => `
mutation ($data: ClientUpdateData!) {
  me {
    client {
      update(data: $data) {
        _id
      }
    }
  }
}
`;

export default async function completeOnboarding(data: any) {
  const query = mutateClient("", "");
  return queryApi<any>(
    query.trim().replace(/\s+/g, ' '),
    { data }
  ).then((response) => {
    const client = response?.me?.client;
    if (response.__errors){
      return response;
    } else if (client) {
      return client;
    } else {
      return response;
    }

    return Promise.reject(new Error("invalid-response"));
  });
}
