export type CrossLocation = {
  url: (
    // eslint-disable-next-line no-unused-vars
    to: string,
    // eslint-disable-next-line no-unused-vars
    parameters?: { [key: string]: any }
  ) => void;

  pushr: (
    // eslint-disable-next-line no-unused-vars
    url: string
  ) => void;
};

export const location: CrossLocation = {
  url() {},

  pushr() {},
};

export const toggleLocationHash = (ms = (1000 / 3) * 2) => {
  const { hash } = window.location;

  if (hash.length > 1) {
    window.location.hash = "";

    setTimeout(() => {
      window.location.hash = hash;
    }, ms);
  }
};
