
export default  {
    getAnnualAmount: function (amount, frequency) {

        var annual = 0;
        if (amount > 0) {
            switch (frequency) {
                case "Weekly":
                    annual = amount*52*12;
                    break;
                case "Fortnightly":
                    annual = amount * 26;
                    break;
                case "Monthly":
                    annual = amount * 12;
                    break;
                case "Every 2 months":
                case "Bi-Monthly":
                    annual = amount * 6;
                    break;
                case "Every 3 months":
                case "Quarterly":
                    annual = amount * 4;
                    break;
                case "Every 6 months":
                    annual = amount * 2;
                    break;
                case "Yearly":
                    annual = amount;
                    break;
                default:
                    annual = 0;
                    break;
            }
        }
        return annual;
    },
    getAnnualAmountTaxDeductible: function(amount,frequency){
        var annual = 0;
        switch(frequency)
        {
          case "Weekly":
            annual += amount*52/12;
            break;
          case "Fortnightly":
            annual  += (amount*26/12);
            break;
          case "Monthly":
            annual  += amount;
            break;
          case "Every 2 months":
            annual  += (amount&6/12);
            break;
          case "Every 3 months":
            annual  += (amount*4/12);
            break;
          case "Every 6 months":
            annual  += (amount*2/12);
            break;
          case "Yearly":
            annual  += (amount/12);
            break;
        }
        annual = annual*12;

        return annual;
    },
    getAnnualAmountExpenses: function (amount, frequency) {

        var annual = 0;
        if (amount > 0) {
            switch(frequency)
            {
              case "Weekly":
                annual += amount*52;
                break;
              case "Fortnightly":
                annual += amount*26;
                break;
              case "Monthly":
                annual += amount*12;
                break;
              case "Every 2 months":
                annual += amount*6;
                break;
              case "Every 3 months":
                annual += amount*4;
                break;
              case "Every 6 months":
                annual += amount*2;
                break;
              case "Yearly":
                annual += amount;
                break;
            }
        }
        return annual;
    },
    getAnnualLoanRepayments: function (amount, frequency) {

        var annual = 0;
        if (amount > 0) {
            switch(frequency){
                case "Weekly":
                annual += ((amount*52)/12,10);
                  break;
                case "Fortnightly":
                annual += ((amount *26)/12,10);
                  break;
                case "Monthly":
                annual += (amount,10);
                  break;
                case "Bi-Monthly":
                annual += (amount,10)/2;
                  break;
                case "Quarterly":
                annual += (amount,10)/4;
                  break;
                default:
                annual += (amount,10)/12;
                  break;
              }
              annual = annual*12;
        }
        return annual;
    },

    getCurrentValue: function (assetObject, clientId) {
        var currentValue = 0;
        if (assetObject.currentValue) {
            if (clientId) {
                // Check ownership
                if (assetObject.ownership && assetObject.ownership.owners) {
                    for (var i = 0; i < assetObject.ownership.owners.length; i++) {
                        var owner = assetObject.ownership.owners[i];
                        if (owner.owner == clientId) {
                            currentValue += (assetObject.currentValue * (owner.percentage / 100));
                        }
                    }
                }
            } else {
                currentValue += assetObject.currentValue;
            }
        }
        return currentValue;
    },
    getValue: function (assetObject, clientId) {
        var currentValue = 0;
        if (assetObject.value) {
            if (clientId) {
                // Check ownership
                if (assetObject.ownership && assetObject.ownership.owners) {
                    for (var i = 0; i < assetObject.ownership.owners.length; i++) {
                        var owner = assetObject.ownership.owners[i];
                        if (owner.owner == clientId) {
                            currentValue += (assetObject.value * (owner.percentage / 100));
                        }
                    }
                }
            } else {
                currentValue += assetObject.value;
            }
        }
        return currentValue;
    },
    getBalance: function (assetObject, clientId) {
        var balance = 0;
        if (assetObject.balance) {
            if (clientId) {
                // Check ownership
                if (assetObject.ownership && assetObject.ownership.owners) {
                    for (var i = 0; i < assetObject.ownership.owners.length; i++) {
                        var owner = assetObject.ownership.owners[i];
                        if (owner.owner == clientId) {
                            balance += (assetObject.balance * (owner.percentage / 100));
                        }
                    }
                }
            } else {
                balance += assetObject.balance;
            }
        }
        return balance;
    },
    getContentsValue: function (assetObject, clientId) {
        var contentsValue = 0;
        if (assetObject.contentsValue) {
            if (clientId) {
                // Check ownership
                if (assetObject.ownership && assetObject.ownership.owners) {
                    for (var i = 0; i < assetObject.ownership.owners.length; i++) {
                        var owner = assetObject.ownership.owners[i];
                        if (owner.owner == clientId) {
                            contentsValue += (assetObject.contentsValue * (owner.percentage / 100));
                        }
                    }
                }
            } else {
                contentsValue += assetObject.contentsValue;
            }
        }
        return contentsValue;
    },
    getOutstanding: function (loanObject, clientId) {
        var outstanding = 0;
        if (loanObject.outstanding) {
            if (clientId) {
                // Check ownership
                if (loanObject.borrower && loanObject.borrower.owners) {
                    for (var i = 0; i < loanObject.borrower.owners.length; i++) {
                        var owner = loanObject.borrower.owners[i];
                        if (owner.owner == clientId) {
                            outstanding += (loanObject.outstanding * (owner.percentage / 100));
                        }
                    }
                }
            } else {
                outstanding += loanObject.outstanding;
            }
        }
        return outstanding;
    },
    initAddressField: function (address) {
        if (address) {
            address.isStateManual = false
            address.frontFormatted =_ .clone(address.formatted)
            address.isAddressValid = true
            address.addressClass = 'isValid'
            address.isFocused = false

            if (!address.isManual && address.formatted) {
                address.addressClass += ' isNotManual'
            }
        }
    },
    addressFieldOnClickHandler: function (obj, id, type) {
        // DO NOT USE THIS CODE ANY MORE
        // Now handled in ngAutoComplete.js
        return;

        let data = obj.find(i => i._id === id);
        if(data === undefined) { // new
            data = obj.find(i => i.$$hashKey === id);
        }
        //set Address Valid To False
        const setAddressFieldAttrOnFocus = (address) => {
            if(this.formatAddress(address).trim() === "") {
                address.formatted = 
                address.country = 
                address.floor = 
                address.number = 
                address.postcode = 
                address.state = 
                address.street = 
                address.streetType = 
                address.suburb = 
                address.unit = "";
                address.isAddressValid = true;
                address.addressClass='valid';
                address.isFocused = false;
            } else if(address) { // if property
                address.isAddressValid=false;
                address.addressClass='notValid';
                address.isFocused = true;
            }
        }
        switch(type) {
            case "property" : 
                setAddressFieldAttrOnFocus(data.address)
                break;
            case "income":
            case "incomeSecondary":
            case "client1PreviousEmployment":
            case "client2PreviousEmployment":
            case "client2Employment":
            case "incomeSelfEmployed":
                if(data.employmentType==="Self Employed") {
                    setAddressFieldAttrOnFocus(data.business.registeredAddress)
                }
                else {
                    setAddressFieldAttrOnFocus(data.job.employer.address)
                }
                break;
        }
    },
    addressSwitchToManual: function (obj, id, value, type) {
        let data = obj.find(i => i._id === id)
        if(data === undefined) { // new
          data = obj.find(i => i.$$hashKey === id)
        }
        
        let address;
        switch(type) {
            case "property" :
                address =  data.address;
                break;
            case "income":
            case "incomeSecondary":
            case "client1PreviousEmployment":
            case "client2PreviousEmployment":
            case "client2Employment":
            case "incomeSelfEmployed":
                address = (data.employmentType==="Self Employed") 
                ? data.business.registeredAddress 
                : data.job.employer.address;
                break;
        }

        // modify isManual only if it's true, if it's false, don't touch (either it's from true or false).
        address.isManual = !!value || address.isManual
        address.isAddressValid = false; // always set this to false everytime this function is called
        address.isStateManual = value;
        address.isFocused = value;     
    },
    updateAddressField : function(address) {
        address.formatted = this.formatAddress(address);
    },
    formatAddress: function (address) {
        return [
          address.floor,
          [address.unit, address.number].filter(Boolean).join('/'),
          address.street,
          address.streetType,
          address.suburb,
          address.postcode,
          address.state,
          address.country
        ].filter(Boolean).join(' ');
    }
}
