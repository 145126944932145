import { dispatchShowAlertChanges } from "../../modules/util/events";

angular
  .module("meanApp")
  .controller(
    "personal-info-updated-controller",
    function (
      $scope,
      $rootScope,
      $state,
      $stateParams,
      DataM,
      $window,
      Auth,
      empowerDataService,
      $location,
      $timeout,
      Idle,
      DataHelper,
      $uibModal,
      ReactWrapper
    ) {
      "use strict";
      $scope.isAdmin = false;
      Auth.isAdmin().then((isAdmin) => ($scope.isAdmin = isAdmin));

      $scope.locked = false;
      $scope.personalInfoData = {};

      $scope.isTPCUser = false;

      $scope.reqData = $rootScope.reqData || {}

      $scope.showChangesAlert = false;
      $scope.showClientSpinner = true;
      const $clientId = $stateParams.clientId;
      const formData = {};
      if ($clientId && $clientId.length > 0) {
        formData.uid = $clientId;
      }
      const savePersonalInfo = DataM.savePersonalInfoWithFP;
      const getIsPartnerActivated = DataM.getPartnerActivated;
      const createPartner = DataM.createPartner;
      $scope.mbWarningEnabled = false;
      $scope.newLoc = "";
      $scope.isSaving = false;
      $scope.toStateName = "";
      $scope.showBottomButtons = false;
      $scope.didNextSave = false;

      $scope.$on(
        "$stateChangeStart",
        function (event, toState, toParams, fromState, fromParams) {
          if (_.isEqual(toState.name, "login")) {
          } else if (
            toState.url.indexOf("personal-info") == -1 &&
            !$scope.isSaving &&
            !$scope.didNextSave
          ) {
            $scope.isSaving = true;
            $scope.toStateName = toState.name;

            if ($scope.showBottomButtons) {
              $scope.showChangesAlert = true;
              dispatchShowAlertChanges($scope.showChangesAlert);
              event.preventDefault();
            }
          }
        }
      );

      $rootScope.changeRouteModalReact = function (isToSave, isToDiscard) {
        showReactApp($scope.mbWarningEnabled, isToSave, isToDiscard);
        $scope.closeChangesAlert();
      };

      $scope.closeChangesAlert = function (doCancel) {
        $scope.showChangesAlert = false;
        dispatchShowAlertChanges($scope.showChangesAlert);
        $scope.isSaving = false;
        if (doCancel){
          $scope.toStateName = "";
        }
      };

      function getMBWarning() {
        DataM.getMBWarningEnabled(formData)
          .then(function (data) {
            $scope.mbWarningEnabled = data.mbWarningEnabled;
            showReactApp($scope.mbWarningEnabled, false, false);
          })
          .catch(function (err) {
            console.log(err);
          });
      }

      const wrapper = new ReactWrapper($scope);
      function showReactApp(mbWarningEnabled, isToSave, isToDiscard) {
        import('PersonalInfo').then((PersonalInfo) => {
          if (
            PersonalInfo.default &&
            ($state.current.name == "personalinfo" ||
              $state.current.name === "fact-find1")
          ) {
            let isFactFind =
              $state.current.name === "fact-find1" ? true : false;

            var propsApi = {
              savePersonalInfo: savePersonalInfo,
              getIsPartnerActivated: getIsPartnerActivated,
              createPartner: createPartner,
            };

            wrapper.setup(PersonalInfo);
            wrapper.bind(document.getElementById("react-wrapper-personal"));
            wrapper.mount(PersonalInfo.default, {
              clientData: $scope.personalInfoData,
              savePersonalInfo: propsApi.savePersonalInfo,
              getIsPartnerActivated: propsApi.getIsPartnerActivated,
              createPartner: propsApi.createPartner,
              isFactFind: isFactFind,
              isTPCUser: $scope.isTPCUser,
              MBChecker: mbWarningEnabled,
              reqData: $scope.reqData,
              isToSave,
              isToDiscard,
              showModal: function (show) {
                $scope.showBottomButtons = show;
              },
              showNotifAlert: function (isSaved, isFromModal) {
                var alertMessage = "";
                if (isSaved) {
                  alertMessage = "Successfully saved changes!";
                } else {
                  alertMessage = "Successfully discarded changes!";
                }
                if (
                  $scope.toStateName !== "" &&
                  !$scope.showBottomButtons
                ) {
                  if (isFromModal) {
                    $state.go($scope.toStateName, {
                      clientId: $clientId,
                    });
                  }
                } else {
                  $rootScope.setAlertValues(true, true, alertMessage, true);
                }
              },
              updateState: function () {
                $rootScope.$broadcast("resetNotif", null);
              },
            });
          }
        });
      }

      const showPage = () => {
        DataM.getMe()
        .then(function (userData) {
          const userType = userData?.type;

          if (typeof userType !== "string") return;

          switch (userType) {
            case "tpc":
              $scope.isTPCUser = true;

            default:
            // pass
          }
        })
        .catch(function (err) {
          console.log("Retrieve current user error");
        });

        DataM.getPersonalInfoWithFP().then(function (data) {
          $scope.personalInfoData = data;
          $scope.showClientSpinner = false;
          if (Auth.getToken()) {
            getMBWarning();
          }
        }).catch(error => {
          console.log("Personal Info Request Data:", error);
          $scope.showClientSpinner = false;
        });
      }

      showPage();

      $scope.$on("reqData", function (evt, reqData) {
        $scope.reqData = reqData;
        showPage();
      });
    }
  );
