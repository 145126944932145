const controller = function ($scope, DataM, Auth, $state, $stateParams, $uibModal, $timeout, $q) {
    'use strict';
    const optiMessageType = "mortgagemessage_2022_10";
    const gtagEnabled = true;
    const gtagEvent = "opti_message";
    const gtagTitle = optiMessageType;

    /**
     * 
     */
    $scope.init = function () {
        return Auth.getIsTPCUser().then(function (isTPCUser) {
            $scope.isTPCUser = isTPCUser;
            
            // User type is not equal to TPC, opti message should never be shown
            if (!$scope.isTPCUser)
                return $q.resolve();

            // Assess current user's loans
            $scope.assessLoans();

            // If loans is assessed and loan assessment passed
            const tasks = [];
            if ($scope.loanAssessed
                && $scope.loanAssessmentPassed
            ) {
                // Getting admin settings
                tasks.push(DataM.getSetting({ name: "OptiMortgageRateMessage" }).then(function (settingsData) {
                    $scope.optiMortgageRateMessageSettings = settingsData;
                }));

                // Getting current user opti message status
                tasks.push(DataM.getOptiMessageCurrentUserStatus({ messageId: optiMessageType }).then(function (optiMessageStatus) {
                    $scope.optiMessageStatus = optiMessageStatus;
                }));
            }
            return $q.all(tasks).then(function () {
                $scope.optiMessageState = "teaser";
                $scope.messageContentReady = true;
                $scope.initialized = true;
                $timeout(function () {
                    const tasks = [];
                    if ($scope.isOptiVisible()) {
                        // Update with back-end
                        tasks.push(DataM.updateOptiMessageAction({
                            messageId: optiMessageType,
                            action: "viewed"
                        }).then(function () {
                            // Send Google analytics
                            if (gtagEnabled) {
                                gtag('event', gtagEvent, {
                                    'event_label': gtagTitle,
                                    'event_category': "viewed"
                                });
                            }
                        }));
                    }

                    return $q.all(tasks).then(function () {
                        $scope.componentReady = true;
                    });
                }, 100);
            });
        });
    }

    /**
     * 
     */
    $scope.assessLoans = function () {
        if ($scope.$ctrl.loans
            && $scope.$ctrl.loans.length > 0
        ) {
            $scope.loanAssessmentPassed = false;
            let index = 0;
            do {
                // Assess this loan
                const loan = $scope.$ctrl.loans[index++];

                // Valid loan types
                switch (loan.type) {
                    case "Investment Loan - Basic Variable":
                    case "Investment Loan - Standard Variable":
                    case "Home Loan - Basic Variable":
                    case "Home Loan - Standard Variable":
                        // Logic to consider assessment passed
                        $scope.loanAssessmentPassed =
                            // Have a loan with an outstanding balance greater than $350,000
                            loan.outstanding > 350000
                            // Have a rate higher than 2%
                            && loan.interestRate > 2
                            ;
                        break;
                }
            }
            // Repeat while the loan assessment has not passed
            while (!$scope.loanAssessmentPassed
                // Until the last loan in the collection
                && index < $scope.$ctrl.loans.length
            );
        }
        $scope.loanAssessed = true;
    }

    /**
     * 
     * @param {*} optiMessageState 
     */
    $scope.exit = function (optiMessageState) {
        var deferred = $q.defer();
        $scope.componentExiting = true;
        $timeout(function () {
            $scope.optiMessageState = optiMessageState;
            deferred.resolve();
        }, 500);

        return deferred.promise;
    }

    /**
     * 
     * @returns 
     */
    $scope.isOptiVisible = function () {
        // Check if user is TPC type
        if (!$scope.isTPCUser)
            return false;

        // Settings must be toggled on for Opti Mortgage Rate Message
        if (!$scope.optiMortgageRateMessageSettings
            || !$scope.optiMortgageRateMessageSettings.isEnabled
        ) {
            return false;
        }

        // If loans data is not available, no show
        if (!$scope.loanAssessed
            || !$scope.loanAssessmentPassed
        ) {
            return false;
        }

        // Opti message is already completed for current user
        if (!$scope.optiMessageStatus
            || $scope.optiMessageStatus.completeAt !== null
        ) {
            return false;
        }

        // Opti message is last dismissed in less than 24 hours ago
        if ($scope.optiMessageStatus.lastDismissedAt !== null) {
            const lastDismissedAt = new Date($scope.optiMessageStatus.lastDismissedAt);
            const totalHour = Math.floor(Math.abs(new Date() - lastDismissedAt) / 3600000);
            if (!isNaN(totalHour)
                && totalHour < 24
            ) {
                return false;
            }
        }

        // Component state check
        return $scope.optiMessageState !== "unsuccessful"
            && $scope.optiMessageState !== "closed"
            ;
    }

    /**
     * 
     * @returns 
     */
    $scope.getGreetingNames = function () {
        if (!$scope.$ctrl.personalInfo)
            return "User";

        const names = [];
        if ($scope.$ctrl.personalInfo.client1
            && $scope.$ctrl.personalInfo.client1.fName
        ) {
            names.push($scope.$ctrl.personalInfo.client1.fName);
        }
        if ($scope.$ctrl.personalInfo.client2
            && $scope.$ctrl.personalInfo.client2.fName
        ) {
            names.push($scope.$ctrl.personalInfo.client2.fName);
        }

        if (names.length === 0)
            return "User";

        return names.join(" and ");
    }

    /**
     * 
     * @param {*} optiMessageState 
     * @returns 
     */
    $scope.changeOptiMessageState = function (optiMessageState) {
        var deferred = $q.defer();
        $scope.messageContentReady = false;
        $timeout(function () {
            $scope.optiMessageState = optiMessageState;
            $scope.messageContentReady = true;
            deferred.resolve();
        }, 100);

        return deferred.promise;
    }

    /**
     * 
     */
    $scope.onDismissButtonClick = async function () {
        // Exit component
        await $scope.exit("closed");

        // Update with back-end
        await DataM.updateOptiMessageAction({
            messageId: optiMessageType,
            action: "dismissed"
        });

        // Send Google analytics
        if (gtagEnabled) {
            gtag('event', gtagEvent, {
                'event_label': gtagTitle,
                'event_category': "dismissed"
            });
        }
    }

    /**
     * 
     */
    $scope.onCloseButtonClick = async function () {
        // Exit component
        await $scope.exit("closed");
    }

    /**
     * 
     */
    $scope.onLearnMoreClick = async function () {
        // Change component state
        await $scope.changeOptiMessageState("open");

        // Update with back-end
        await DataM.updateOptiMessageAction({
            messageId: optiMessageType,
            action: "teaser_success"
        });

        // Send Google analytics
        if (gtagEnabled) {
            gtag('event', gtagEvent, {
                'event_label': gtagTitle,
                'event_category': "teaser_success"
            });
        }
    }

    /**
     * 
     */
    $scope.onFreeMortgageReviewClick = async function () {
        // Change component state
        await $scope.changeOptiMessageState("success");

        // Update with back-end
        await DataM.updateOptiMessageAction({
            messageId: optiMessageType,
            action: "success"
        });

        // Send Google analytics
        if (gtagEnabled) {
            gtag('event', gtagEvent, {
                'event_label': gtagTitle,
                'event_category': "success"
            });
        }
    }
    /**
     * 
     */
    $scope.onNoThanksClick = async function () {
        // Exit component
        await $scope.exit("unsuccessful");

        // Update with back-end
        await DataM.updateOptiMessageAction({
            messageId: optiMessageType,
            action: "unsuccessful"
        });

        // Send Google analytics
        if (gtagEnabled) {
            gtag('event', gtagEvent, {
                'event_label': gtagTitle,
                'event_category': "unsuccessful"
            });
        }
    }
}

angular.module('meanApp')
    .component('mortgageMessaging', {
        templateUrl: "/app/messaging/mortgage-messaging.component.html",
        controller: controller,
        bindings: {
            personalInfo: "=",
            loans: "="
        }
    });