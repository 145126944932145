export const EXPENSES_DEFAULT = {
  bills: [
    {
      category: "Rent",
      tier1: "Home Costs",
      tier2: "Rent Payments",
      tier3: "Rent Payments",
      tier4: "Regular Payments",
      jar: "Primary Direct",
    },
    {
      prevCategory: "Local Government Rates",
      category: "Council/Local Govt Rates",
      tier1: "Home Costs",
      tier2: "Council Rates",
      tier3: "Council/Local Govt Rates",
      tier4: "Bills",
      jar: "Primary Direct",
    },
    {
      category: "Water and Sewerage Rates and Charges",
      tier1: "Home Costs",
      tier2: "Council Rates",
      tier3: "Water and sewerage rates",
      tier4: "Bills",
      jar: "Credit Card",
    },
    {
      prevCategory: "House and Contents Insurance",
      category: "Property Insurance",
      tier1: "Home Costs",
      tier2: "Property Insurance",
      tier3: null,
      tier4: "Bills",
      jar: "Credit Card",
    },
    {
      category: "Electricity",
      tier1: "Home Power & Fuel",
      tier2: "Utilities",
      tier3: "Electricity",
      tier4: "Bills",
      jar: "Credit Card",
    },
    {
      category: "Gas",
      tier1: "Home Power & Fuel",
      tier2: "Utilities",
      tier3: "Mains gas",
      tier4: "Bills",
      jar: "Credit Card",
    },
    {
      category: "Telephone - Fixed and Mobile",
      tier1: "Home Services & Operations",
      tier2: "Communications",
      tier3: null,
      tier4: "Bills",
      jar: "Credit Card",
    },
    {
      category: "Internet",
      tier1: "Home Services & Operations",
      tier2: "Communications",
      tier3: "Internet charges",
      tier4: "Bills",
      jar: "Credit Card",
    },
    {
      prevCategory: "Digital Subscriptions",
      category: "Digital Subscriptions",
      tier1: "Lifestyle (Recreational)",
      tier2: "Audio & Visual",
      tier3: null,
      tier4: "Living and Lifestyle",
      jar: "Credit Card",
    },
    {
      category: "Health Insurance",
      tier1: "Personal Health Insurance",
      tier2: "Accident & Health Insurance",
      tier3: null,
      tier4: "Bills",
      jar: "Credit Card",
    },
    {
      category: "Life Insurance",
      tier1: "Personal / Professional",
      tier2: "Personal Protection Insurances",
      tier3: "Life Insurance",
      tier4: "Bills",
      jar: "Credit Card",
    },
    {
      prevCategory: "Vehicle Registration",
      category: "Vehicle Registration",
      tier1: "Transport",
      tier2: "Car License & Registration",
      tier3: "Compulsory registration",
      tier4: "Bills",
      jar: "Credit Card",
    },
    {
      prevCategory: "Vehicle Insurance",
      category: "Vehicle Insurance",
      tier1: "Transport",
      tier2: "Car Insurance",
      tier3: null,
      tier4: "Bills",
      jar: "Credit Card",
    },
    {
      category: "Motoring Organisations",
      tier1: "Transport",
      tier2: "Motoring Organisation Fees",
      tier3: "Subscription to motor organisations",
      tier4: "Bills",
      jar: "Credit Card",
    },
    {
      category: "Education - School Fees",
      tier1: "Education",
      tier2: null,
      tier3: null,
      tier4: "Bills",
      jar: "Credit Card",
    },
    {
      category: "Child Care Fees",
      tier1: "Education",
      tier2: "Pre School Care Services",
      tier3: null,
      tier4: "Bills",
      jar: "Credit Card",
    },
    {
      category: "Bank Fees",
      tier1: "Bank Charges",
      tier2: "Banking Fees",
      tier3: "Regular Payment",
      tier4: "Bills",
      jar: "Primary Direct",
    },
    {
      prevCategory: "Personal Insurance (e.g. Income Protection)",
      category: "Personal Insurance (e.g. Income Protection)",
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: null,
      jar: "Credit Card",
    },
    {
      category: "Professional Fees",
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: null,
      jar: "Credit Card",
    },
    {
      category: "Owner's/Body Corporate",
      tier1: "Home Costs",
      tier2: null,
      tier3: null,
      tier4: "Bills",
      jar: "Primary Direct",
    },
    {
      category: "Registration/Licence Fees",
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: "Bills",
      jar: "Credit Card",
    },
  ],
  spending: [
    {
      category: "Groceries and Non-alcoholic Beverages",
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: "Living Lifestyle",
      jar: "Living & Lifestyle",
    },
    {
      category: "Clothing, Footwear, Dry cleaning, Repairs",
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: "Living Lifestyle",
      jar: "Living & Lifestyle",
    },
    {
      category: "Education - Out-of-pocket expenses",
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: "Bills",
      jar: "Credit Card",
    },
    {
      category: "Medical and Health Care expenses",
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: "Bills",
      jar: "Provision",
    },
    {
      category: "Personal Care",
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: "Living Lifestyle",
      jar: "Living & Lifestyle",
    },
    {
      category: "Pets and Animals",
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: "Living Lifestyle",
      jar: "Living & Lifestyle",
    },
    {
      prevCategory: "Appliances and Tools",
      category: "Appliances and Tools",
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: "Provisioning",
      jar: "Provision",
    },
    {
      category: "Furniture",
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: "Provisioning",
      jar: "Provision",
    },
    {
      prevCategory: "Household Furnishings",
      category: "Furnishings",
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: "Living Lifestyle",
      jar: "Living & Lifestyle",
    },
    {
      prevCategory: "House and Garden maintenance",
      category: "Property Maintenance/Repairs",
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: "Household",
      jar: "Provision",
    },
    {
      category: "Fuel",
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: "Bills",
      jar: "Credit Card",
    },
    {
      prevCategory: "Vehicle Maintenance",
      category: "Vehicle Maintenance",
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: "Bills",
      jar: "Provision",
    },
    {
      category: "Parking and Tolls",
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: "Bills",
      jar: "Credit Card",
    },
    {
      category: "Fares",
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: "Bills",
      jar: "Credit Card",
    },
    {
      category: "Presents and Gifts",
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: "Living Lifestyle",
      jar: "Provision",
    },
    {
      category: "Donations",
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: "Living Lifestyle",
      jar: "Provision",
    },
    {
      category: "Holidays",
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: "Provisioning",
      jar: "Provision",
    },
    {
      category: "Dining out / Takeaway food",
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: "Living Lifestyle",
      jar: "Living & Lifestyle",
    },
    {
      category: "Entertainment",
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: "Living Lifestyle",
      jar: "Living & Lifestyle",
    },
    {
      category: "Sport, recreation and hobbies",
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: "Living Lifestyle",
      jar: "Living & Lifestyle",
    },
    {
      category: "Books, Magazines and Newspapers",
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: "Living Lifestyle",
      jar: "Living & Lifestyle",
    },
    {
      prevCategory: "Digital / Video Purchases or Hire",
      category: "Digital / Video Purchases or Hire",
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: "Living Lifestyle",
      jar: "Living & Lifestyle",
    },
    {
      category: "Alcohol and Tobacco",
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: "Living Lifestyle",
      jar: "Living & Lifestyle",
    },
    {
      category: "Gambling and Lotto",
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: "Living Lifestyle",
      jar: "Living & Lifestyle",
    },
    {
      category: "Other Household Expenses",
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: "Provisioning",
      jar: "Provision",
    },
    {
      category: "Family Related Expenses",
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: "Provisioning",
      jar: "Provision",
    },
    {
      category: "Miscellaneous Expenses",
      tier1: null,
      tier2: null,
      tier3: null,
      tier4: "Provisioning",
      jar: "Provision",
    },
  ],
};

export const EXPENSES_DEFAULT_ARRAY = [
  ...EXPENSES_DEFAULT.bills,
  ...EXPENSES_DEFAULT.spending,
];

// categories in array
export const BILL_CATEGORIES = EXPENSES_DEFAULT.bills.map(
  (expense) => expense.category
);

export const SPENDING_CATEGORIES = EXPENSES_DEFAULT.spending.map(
  (expense) => expense.category
);

export const EXPENSE_CATEGORIES = [...BILL_CATEGORIES, ...SPENDING_CATEGORIES];

// old categories in array
export const OLD_BILL_CATEGORIES = EXPENSES_DEFAULT.bills.map(
  (expense) => expense.prevCategory || expense.category
);

export const OLD_SPENDING_CATEGORIES = EXPENSES_DEFAULT.spending.map(
  (expense) => expense.prevCategory || expense.category
);

export const OLD_EXPENSE_CATEGORIES = [
  ...OLD_BILL_CATEGORIES,
  ...OLD_SPENDING_CATEGORIES,
];

// old default expenses
export const OLD_EXPENSES_DEFAULT = {};

Object.entries(EXPENSES_DEFAULT).forEach(([path, expenses]) => {
  OLD_EXPENSES_DEFAULT[path] = expenses.map((expense) => ({
    ...expense,
    category: expense.prevCategory || expense.category,
  }));
});

export const OLD_EXPENSES_DEFAULT_ARRAY = [
  ...OLD_EXPENSES_DEFAULT.bills,
  ...OLD_EXPENSES_DEFAULT.spending,
];

// map of new to old
export const NEW_TO_OLD_CATEGORIES_MAP = {};

EXPENSES_DEFAULT_ARRAY.forEach((expense) => {
  NEW_TO_OLD_CATEGORIES_MAP[expense.category] =
    expense.prevCategory || expense.category;
});
