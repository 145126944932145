angular
  .module("meanApp")
  .controller("knowlegeCenter-controller", function (
    $scope,
    $stateParams,
    Auth,
    ReactWrapper
  ) {
    "use strict";
    $scope.isAdmin = false;
    Auth.isAdmin().then((isAdmin) => ($scope.isAdmin = isAdmin));

    const wrapper = new ReactWrapper($scope);
    import('HeadlessCMS').then((EmpowerWealthBlog) => {
      let { React, ReactDOM } = EmpowerWealthBlog;
      wrapper.setup(EmpowerWealthBlog);
      wrapper.bind(document.getElementById("react-wrapper-knowledgeCenter"));
      wrapper.mount(EmpowerWealthBlog.default, {
        id: $stateParams.clientId,
        isAdmin: $scope.isAdmin,
      });
    });
  });
