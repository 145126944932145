import { dispatchShowAlertChanges } from "../../modules/util/events";

angular
  .module("meanApp")
  .controller(
    "documentCollection-controller",
    function ($scope, DataM, $stateParams, Auth, $q, $state, ReactWrapper) {
      "use strict";

      $scope.toSave = false;
      $scope.toDiscard = false;
      $scope.showChangesAlert = false;
      $scope.haveChanges = false;
      $scope.isAdmin = false;
      $scope.toStateName = "";
      $scope.stateToChange = false;

      const fetchAuthIsAdmin = Auth.isAdmin();
      const $clientId = $stateParams.clientId;
      const clientData = $clientId
        ? DataM.getClientSummary({ id: $clientId })
        : DataM.getMSSumarry();

      $scope.closeChangesAlert = function (doCancel) {
        $scope.showChangesAlert = false;
        dispatchShowAlertChanges($scope.showChangesAlert);
        
        if (doCancel){
          $scope.toStateName = "";
        }
      };

      $scope.triggerToSave = () => {
        $scope.toSave = true;
        $scope.toDiscard = false;
        $scope.showChangesAlert = false;
        dispatchShowAlertChanges($scope.showChangesAlert);
        $scope.haveChanges = false;
        dispatchShowAlertChanges($scope.showChangesAlert);
        renderReactApp();
      };
      $scope.triggerToDiscard = () => {
        $scope.haveChanges = false;
        $state.go($scope.toStateName, {
          clientId: $clientId
        })
      };

      Auth.isAdmin().then((isAdmin) => ($scope.isAdmin = isAdmin));

      $scope.$watch('haveChanges', () => {

        console.log({
          haveChanges : $scope.haveChanges
        })
      })

      const wrapper = new ReactWrapper($scope);
      const renderReactApp = () => {
        import('Settlements').then((SettlementForm) => {
          let { React, ReactDOM } = SettlementForm;

          $q.all([fetchAuthIsAdmin, clientData]).then(([isAdmin, clientData]) => {
            let params = {
                client_id: $clientId ? $clientId : clientData?.clientSummary?.uid,
                user_email: Auth.getCurrentUser().email,
                user_id: Auth.getCurrentUser()._id,
                user_role: Auth.getCurrentUser().role,
                user_info: Auth.getCurrentUser(),
                client_email:
                  clientData?.clientSummary?.personalInfo?.client1?.email,
                client_info:
                  clientData?.clientSummary?.personalInfo,
                isAdmin: isAdmin,
                url: process.env.API_ENDPOINT,
                
                setContainerToHaveChanges: (value) => {
                  $scope.haveChanges = value;
                },
                toStateProceed : () => {
                  $scope.stateToChange = false;
                  $scope.haveChanges = false;
                  setTimeout(function() {
                    $state.go($scope.toStateName, {
                      clientId: $clientId
                    })
                  }, 1000)
                },
                triggerSave: $scope.toSave,
                triggerDiscard: $scope.toDiscard,
                stateToChange: $scope.stateToChange,
              };
            wrapper.setup(SettlementForm);
            wrapper.mount(SettlementForm.DocumentCollection, params, document.getElementById("react-wrapper-document-collection"));
          });

          $scope.$on("$stateChangeStart", (event, toState) => {
            console.log({
              haveChanges : $scope.haveChanges
            })
            if ($scope.haveChanges) {
              event.preventDefault();
              $scope.showChangesAlert = true;
              dispatchShowAlertChanges($scope.showChangesAlert);
              $scope.toStateName = toState.name
              $scope.stateToChange = true;
            }
            console.log("statechange", { toState });
          });
        });
      };

      renderReactApp();
    }
  );
