// NOTE Copied from field-validation-modal

import { BaseJWT, decodeJWT, verifyJWT } from "./jwt-lib";
import secureSessionStorage from "../../../components/data/secure-session-storage"

const ACCESS_JWT_KEY = "token";

export type AccessJWT = BaseJWT & {
  sid: string;
  uid: string;
  email: string;
  role: string;
  strongAuth: boolean;
};

export function isAccessJWT(
  jwt: BaseJWT & Partial<AccessJWT>
): jwt is AccessJWT {
  return (
    typeof jwt.sid === "string" &&
    typeof jwt.uid === "string" &&
    typeof jwt.email === "string" &&
    typeof jwt.role === "string" &&
    typeof jwt.strongAuth === "boolean"
  );
}

async function getEncodedAccessJWT() {
  const encodedAccessJWT = secureSessionStorage.getItem(ACCESS_JWT_KEY);

  if (encodedAccessJWT) return encodedAccessJWT;

  throw new Error("No Access JWT");
}

/**
 * @param encodedAccessJWT
 * @param decode Base decoder. Use either `verifyJWT` or `decodeJWT`.
 * @returns
 */
export function decodeAccessJWT(
  encodedAccessJWT: string,
  decode: (
    // eslint-disable-next-line no-unused-vars
    encodedJWT: string
  ) => BaseJWT
) {
  const decodedAccessJWT = decode(encodedAccessJWT);

  if (isAccessJWT(decodedAccessJWT)) {
    return {
      encoded: encodedAccessJWT,
      decoded: decodedAccessJWT,
    };
  }

  throw new Error("Invalid Access JWT");
}

async function getAccessJWT(
  decode: (
    // eslint-disable-next-line no-unused-vars
    encodedJWT: string
  ) => BaseJWT
) {
  const encodedAccessJWT = await getEncodedAccessJWT();

  return decodeAccessJWT(encodedAccessJWT, decode);
}

export default async function getVerifiedAccessJWT() {
  return getAccessJWT(verifyJWT);
}

/**
 * This does not check the expiration. Use `getVerifiedAccessJWT` instead.
 */
export async function getDecodedAccessJWT() {
  return getAccessJWT(decodeJWT);
}
