import moment, { Moment } from "moment";
import Decimal from "decimal.js";
import { DecimalOne, DecimalZero } from "./decimal-js-lib";

export function calcCapitalGrowth(
  yearDifference: Decimal,
  valueFrom: Decimal,
  valueTo: Decimal
) {
  return valueTo.div(valueFrom).pow(DecimalOne.div(yearDifference)).minus(1);
}

export function getCapitalGrowth(
  valueFrom: Decimal,
  valueTo: Decimal,
  momentFrom: Moment,
  momentTo = moment()
) {
  const yearDifference = new Decimal(momentTo.diff(momentFrom, "days")).div(
    365
  );

  if (yearDifference.gt(0)) {
    return calcCapitalGrowth(yearDifference, valueFrom, valueTo);
  }

  return DecimalZero;
}
