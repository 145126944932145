angular
  .module("meanApp")
  .controller("moneysmarts-controller", function (
    $scope,
    $stateParams,
    DataM,
    DataMSPortal,
    Auth,
    $rootScope,
    ReactWrapper
  ) {
    "use strict";
    $scope.isAdmin = false;
    Auth.isAdmin().then(isAdmin => ($scope.isAdmin = isAdmin));
    const moneyFitPromise = DataMSPortal.getMoneyFit;
    const wrapper = new ReactWrapper($scope);

    // Store data if moneysmarts-calculation is called before import completes
    let initialData = undefined;
    let loadMoneySmarts = (reqData) => { initialData = reqData;  };
    // $scope.$on("moneysmarts-calculation", function (evt, reqData) {
    //   loadMoneySmarts(reqData);
    // });

    import('MoneySmarts').then((MoneySMARTS) => {
      loadMoneySmarts = (reqData) => {
          wrapper.setup(MoneySMARTS);
          wrapper.bind(document.getElementById("react-wrapper-moneysmarts"));
          wrapper.mount(MoneySMARTS.default, {
            id: $stateParams.clientId,
            forceV1: $stateParams.v1 ? true : false,
            dashboardValues: reqData, 
            isAdmin: $scope.isAdmin,
            moneyFitPromise,
            tabValue: $stateParams.tabValue,
            moorrApiURL: process.env.MOORR_GRAPHQL_API_URL
          });
      };

      // Process pre-loaded data
      // if (initialData) loadMoneySmarts(initialData);
      loadMoneySmarts();
    });
  });