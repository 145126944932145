'use strict';

// Filter
const filterItem = (card) => !card.isArchived;

angular.module('meanApp')
  .factory('FilterArchived', function () {
    const FilterArchived = class {
      static filter (client) {
        const bankAccounts = client?.assets?.bankAccounts?.filter(filterItem) || [];
        const investments = client?.assets?.investments?.filter(filterItem) || [];
        const lifeInsurance = client?.assets?.lifeInsurance?.filter(filterItem) || [];
        const otherAssets = client?.assets?.otherAssets?.filter(filterItem) || [];
        const properties = client?.assets?.properties?.filter(filterItem) || [];
        const superFunds = client?.assets?.superFunds?.filter(filterItem) || [];
        const vehicles = client?.assets?.vehicles?.filter(filterItem) || [];
        const income = {
          ...client?.income,
          client1PersonalIncome: {
            ...client?.income?.client1PersonalIncome,
            currentEmployment: client?.income?.client1PersonalIncome?.currentEmployment?.filter(filterItem) || [],
            pastEmployment: client?.income?.client1PersonalIncome?.pastEmployment?.filter(filterItem) || [],
            other: client?.income?.client1PersonalIncome?.other?.filter(filterItem) || []
          },
          client2PersonalIncome: {
            ...client?.income?.client1PersonalIncome,
            currentEmployment: client?.income?.client2PersonalIncome?.currentEmployment?.filter(filterItem) || [],
            pastEmployment: client?.income?.client2PersonalIncome?.pastEmployment?.filter(filterItem) || [],
            other: client?.income?.client2PersonalIncome?.other?.filter(filterItem) || []
          }
        };
        const expenses = client?.expenses?.expenses?.filter(filterItem) || [];
        const borrowing = client?.borrowings?.borrowing?.filter(filterItem) || [];

        return {
          ...client,
          assets: client.assets
            ? {
                ...client.assets,
                bankAccounts,
                investments,
                lifeInsurance,
                otherAssets,
                properties,
                superFunds,
                vehicles,
              }
            : undefined,
          income,
          expenses: {
            ...client.expenses,
            expenses
          },
          borrowings: {
            ...client.borrowings,
            borrowing
          },
        };
      }
    }

    return FilterArchived;
  });

