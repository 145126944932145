import queryFuturePlansChanges from '../../components/data/moorr/queryFuturePlansChanges'

angular
  .module("meanApp")
  .controller(
    "futurePlansChanges-controller",
    function ($scope, $stateParams, Auth, $q, ReactWrapper) {
      "use strict";
      $scope.isAdmin = false;

      Auth.isAdmin().then((isAdmin) => {
        $scope.isAdmin = isAdmin;
        
        queryFuturePlansChanges(isAdmin ? $stateParams.clientId : undefined).then(data => {
          const wrapper = new ReactWrapper($scope);
          
          import('FieldValidation').then((module) => {
            window.ModalUnansweredFields = module;
            wrapper.setup(ModalUnansweredFields);
            wrapper.bind(document.getElementById("react-wrapper-futurePlansChanges"));
            wrapper.mount(ModalUnansweredFields.FuturePlansChanges, {
                category: $stateParams.category, clientId: $stateParams.clientId, futurePlansProps: {
                  primaryClientName: data?.personalInfo?.client1?.fName,
                  clientLastName: data?.personalInfo?.client1?.lname,
                  secondaryClientName: data?.personalInfo?.client2?.lname
                }
            });
          });
        });
      });
    }
  );
