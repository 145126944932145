angular
  .module("meanApp")
  .controller("online-simulator-controller", function($scope, $stateParams, ReactWrapper) {
    "use strict";

    const wrapper = new ReactWrapper($scope);
    import('OnlineSimulator').then((OnlineSimulator) => {
      wrapper.setup(OnlineSimulator);
      wrapper.bind(document.getElementById("react-wrapper-sim"));
      wrapper.mount(OnlineSimulator.default, {
        clientUID: $stateParams.clientId
      });
    });
  });
