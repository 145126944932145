angular
  .module("meanApp")
  .controller("moneysmartsChanges-controller", function (
    $scope,
    $stateParams,
    Auth,
    ReactWrapper
  ) {
    $scope.isAdmin = false;
    Auth.isAdmin().then(isAdmin => ($scope.isAdmin = isAdmin));

    // Store data if moneysmarts-calculation is called before import completes
    let initialData = undefined;
    let loadMoneyStretch = (reqData) => { initialData = reqData;  };
    $scope.$on("moneysmarts-changes", function (evt, reqData) {
      loadMoneyStretch(reqData);
    });

    import('MoneySmarts').then((MoneyStretchChanges) => {
      loadMoneyStretch = () => {
          wrapper.setup(MoneyStretchChanges);
          wrapper.bind(document.getElementById("react-moneysmarts-changes"));
          wrapper.mount(MoneyStretchChanges.default, {
            id: $stateParams.clientId,
            isAdmin: $scope.isAdmin,
          });
      };

      // Process pre-loaded data
      if (initialData) loadMoneyStretch(initialData);
    });
  });
