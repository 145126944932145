angular.module('meanApp')
.controller('assetsVehiclesCrtl', function($scope) {
  'use strict';
	$scope.vehicles = [
    {'name': '2015 HSV GTS', 'owner':'Kosta','value':'98000'},
    {'name': '2009 Mercedes C250','owner':'Nakul', 'value':'50000'}
  ];
  $scope.textlabel = 'EDIT';
  $scope.editing = false;
  //Replace function adds commas every three digits.
	$scope.totalvalue = '148000'.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

  $scope.totalVehicleValue = function() {
		var index;
		var total=0;
		for	(index = 0; index < $scope.vehicles.length; index++) {
			total+= ($scope.vehicles[index].value);
		}
    $scope.totalvalue = total.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
	};
	/*Deleting a Clients Vehicle*/
	$scope.deleteVehicle = function(vehicle, $index) {
		$scope.vehicles.splice($index,1);
		$scope.totalVehicleValue();
	};
	$scope.saveVehicles = function() {
		$scope.totalVehicleValue();
		$scope.changeText();
		/*Save Vehicles to Database.*/
	};
	$scope.addNewVehicle = function() {
		var vehicle = {'name': $scope.new.model, 'owner':$scope.new.year,'value':$scope.new.value};
		/*Check if all fields have been entered.*/
		if($scope.new.model.localeCompare('') !== 0 && $scope.new.owner.localeCompare('') !== 0 && $scope.new.value.localeCompare('') !== 0) {
			$scope.vehicles.unshift(vehicle);
			$scope.new.model = '';
			$scope.new.year = '';
			$scope.new.value = '';
		}
		$scope.totalVehicleValue();
	};
	$scope.changeText = function() {
		if($scope.editing === true) {
			$scope.textlabel = 'SAVE';
		} else {
			$scope.textlabel = 'EDIT';
		}
	};
	$scope.helloWorld = function() {
		console.log('Empower Wealth Assets');
	};
});
