// 'isMainClient' true if client1, false if client2
import {computeEstTaxPaid} from "../../../config/taxCalculation"
export default {
  
    // Income
    getGrossNontaxableIncome: function (otherIncomes) {
        var incomeValue = 0;
        for (var i = 0; i < otherIncomes.length; i++) {
            var otherIncome = otherIncomes[i];
            if (otherIncome.isTaxFree == true) {
                incomeValue += otherIncome.amount;
            }
        }
        return incomeValue;
    },
    getPreTaxDeduction: function (currentEmployments) {
        var preTaxDeduction = 0;

        for (var i = 0; i < currentEmployments.length; i++) {
            let employmentType = currentEmployments[i].employmentType;
            if(employmentType == "PAYG"){
                var job = currentEmployments[i].job;
                if (job.withPreTax == true) {
                    for(var j = 0; j < job.preTaxes.length; j++){
                        preTaxDeduction += job.preTaxes[j].taxValue;
                    }
                }

            }

        }
        return preTaxDeduction;
    },
    getGrossOtherIncome: function (otherIncomes, currentEmployments) {
        var otherIncomeTotal = 0;

        for (var i = 0; i < otherIncomes.length; i++) {
            var otherIncome = otherIncomes[i];
            if (otherIncome.isTaxFree == false) {
                otherIncomeTotal += otherIncome.amount;
            }
        }
        for (var i = 0; i < currentEmployments.length; i++) {
            var currentEmployment = currentEmployments[i];
            if (currentEmployment.employmentType === 'PAYG' && currentEmployment.job) {
                otherIncomeTotal += currentEmployment.job.overtime ? currentEmployment.job.overtime : 0;
                otherIncomeTotal += currentEmployment.job.commission ? currentEmployment.job.commission : 0;
                otherIncomeTotal += currentEmployment.job.bonus ? currentEmployment.job.bonus : 0;
            }
        }
        return otherIncomeTotal;
    },
    getGrossPAYGSalaryIncome: function (currentEmployments) {
        var totalSalary = 0;
        for (var i = 0; i < currentEmployments.length; i++) {
            var currentEmployment = currentEmployments[i];
            if (currentEmployment.employmentType === 'PAYG' && currentEmployment.job) {
                totalSalary += currentEmployment.job.annualSalary ? currentEmployment.job.annualSalary : 0;
            }
        }
        return totalSalary;
    },
    // Sole Trader income
    getGrossSoleTraderIncome: function (currentEmployments) {
        var totalIncome = 0;
        for (var i = 0; i < currentEmployments.length; i++) {
            var currentEmployment = currentEmployments[i];
            if (currentEmployment.employmentType !== 'PAYG' && currentEmployment.business) {
                if (currentEmployment.business.basis == 'Sole trader') {
                    if (currentEmployment.business.earnings && currentEmployment.business.earnings.length > 0) {
                        totalIncome += _.get(currentEmployment, 'business.earnings[0].salary', 0);
                    }
                }
            }
        }
        return totalIncome;
    },
    // Business income
    getGrossBusinessIncome: function (currentEmployments) {
        var totalIncome = 0;
        for (var i = 0; i < currentEmployments.length; i++) {
            var currentEmployment = currentEmployments[i];
            if (currentEmployment.employmentType !== 'PAYG' && currentEmployment.business) {
                if (currentEmployment.business.basis != 'Sole trader') {
                    if (currentEmployment.business.earnings && currentEmployment.business.earnings.length > 0) {
                        totalIncome += _.get(currentEmployment, 'business.earnings[0].salary', 0);
                    }
                }
            }
        }
        return totalIncome;
    },

    // Rental Income
    getGrossRentalIncome: function (properties, clientId) {
        var totalRentalIncome = 0;
        for (var i = 0; i < properties.length; i++) {
            var property = properties[i];
            if (property.purpose == "Investment Property" || property.purpose == "Investment" || property.purpose == "Business Use") {
                if (property.grossRentalIncome) {
                    if (clientId) {
                        if (property.ownership && property.ownership.owners) {
                            for (var i2 = 0; i2 < property.ownership.owners.length; i2++) {
                                var owner = property.ownership.owners[i2];
                                if (owner.owner == clientId) {
                                    totalRentalIncome += (property.grossRentalIncome * (owner.percentage / 100));
                                }
                            }
                        }
                    } else {
                        totalRentalIncome += (property.grossRentalIncome);
                    }
                }
            }
        }
        return totalRentalIncome;
    },
    // Investment Income
    getGrossInvestmentIncome: function (investments, clientId) {
        var totalInvestmentIncome = 0;
        for (var i = 0; i < investments.length; i++) {
            var investment = investments[i];
            if (investment.yearlyIncome) {
                if (clientId) {
                    if (investment.ownership && investment.ownership.owners) {
                        for (var i2 = 0; i2 < investment.ownership.owners.length; i2++) {
                            var owner = investment.ownership.owners[i2];
                            if (owner.owner == clientId) {
                                totalInvestmentIncome += (investment.yearlyIncome * (owner.percentage / 100));
                            }
                        }
                    }
                } else {
                    totalInvestmentIncome += (investment.yearlyIncome);
                }
            }

        }
        return totalInvestmentIncome;
    },
    // Get total Gross Income
    getGrossIncome: function (personalIncome, assets, clientId, isMainClient = true) {
        var currentEmployments = _.get(personalIncome, 'currentEmployment', []);
        var otherIncomes = _.get(personalIncome, 'other', []);
        var properties = _.get(assets, 'properties', []);
        var investments = _.get(assets, 'investments', []);
        var superFunds = _.get(assets, 'superFunds', []);

        var investmentsIncome = investments ? this.getGrossInvestmentIncome(investments, clientId) : 0;
        var rentalIncome = properties ? this.getGrossRentalIncome(properties, clientId) : 0;
        var superFundSalarySacrifice = this.getSuperAnnuationSalarySacrifice(superFunds, clientId, isMainClient);

        // Income from current employment
        var grossPAYGIncome = currentEmployments ? this.getGrossPAYGSalaryIncome(currentEmployments) : 0;
        var grossSoleTraderIncome = currentEmployments ? this.getGrossSoleTraderIncome(currentEmployments) : 0;
        var grossBusinessIncome = currentEmployments ? this.getGrossBusinessIncome(currentEmployments) : 0;
        var grossOtherIncome = currentEmployments ? this.getGrossOtherIncome(otherIncomes, currentEmployments) : 0;

        var grossIncome = investmentsIncome + rentalIncome + grossPAYGIncome + grossSoleTraderIncome + grossBusinessIncome + grossOtherIncome - superFundSalarySacrifice;


        return grossIncome;
    },
    getTaxableIncome: function (personalIncome, expenses, assets, loans, clientId, isMainClient = true) {

        var currentEmployments = _.get(personalIncome, 'currentEmployment', []);
        var properties = _.get(assets, 'properties', []);

        // Investment Property Deductions
        var investmentPropertyDeduction = this.getInvestmentPropertyDeduction(properties, clientId);

        // Tax deductible expenses
        var taxDeductibleExpenses = this.getTotalTaxDeductibleBills(expenses, clientId);

        var loansDeductible = this.getLoanPropertyDeduction(loans, clientId);

        var grossIncome = this.getGrossIncome(personalIncome, assets, clientId, isMainClient);
        var preTaxDeduction = this.getPreTaxDeduction(currentEmployments);
        var taxableIncome = grossIncome - investmentPropertyDeduction - taxDeductibleExpenses - loansDeductible - preTaxDeduction;

        if (taxableIncome <= 0) {
            taxableIncome = 0;
        }

        return taxableIncome;
    },
    getNetIncome: function (personalIncome, expenses, assets, loans, clientId, isMainClient = true) {
        var currentEmployments = personalIncome.currentEmployment;
        var otherIncomes = personalIncome.other;
        var taxableIncome = this.getTaxableIncome(personalIncome, expenses, assets, loans, clientId, isMainClient);
        var taxPayable = this.computeEstimatedTaxPaid(taxableIncome);
        var grossNontaxableIncome = otherIncomes ? this.getGrossNontaxableIncome(otherIncomes) : 0;
        var grossIncome = this.getGrossIncome(personalIncome, assets, clientId, isMainClient);

        if (taxableIncome <= 0) {
            taxPayable = 0;
        }
        var netIncome = grossIncome + grossNontaxableIncome - taxPayable;
        return netIncome;
    },
    // 'isMainClient' true if client1, false if client2
    getIncomeValues: function (personalIncome, expenses, assets, loans, clientId, isMainClient = true) {

        var currentEmployments = _.get(personalIncome,'currentEmployment',[]);
        var otherIncomes = _.get(personalIncome,'other',[]);
        var superFunds = _.get(assets,'superFunds',[]);
        var properties = _.get(assets,'properties',[]);

        var investmentsIncome = assets.investments ? this.getGrossInvestmentIncome(assets.investments, clientId) : 0;
        var rentalIncome = assets.properties ? this.getGrossRentalIncome(properties, clientId) : 0;

        // Income from current employment
        var grossPAYGIncome = currentEmployments ? this.getGrossPAYGSalaryIncome(currentEmployments) : 0;
        var preTaxDeduction = currentEmployments ? this.getPreTaxDeduction(currentEmployments) : 0;
        var grossSoleTraderIncome = currentEmployments ? this.getGrossSoleTraderIncome(currentEmployments) : 0;
        var grossBusinessIncome = currentEmployments ? this.getGrossBusinessIncome(currentEmployments) : 0;
        var grossOtherIncome = currentEmployments ? this.getGrossOtherIncome(otherIncomes, currentEmployments) : 0;
        var grossNontaxableIncome = otherIncomes ? this.getGrossNontaxableIncome(otherIncomes) : 0;

        var taxableIncome = this.getTaxableIncome(personalIncome, expenses, assets, loans, clientId, isMainClient);
        var taxPayable = this.computeEstimatedTaxPaid(taxableIncome);
        var netIncome = this.getNetIncome(personalIncome, expenses, assets, loans, clientId, isMainClient);
        var superFundSalarySacrifice = this.getSuperAnnuationSalarySacrifice(superFunds, clientId, isMainClient);
        var grossIncome = this.getGrossIncome(personalIncome, assets, clientId, isMainClient);
        var investmentPropertyDeduction = this.getInvestmentPropertyCost(properties, clientId);
        var loansDeductible = this.getLoanPropertyDeduction(loans, clientId);

        if (taxableIncome <= 0) {
            taxPayable = 0;
        }
        investmentPropertyDeduction = loansDeductible + investmentPropertyDeduction;
        var incomeValues = {
            investment: investmentsIncome,
            rental: rentalIncome,
            payg: grossPAYGIncome,
            soleTrader: grossSoleTraderIncome,
            business: grossBusinessIncome,
            other: grossOtherIncome,
            nonTaxable: grossNontaxableIncome,
            taxable: taxableIncome,
            taxPayable: taxPayable,
            superFundSalarySacrifice: superFundSalarySacrifice,
            netIncome: netIncome,
            grossIncome: grossIncome,
            investmentPropertyDeductions: investmentPropertyDeduction,
            preTaxDeduction: preTaxDeduction
        }
        return incomeValues;
    },
    getSuperAnnuationSalarySacrifice: function (superFunds, clientId, isMainClient = true) {
        var totalSacrifice = 0;


        for (var i = 0; i < superFunds.length; i++) {
            var superFund = superFunds[i];

            if (superFund.ownership && superFund.ownership.owners) {

                for (var i2 = 0; i2 < superFund.ownership.owners.length; i2++) {
                    var owner = superFund.ownership.owners[i2];
                    if (owner.owner == clientId) {
                        if (superFund.ownership.ownershipType == 'Sole') {
                            if (isMainClient) {
                                totalSacrifice += superFund.salarySacrificeClient1 ? superFund.salarySacrificeClient1 : superFund.salarySacrificeClient2;
                            } else {
                                totalSacrifice += superFund.salarySacrificeClient2 ? superFund.salarySacrificeClient2 : superFund.salarySacrificeClient1;
                            }
                        } else {
                            if (isMainClient) {
                                totalSacrifice += superFund.salarySacrificeClient1;
                            } else {
                                totalSacrifice += superFund.salarySacrificeClient2;
                            }
                        }
                    }
                }
            }




        }
        return totalSacrifice;
    },
    computeEstimatedTaxPaid: function (totalTaxableIncome) {
      return computeEstTaxPaid(totalTaxableIncome)
    },
    getClientsIncomeValues: function (clientSchema) {
        //personalIncome, expenses, assets, clientId, isMainClient = true
        var client1PersonalIncome = _.get(clientSchema, 'income.client1PersonalIncome', {});
        var expenses = _.get(clientSchema, 'expenses.expenses', []);
        var assets = _.get(clientSchema, 'assets', {});
        var loans = _.get(clientSchema, 'borrowings.borrowing', []);
        var client1Id = clientSchema.personalInfo.client1._id;
        var hasClient2 = clientSchema.personalInfo.client2;

        var client1IncomeValues = this.getIncomeValues(client1PersonalIncome, expenses, assets, loans, client1Id, true);

        var incomeValues = {
            client1: client1IncomeValues
        }

        if (hasClient2) {
            var client2Id = clientSchema.personalInfo.client2._id;
            var client2PersonalIncome = _.get(clientSchema, 'income.client2PersonalIncome', {});
            var client2IncomeValues = this.getIncomeValues(client2PersonalIncome, expenses, assets, loans, client2Id, false);
            incomeValues.client2 = client2IncomeValues;
        }
        return incomeValues;
    }
}
