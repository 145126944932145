'use strict';

angular.module('meanApp')
  .factory('EventHelper', function () {
    const eventRegistry = {};
    const EventHelper = class {
      constructor(p_$scope) {
        if (!p_$scope) {
          console.error("Missing scope: new EventHelper($scope)");
          return;
        }

        this.$scope = p_$scope;
      }

      // Event Handling
      $on(p_eventName, p_handler) {
        // Only allow one event to be bound
        if (this.hasEvent(p_eventName, p_handler)) {
          return;
        }

        // Create Handler
        const handler = (p_args) => {
          if (!this.$scope.$$destroyed) {
            p_handler(p_args);
          }
        }

        // Register
        eventRegistry[p_eventName] = eventRegistry[p_eventName] || [];
        eventRegistry[p_eventName].push({ handler: handler, originalHandler: p_handler, $scope: this.$scope });
        document.addEventListener(p_eventName, handler)

        // Add Cleanup Code (Important!)
        this.$scope.$on('$destroy', () => {
          this.$off(p_eventName, handler);
        });

        // Return remove handler
        return () => {
          this.$off(p_eventName, handler);
        }
      }

      // Raise Event
      $trigger(p_eventName, p_args) {
        const raisedEvent = new CustomEvent(p_eventName, {
          detail: p_args
        });

        document.dispatchEvent(raisedEvent);
      }

      // Check if Event Exists
      hasEvent(p_eventName, p_hander) {
        if (eventRegistry[p_eventName]) {
          eventRegistry[p_eventName].forEach((p_event) => {
            if ((
              p_event.handler == p_hander ||
              p_event.originalHandler == p_hander
            ) && p_event.$scope == this.$scope) {
              return p_event;
            }
          })
        }

        return undefined;
      }

      /**
       * helperInstance.$off('eventName')
       * helperInstance.$off('eventName', eventHandler);
       * helperInstance.$off(eventHandler);
       * @param {string|function} p_removeEvent 
       * @param {function} p_removeHandler 
       */
      $off(p_removeEvent, p_removeHandler) {
        if (typeof (p_removeEvent) == "string" && eventRegistry[p_removeEvent]) {
          // Remove by name
          // Remove by name and handler
          for (var i = 0; i < eventRegistry[p_removeEvent].length; i++) {
            const eventRecord = eventRegistry[p_removeEvent];
            if ((!p_removeHandler ||
              eventRecord.handler == p_removeHandler ||
              eventRecord.originalHandler == p_removeHandler
            ) && eventRecord.$scope == this.$scope) {
              document.removeEventListener(eventName, eventRecord.handler);
              eventRegistry[p_removeEvent].splice(i--, 1);
            }
          }
        } else if (typeof (p_removeEvent) != "string") {
          // Remove by handler (for all Events)
          for (var eventName in eventRegistry) {
            this.$off(eventName, p_removeEvent);
          }
        }
      }
    }

    return EventHelper;
  });

