// NOTE Copied from field-validation-modal

export type BaseJWT = {
  [key: string]: any;
  exp: number;
  iat: number;
};

const fromBase64 = (value: string) => atob(value);

function parseJSON<T = any>(
  value: string,
  check: (parsed: any) => parsed is T
): T {
  const parsed = JSON.parse(value);

  if (check(parsed)) return parsed;

  throw new Error("Invalid JSON string");
}

function getDecodedDataString(encodedJWT: string) {
  const [, encodedData] = encodedJWT.split(".", 2);

  return fromBase64(encodedData);
}

export function isValidJWT(
  decodedToken: Partial<BaseJWT>
): decodedToken is BaseJWT {
  return (
    typeof decodedToken?.exp === "number" &&
    decodedToken.exp * 1000 > Date.now() &&
    typeof decodedToken.iat === "number"
  );
}

/**
 * This checks the expiration.
 */
export function verifyJWT(encodedJWT: string) {
  const decodedDataString = getDecodedDataString(encodedJWT);

  return parseJSON(decodedDataString, isValidJWT);
}

/**
 * This does not check the expiration. Use `isValidJWT` instead.
 */
function isJWT(decodedData: Partial<BaseJWT>): decodedData is BaseJWT {
  return (
    typeof decodedData?.exp === "number" && typeof decodedData.iat === "number"
  );
}

export function decodeJWT(encodedJWT: string) {
  const decodedDataString = getDecodedDataString(encodedJWT);

  return parseJSON(decodedDataString, isJWT);
}
