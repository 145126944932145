import { DocumentNode } from "graphql";
import secureSessionStorage from "../secure-session-storage"
export type MaybeBaseJwt = {
  [key: string]: any;
  exp?: number;
  iat?: number;
};

export type BaseJwt = {
  [key: string]: any;
  exp: number;
  iat: number;
};

export function fromBase64(value: string) {
  return atob(value);
}

export function isJwt(decodedToken: MaybeBaseJwt): decodedToken is BaseJwt {
  return (
    typeof decodedToken?.exp === "number" &&
    decodedToken.exp * 1000 > Date.now() &&
    typeof decodedToken.iat === "number"
  );
}

export function decodeJwt(encodedJwt: string) {
  const [, payload] = encodedJwt.split(".", 2);
  const parsed = JSON.parse(fromBase64(payload));

  if (isJwt(parsed)) return parsed;

  throw new Error("invalid-jwt");
}

function getAccessJwt() {
  const encodedAccessJwt = secureSessionStorage.getItem("token");

  if (encodedAccessJwt && decodeJwt(encodedAccessJwt)) {
    return encodedAccessJwt;
  }

  throw new Error("no-access-jwt");
}

export default async function queryApi<T>(
  query: string | DocumentNode,
  variables?: { [key: string]: any }
) {
  if (!process.env.MOORR_GRAPHQL_API_URL) {
    throw new Error("process.env.MOORR_GRAPHQL_API_URL");
  }

  const anAccessJwt = getAccessJwt();
  let body;
  if (typeof query === "string")
    body = { query, variables };
  else
    body = { query: toGqlString(query as DocumentNode), variables };

  return fetch(process.env.MOORR_GRAPHQL_API_URL, {
    headers: {
      authorization: `Bearer ${anAccessJwt}`,
      "content-type": "application/json",
    },
    body: JSON.stringify(body),
    method: "POST",
  })
    .then((response) => response.json())
    .then((response) => { return { ...response.data, __errors: response.errors }  as T });
}

export const WhitespacesRegex = /\s+/g;

export const gql = (templateString: TemplateStringsArray) => {
  const query = templateString[0];

  return query ? query.trim().replace(WhitespacesRegex, " ") : "";
};

export const toGqlString = (doc: DocumentNode): string => {
  if (!doc?.loc
    || !doc?.loc?.source?.body) {
    return "";
  }

  return (doc.loc && doc.loc.source.body).replace(/(\r|\n)/g, '').replace(/\u200B/g, '').replace(/\s+/g, ' ');
}