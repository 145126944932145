angular.module("meanApp").config(function ($stateProvider) {
  "use strict";
  $stateProvider.state("client-changes", {
    url: "/client-changes/:clientId",
    params: {
      clientId: {
        value: null,
        squash: false,
      },
    },
    templateUrl: "app/clientChanges/client-changes.html",
    controller: "client-changes-controller",
    authenticate: ["admin"],
  });
});
