import queryApi, { gql } from "../graphql/query-api";

type UserGetPasswordResetRequestQueryResult = {
  user: {
    getPasswordResetRequest: {
      expiresAt: string;
      token: string;
      type: {
        vars: {
          name: string;
          value: string;
        }[];
      };
    } | null;
  } | null;
};

const UserGetPasswordResetRequestQuery = gql`
  query ($userId: ObjectId) {
    user(id: $userId) {
      getPasswordResetRequest {
        expiresAt
        token
        type {
          vars {
            name
            value
          }
        }
      }
    }
  }
`;

export default async function queryUserGetPasswordResetRequest(uid: string) {
  return queryApi<UserGetPasswordResetRequestQueryResult>(
    UserGetPasswordResetRequestQuery,
    { userId: uid }
  ).then((response) => {
    if (response.user?.getPasswordResetRequest) {
      return response.user.getPasswordResetRequest;
    } else {
      return undefined;
    }

    return Promise.reject(new Error("invalid-response"));
  });
}
