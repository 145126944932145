/**
 * Created by Konstantino on 29/9/16.
 */
'use strict';

angular.module('meanApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('moneyquiz', {
        url: '/money-quiz/:clientId',
        params: {
          clientId: {
            value: null,
            squash: false
          }
        },
        templateUrl: 'app/myGoals/quiz/money-quiz.html',
        controller: 'money-quiz-controller',
        authenticate: ['user','admin']
      });
  })
  .controller(
    "money-quiz-controller",
    function (
      $scope,
      $state,
      $stateParams,
      $timeout,
      $location,
      DataM,
      Auth,
      $interval,
      empowerDataService,
      $rootScope
    ) {
      "use strict";
      $scope.isAdmin = false;
      Auth.isAdmin().then((isAdmin) => ($scope.isAdmin = isAdmin));

      $scope.quiz = {};
      $scope.score = 0;

      $scope.showClientSpinner = true;
      $scope.loadingMessage = "Retrieving Client Information...";
      $scope.showRetrieveError = false;
      $scope.clientId = $stateParams.clientId;
      $scope.state = $state.current;
      $scope.params = $stateParams;


      $scope.showBottomButtons = false;
      let toCompare = null;
      $scope.initialSurvey = null;
      $scope.showChangesAlert = false;
      $scope.toStateName = "";
      $scope.isSaving = false;

      $scope.closeChangesAlert = function (doCancel) {
        $scope.newLocation = "";
        $scope.showChangesAlert = false;
        $scope.isSaving = false;
        if (doCancel){
          $scope.toStateName = "";
        }
      };

      $scope.dashboardDiscard = async function () {
        $scope.showClientSpinner = true;
        $scope.loadingMessage = "Discarding Client Changes...";
        if ($scope.showChangesAlert) {
          $scope.showChangesAlert = false;
        }

        var promise = new Promise(function (resolve, reject) {
          $timeout(function () {
            $scope.setInputValues(true);
            resolve("done");
          }, 1);
        });

        let result = await promise;
        if (result === "done") {
          $scope.showClientSpinner = false;
          if ($scope.isSaving && $scope.toStateName !== "") {
            $state.go($scope.toStateName, {
              clientId: $scope.clientId,
            });
          } else {
            $scope.showBottomButtons = false;
            $rootScope.setAlertValues(
              true,
              true,
              "Successfully discarded changes!",
              true
            );
          }
        }
      };

      $scope.calculateScore = function () {
        var total = 0;
        for (var index in $scope.quiz) {
          if ($scope.quiz[index] !== "" && index !== "_id") {
            const value =
              typeof index == "undefined" || !$scope.quiz[index]
                ? 0
                : $scope.quiz[index];
            total += parseInt(value, 10);
          }
        }
        $scope.score = isNaN(total) || !total ? 0 : total;
      };

      $scope.assignValues = function (data) {
          if (data.clientSurvey.hasOwnProperty("quiz")) {
            $scope.quiz = data.clientSurvey.quiz;
          }
          $scope.calculateScore();

        $scope.changeBottomButtonState();
      };

      $scope.setInputValues = function (isDiscard) {
        $scope.quiz = {};
        $scope.clientData = undefined;

        if (!isDiscard) {
          DataM.getClientSurvey()
            .then(function (data) {
              $scope.clientData = data;

              if (_.isNil(data.clientSurvey)) {
                // if the user is newly created, this varible is undefined
                // in the database, so we need to add an empty data for it
                // so that it will not cause any inconsistency on comapring
                // initial scope variable to toCompare
                data.clientSurvey = {
                  quiz: {}
                };
              } else {
                if (_.isNil(data.clientSurvey.quiz)) {
                  data.clientSurvey.quiz = {};
                }
              }
              toCompare = data;
              var promise = new Promise(function (resolve, reject) {
                $scope.assignValues(data);
                resolve("done");
              });

              promise.then(function (data) {
                if (data === "done") {
                  $scope.showClientSpinner = false;
                }
              });
            })
            .catch(function (err) {
              console.log("Retrieve error", err);
              $scope.showRetrieveError = true;
            });
        } else {
          var data = JSON.parse($scope.initialSurvey);
          toCompare = data;
          $scope.assignValues(data);
        }
      };

      $scope.setInputValues(false);
      $scope.changeBottomButtonState = function () {
        if ($scope.initialSurvey !== null && toCompare !== null) {
          var newToCompare = JSON.stringify(toCompare, function (key, value) {
            if (key === "$$hashKey") {
              return undefined;
            }
            if (key === "$promise") {
              return undefined;
            }
            if (key === "$resolved") {
              return undefined;
            }
            return value;
          });
          if ($scope.initialSurvey !== newToCompare) {
            $scope.showBottomButtons = true;
          } else {
            $scope.showBottomButtons = false;
          }
        } else if ($scope.initialSurvey === null && toCompare !== null) {
          $scope.initialSurvey = JSON.stringify(
            toCompare,
            function (key, value) {
              if (key === "$$hashKey") {
                return undefined;
              }
              if (key === "$promise") {
                return undefined;
              }
              if (key === "$resolved") {
                return undefined;
              }
              return value;
            }
          );
        }
      };

      $scope.$watch(
        "[quiz]",
        function () {
          $scope.changeBottomButtonState();
        },
        true
      );

      $scope.save = function (location) {
        if ($scope.showChangesAlert) {
          $scope.showChangesAlert = false;
        }
        $scope.showClientSpinner = true;
        $scope.loadingMessage = "Saving Client Information...";

        if (!$scope.clientData || !$scope.clientData.clientSurvey){
          return;
        }

        // create variable in same format as clientSchema
        var clientSurvey = $scope.clientData.clientSurvey;
        var saveClientSurvey = $.extend({}, clientSurvey, { quiz: $scope.quiz });
        var formData = {
          clientSurvey: saveClientSurvey
        };

        //SAVE TO DB
        DataM.saveClientSurvey(formData)
          .then(function (data) {
            if (data._message === "Client validation failed") {
              var errorMessage = "";
              const formattedMessage = DataHelper.formatMongoError(data);
              $scope.showClientSpinner = false;

              $scope.didNextSave = false;
              alert(formattedMessage);
            } else {
              if ($location.$$url.includes("money-quiz")) {
                if ($scope.isSaving && $scope.toStateName !== "") {
                  $state.go($scope.toStateName, {
                    clientId: $scope.clientId,
                  });
                } else {
                  $rootScope.setAlertValues(
                    true,
                    true,
                    "Successfully saved changes!",
                    true
                  );
                  $scope.initialSurvey = null;
                  $scope.initialSurvey = JSON.stringify(
                    toCompare,
                    function (key, value) {
                      if (key === "$$hashKey") {
                        return undefined;
                      }
                      if (key === "$promise") {
                        return undefined;
                      }
                      if (key === "$resolved") {
                        return undefined;
                      }
                      return value;
                    }
                  );
                  $scope.changeBottomButtonState();
                }
              } else {
                $state.go(location, {
                  clientId: $scope.clientId,
                });
              }

              $scope.showClientSpinner = false;
              $rootScope.$broadcast("onSuitabilitySettingSaved");
            }
          })
          .catch(function (err) {
            console.log("test err", err);
            $rootScope.setAlertValues(
              true,
              false,
              "There is a problem accessing the server.",
              true
            );
            alert(
              "There is a problem accessing the server. Please make sure your internet connection is working. If it is working and this problem persist, please report this problem to Empower Wealth team."
            );
            $scope.showClientSpinner = false;
          });
      };

      $scope.savedText = "Save";
      $scope.dashboardSave = function () {
        var location = "moneyquiz";
        if ($scope.isSaving && $scope.toStateName !== "") {
          location = $scope.toStateName;
        }
        $scope.save(location);
      };

      $scope.showAlertValidationModal = function () {
        $scope.alertMessage = $scope.empowerData.saveValidationMessage;
        $scope.showAlertModal = true;
      };
      $scope.closeModal = function () {
        $scope.showAlertModal = false;

        if ($scope.elementToFocus) {
          $timeout($scope.elementToFocus.focus(), 1500);
        }
      };
      $scope.showConfirmSaveModal = function (page) {
        if (!$scope.didNextSave) {
          // $scope.toShowConfirmSaveModal = true;
          $scope.nextPageLocation = page;
        }
      };
      $scope.goNextAndNotSaveModal = function () {
        //$scope.toShowConfirmSaveModal = false;
        $state.go($scope.nextPageLocation, {
          clientId: $scope.clientId,
        });
      };
      $scope.goNextAndSaveModal = function () {
        //$scope.toShowConfirmSaveModal = false;
        $scope.didNextSave = true;
        $scope.save($scope.nextPageLocation);
      };
      $scope.closeConfirmModal = function () {
        //$scope.toShowConfirmSaveModal = false;
        $scope.savedText = "Save";
        $scope.isSaving = false;
      };

      $scope.$on(
        "$stateChangeStart",
        function (event, toState, toParams, fromState, fromParams) {
          if (_.isEqual(toState.name, "login")) {
          } else if (
            toState.url.indexOf("mygoals") == -1 &&
            !$scope.isSaving &&
            !$scope.didNextSave
          ) {
            $scope.isSaving = true;
            $scope.toStateName = toState.name;

            if ($scope.showBottomButtons) {
              $scope.showChangesAlert = true;
              event.preventDefault();
            }
          }
        }
      );
    }
  );
