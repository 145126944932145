

angular
  .module("meanApp")
  .controller("settlements-controller", function($scope, $stateParams, Auth, ReactWrapper) {
    $scope.userType = -1;
    Auth.isAdmin().then(isAdmin => ($scope.isAdmin = isAdmin));

    const wrapper = new ReactWrapper($scope);
    import('Settlements').then((SettlementForm) => {
      function RenderReactSF(params) {
        wrapper.setup(SettlementForm);
        wrapper.bind(document.getElementById("react-wrapper-settlements"));
        wrapper.mount(SettlementForm.default, params);
      }

      let params = {
        id: $stateParams.clientId,
        propertyId: $stateParams.propertyId,
        loanIndex: $stateParams.loanIndex,
        baseUrl: window.location.origin,
        financeProviders: empowerDataService.financeProviders,
        onSubmit: function(msg) {
          if (msg.save) {
            $scope.reloadPage();
            $scope.reloadPropertyLoans();
          }
        },
        onClose: function() {
          params.windowOpen = false;
          RenderReactSF(params);
        }
      };

      RenderReactSF(params);
    });
  });
