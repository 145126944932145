import { triggerGtagEvent } from "moorr-common";

import hasFlag from "../../../components/data/graphql/featureFlags";
import { FLAG_EXPENSE_NEW_CATEGORIES } from "../../../common/constants/flags";
import {
  defaultExpensesDropdown,
  defaultExpensesBills,
  defaultExpensesSpending,
} from "../../../common/constants/expenses/clientStructure";
import { oldDefaultExpensesDropdown } from "../../../common/constants/expenses/clientStructureOld";

angular
  .module("meanApp")
  .controller(
    "step2-controller",
    (
      $scope,
      $rootScope,
      $state,
      $stateParams,
      $filter,
      DataM,
      $window,
      Auth,
      empowerDataService,
      $timeout,
      Idle,
      DataHelper,
      $document,
      DataMSPortal,
      _
    ) => {
      // Feature Flags ---------------------------------------
      $scope.queryFeatureFlag = function () {
        hasFlag(FLAG_EXPENSE_NEW_CATEGORIES).then((response) => {
          $scope.isFlagActiveExpenseNewCategories = response;

          $scope.defaultExpensesDropdown =
            $scope.isFlagActiveExpenseNewCategories
              ? defaultExpensesDropdown
              : oldDefaultExpensesDropdown;

          $scope.defaultExpensesBills = $scope.isFlagActiveExpenseNewCategories
            ? defaultExpensesBills
            : [...empowerDataService.defaultExpensesBills];

          $scope.defaultExpensesSpending =
            $scope.isFlagActiveExpenseNewCategories
              ? defaultExpensesSpending
              : [...empowerDataService.defaultExpensesSpending];
        });
      };

      $scope.queryFeatureFlag();
      // -----------------------------------------------------

      DataM.getSummary().then(function (data) {
        const client = data.clientSummary;
        if(client.surveyComplete) {
          $state.go('dashboard')
        }
      });

      $scope.isAdmin = false;
      Auth.isAdmin().then((isAdmin) => ($scope.isAdmin = isAdmin));

      $scope.onChangeCategory = (expense) => {
        const formattedExpense = $scope.defaultExpensesDropdown.find(
          (item) => item.desc === expense.desc
        );

        for (const key in formattedExpense) {
          expense[key] = formattedExpense[key];
        }
      };

      $scope.defaultExpensesDropdown = [];

      $scope.loadingMessage = "Loading...";
      $scope.isSecondStep = true;
      $scope.empowerData = empowerDataService;
      $scope.isSpendingShown = false;
      $scope.isBillsShown = true;

      $scope.savedText = "Save";

      $scope.stepsState = {
        back: "step1",
        next: "step3",
      };

      $scope.otherExpenses = [];
      $scope.defaultExpensesBills = [];
      $scope.defaultExpensesSpending = [];
      $scope.borrowerList = [];
      $scope.incomeOwnerList = [];

      $scope.loanTypes = [
        "Home Loan - Basic Variable",
        "Home Loan - Standard Variable",
        "Home Loan - Fixed Rate",
        "Home Loan - Line of Credit (Personal Use)",
        "Investment Loan - Basic Variable",
        "Investment Loan - Standard Variable",
        "Investment Loan - Fixed Rate",
        "Investment Loan - Line of Credit (Investment Use)",
        "Credit Card",
        "Car Loan",
        "Personal Loan",
        "ATO or Centrelink Debt",
        "HECS/HELP Debt",
        "Hire Purchase",
        "Lease",
        "Overdraft",
        "Store Card",
        "Commercial Bill",
        "Charge Card",
        "Other",
      ];

      $scope.primaryPurposes = [
        "Buy Real Estate",
        "Refinance Real Estate",
        "Debt Consolidation",
        "General Spending",
        "Other Purchases/Items",
      ];

      $scope.client1Name = "Client";
      $scope.client2Name = "Partner";
      $scope.personalInfo = {};
      $scope.hasPartner = false;

      $scope.incomes = [{}];
      $scope.loans = [];

      $scope.assetIncomes = [];

      $scope.addIncome = function () {
        $scope.incomes.push({
          type: "PAYG",
          // owner: $scope.personalInfo.client1._id,
        });
      };

      $scope.properties = [];
      $scope.investments = [];
      $scope.owners = [];

      $scope.showClientSpinner = false;
      $scope.showErrorMessage = false;
      $scope.error = {};

      $scope.deleteIncome = function (income) {
        $scope.incomes.splice($scope.incomes.indexOf(income), 1);
      };

      $scope.addLoan = function () {
        const loan = { borrowers: $scope.borrowerList[0] ,  repaymentFreq: 'Monthly'};
        $scope.loans.push(loan);
        $scope.assignBorrower(loan, loan.borrowers);
      };

      $scope.addOtherExpense = function () {
        const otherExpense = empowerDataService.newOtherExpense();
        $scope.otherExpenses.push(otherExpense);
      };

      $scope.deleteLoan = function (loan) {
        $scope.loans.splice($scope.loans.indexOf(loan), 1);
      };

      $scope.deleteOtherExpense = function (expense) {
        $scope.otherExpenses.splice($scope.otherExpenses.indexOf(expense), 1);
      };

      $scope.swithExpense = function (expenseType) {
        if (expenseType == "Spending") {
          $scope.isBillsShown = false;
          $scope.isSpendingShown = true;
        }

        if (expenseType == "Bills") {
          $scope.isBillsShown = true;
          $scope.isSpendingShown = false;
        }
      };

      /**
       * Format Form data to server accepted data
       * Client portal format
       */
      $scope.formattedIncomeData = {
        client1PersonalIncome: {
          currentEmployment: [],
          other: [],
        },
        client2PersonalIncome: {
          currentEmployment: [],
          other: [],
        },
      };

      $scope.formatIncome = function () {
        $scope.properties = [];
        $scope.investments = [];

        for (let i = 0; i < $scope.incomes.length; i++) {
          const income = $scope.incomes[i];

          if (income.type == "Rental Income") {
            mapRentalIncome(income, $scope.properties);
          } else if (income.type == "Investment Income") {
            mapInvestmentIncome(income, $scope.investments);
          } else {
            if ($scope.personalInfo.client1._id == income.owner) {
              mapIncome(
                $scope.formattedIncomeData.client1PersonalIncome,
                income
              );
            }

            if (
              $scope.hasPartner &&
              $scope.personalInfo.client2._id == income.owner
            ) {
              mapIncome(
                $scope.formattedIncomeData.client2PersonalIncome,
                income
              );
            }

            if(!income.owner){
              mapIncome(
                $scope.formattedIncomeData.client1PersonalIncome,
                income
              );
            } 
          }
        }
      };

      function mapIncome(employmentArray, income) {
        let employment = {};

        switch (income.type) {
          case "PAYG": {
            const job = {
              basis: "Full time",
              annualSalary: income.amount,
              nature: income.description,
             
            };

            employment = {
              employmentType: "PAYG",
              job,
              noOwner: !income?.owner
            };

            break;
          }

          case "Self Employed": {
            var business = {
              basis: null,
              natureOfWork: income.description,
              earnings: [],
            };

            employment = {
              employmentType: "Self Employed",
              business,
              noOwner: !income?.owner,
              amount: income.amount,
            };

            break;
          }

          case "Business": {
            var business = {
              detailedBasis: income.ownershipType,
              investmentType: "Business",
              natureOfWork: income.description,
              earnings: [],
            };

            employment = {
              employmentType: "Business",
              business,
              noOwner: !income?.owner,
              amount: income.amount,
            };

            break;
          }

          case "Government Income": {
            const otherIncome = {
              type: "Other",
              description: income.description,
              amount: income.amount,
              isTaxFree: income.isTaxFree,
              noOwner: !income?.owner
            };

            employmentArray.other.push(otherIncome);

            break;
          }
          // No default
        }

        if (employment.employmentType) {
          employmentArray.currentEmployment.push(employment);
        }
      }

      function mapRentalIncome(income, propertyArray) {
        const property = {
          purpose: "Investment",
          grossRentalIncome: income.amount,
          ownership: {
            ownershipType: "Sole",
            owners: income.owner ? [
              {
                owner: income.owner,
                percentage: 100,
              },
            ] : [],
          },
          addlInfo: income.description,
        };

        propertyArray.push(property);
      }

      function mapInvestmentIncome(income, investmentArray) {
        const investment = {
          yearlyIncome: income.amount,
          investmentType: "Business",
          ownership: {
            ownershipType: "Sole",
            owners: income.owner ? [
              {
                owner: income.owner,
                percentage: 100,
              },
            ] : [],
          },
          addlInfo: income.description,
        };

        investmentArray.push(investment);
      }

      $scope.assignBorrower = function (loan, owner) {
        const loanObject = $scope.loans[$scope.loans.indexOf(loan)];

        if (owner.name == "Joint") {
          if ($scope.personalInfo.client2) {
            loanObject.borrower = {
              ownershipType: "Joint",
              ownershipDesc: "",
              owners: [
                {
                  percentage: loanObject.percentage1,
                  name: $scope.personalInfo.client1.fName,
                  owner: $scope.personalInfo.client1._id,
                },
                {
                  percentage: loanObject.percentage2,
                  name: $scope.personalInfo.client2.fName,
                  owner: $scope.personalInfo.client2._id,
                },
              ],
            };
          }
        } else if (owner.name == "Tenants in Common") {
          loanObject.borrower = {
            ownershipType: "Tenants in Common",
            ownershipDesc: "",
            owners: [
              {
                percentage: loanObject.percentage1,
                name: $scope.personalInfo.client1.fName,
                owner: $scope.personalInfo.client1._id,
              },
            ],
          };

          if ($scope.personalInfo.client2) {
            const owner2 = {
              percentage: loanObject.percentage2,
              name: $scope.personalInfo.client2.fName,
              owner: $scope.personalInfo.client2._id,
            };

            loanObject.borrower.owners.push(owner2);
          }
        } else if (owner.name == "Other") {
          loanObject.borrower = {
            ownershipType: "Other",
            ownershipDesc: loanObject.ownerOther,
            owners: [
              {
                percentage: loanObject.percentage1,
                name: $scope.personalInfo.client1.fName,
                owner: $scope.personalInfo.client1._id,
              },
            ],
          };
        } else {
          loanObject.otherBorrowerPercentage = null;
          loanObject.otherBorrowerAddlInfo = null;

          loanObject.borrower = {
            ownershipType: "Sole",
            ownershipDesc: "",
            owners: [
              {
                percentage: 100,
                name: owner.name,
                owner: owner.id,
              },
            ],
          };
        }
      };

      // if admin add uid to get request
      const requestPayload = {};

      Auth.isAdmin().then((isAdmin) => {
        if (isAdmin) {
          requestPayload.uid = $stateParams.clientId;
        }

        DataMSPortal.getStep2(requestPayload).then((data) => {
          if (data.personalInfo) {
            $scope.client1Name = getName(data.personalInfo.client1, "first");
            $scope.client2Name = getName(data.personalInfo.client2, "first");

            $scope.borrowerList = DataHelper.getOwnerList(
              data,
              false,
              true,
              true
            );

            $scope.incomeOwnerList = getIncomeOwnerList(data.personalInfo);

            $scope.personalInfo = data.personalInfo;

            if (data.personalInfo.client2) {
              $scope.hasPartner = true;
            }

            if (data.expenses && data.expenses.expenses) {
              mapRetrieveExpenseData(
                data.expenses.expenses,
                $scope.defaultExpensesBills
              );

              mapRetrieveExpenseData(
                data.expenses.expenses,
                $scope.defaultExpensesSpending
              );
            }

            if (data.income) {
              setScopeIncomes(data);

              mapIncomeData(
                data.income.client1PersonalIncome,
                $scope.incomes,
                "1"
              );

              mapIncomeData(
                data.income.client2PersonalIncome,
                $scope.incomes,
                "2"
              );

              mapIncomeData(
                data.income.noOwnershipIncome,
                $scope.incomes,
                null
              );
            }

            if (data.assets && data.assets.properties) {
              if (
                data.assets.properties.length > 0 &&
                $scope.incomes.length === 0
              ) {
                $scope.incomes = [];
              }

              mapRetrievePropertyData(data.assets.properties);
              mapRetrieveInvestmentData(data.assets.investments);
            }

            if (data.borrowings && data.borrowings.borrowing) {
              $scope.loans = DataHelper.formatBorrower(
                data.borrowings.borrowing.filter(
                  (_borrowing) => !_borrowing.isClosed
                ),
                data.personalInfo,
                $scope.borrowerList
              );

              // $scope.loans = data.borrowings.borrowing;
            }

            for (var i = 0; i < $scope.incomes.length; i++) {
              const income = $scope.incomes[i];
              $scope.numbersOnly(income, "amount");
            }

            for (var i = 0; i < $scope.loans.length; i++) {
              const loan = $scope.loans[i];
              $scope.numbersOnly(loan, "outstanding");
              $scope.numbersOnly(loan, "repayment");
              $scope.numbersOnly(loan, "interestRate");
            }
          }
        });
      });

      function getName(client, type) {
        if (client) {
          if (type.toLowerCase() == "first") {
            return client.fName;
          }

          if (type.toLowerCase() == "last") {
            return client.lName;
          }
        }

        return "";
      }

      function setScopeIncomes(data) {
        let hasIncome = false;

        if (
          data.income.client1PersonalIncome &&
          hasAtLeastOneIncome(data.income.client1PersonalIncome)
        ) {
          hasIncome = true;
        }

        if (
          data.income.client2PersonalIncome &&
          hasAtLeastOneIncome(data.income.client2PersonalIncome)
        ) {
          hasIncome = true;
        }

        if (
          data.income.noOwnershipIncome &&
          hasAtLeastOneIncome(data.income.noOwnershipIncome)
        ) {
          hasIncome = true;
        }

        if (
          data.income.noOwnershipIncome &&
          hasAtLeastOneIncome(data.income.noOwnershipIncome)
        ) {
          hasIncome = true;
        }

        if (data.assets && data.assets.properties) {
          if (
            data.assets.properties.length > 0 &&
            $scope.incomes.length === 0
          ) {
            $scope.incomes = [];
          }

         const propertyIncome = checkPropertyData(data.assets.properties);
         const investmentIncome = checkInvestmentData(data.assets.investments);
         if (
            propertyIncome?.length || investmentIncome?.length
          ) {
            hasIncome = true;
          }

        }

        if (hasIncome == true) {
          $scope.incomes = [];
        }
      }

      function getIncomeOwnerList(personalInfo) {
        const incomeOwnerList = [];

        if (personalInfo.client1) {
          const owner1 = {
            owner: personalInfo.client1._id,
            ownerName: personalInfo.client1.fName,
          };

          incomeOwnerList.push(owner1);
        }

        if (personalInfo.client2) {
          const owner2 = {
            owner: personalInfo.client2._id,
            ownerName: personalInfo.client2.fName,
          };

          incomeOwnerList.push(owner2);
        }

        return incomeOwnerList;
      }

      /**
       * Save function
       * send mapped form data to server
       */
      $scope.save = function (stay) {
        $scope.formatIncome();
        $scope.showClientSpinner = true;
        let expenses = [];

        const defaultExpenseBills = $scope.defaultExpensesBills.map((item) => {
          return {
            ...item,
            name: item?.name ? item.name : item.desc,
          };
        });

        const defaultExpensesSpending = $scope.defaultExpensesSpending.map(
          (item) => {
            return {
              ...item,
              name: item?.name ? item.name : item.desc,
            };
          }
        );

        expenses = expenses.concat(defaultExpenseBills);
        expenses = expenses.concat(defaultExpensesSpending);
        expenses = expenses.concat($scope.otherExpenses);

        const step2Data = {
          income: $scope.formattedIncomeData,
          expenses: {
            expenses,
          },
          assets: {
            properties: $scope.properties,
            investments: $scope.investments,
          },
          borrowings: {
            borrowing: $scope.loans,
          },
        };

        saveStep2(step2Data, "step3");
      };

      $scope.saveBorrowings = function () {
        $scope.showClientSpinner = true;

        $scope.formatIncome();

        const step2Data = {
          borrowings: {
            borrowing: $scope.loans,
          },
        };

        saveStep2(step2Data);
        $scope.savedText = "Saved";
      };

      $scope.saveExpenses = function () {
        $scope.showClientSpinner = true;
        let expenses = [];
        expenses = expenses.concat($scope.defaultExpensesBills);
        expenses = expenses.concat($scope.defaultExpensesSpending);
        expenses = expenses.concat($scope.otherExpenses);

        const step2Data = {
          expenses: {
            expenses,
          },
        };

        saveStep2(step2Data);
        $scope.savedText = "Saved";
      };

      $scope.saveIncome = function () {
        $scope.formattedIncomeData = {
          client1PersonalIncome: {
            currentEmployment: [],
            other: [],
          },
          client2PersonalIncome: {
            currentEmployment: [],
            other: [],
          },
        };

        $scope.formatIncome();
        $scope.showClientSpinner = true;

        const step2Data = {
          income: $scope.formattedIncomeData,
          assets: {
            properties: $scope.properties,
            investments: $scope.investments,
          },
        };

        saveStep2(step2Data);
        $scope.savedText = "Saved";
      };

      $scope.isSaving = false;

      function saveStep2(step2Data, path) {

        if($scope.isSaving) return;
        $scope.isSaving = true;


        const USER_MS_ONBOARDING_STEP_2_COMPLETED =
          "onboarding_steps_completed";

          //* fetch surveyLatestStep
          let surveyLatestStep = ""
          DataM.getSummary().then((response) => {
            surveyLatestStep = response?.clientSummary?.surveyLatestStep
            DataMSPortal.saveStep2(step2Data)
            .then((data) => {
  
              if(surveyLatestStep === 1){
                triggerGtagEvent(
                  USER_MS_ONBOARDING_STEP_2_COMPLETED,
                  { type: "moneysmartsStep2" },
                  true
                );
              }
  
              $scope.showClientSpinner = false;
              $scope.isSaving = false;
              if (path) {
                $state.go(path);
              }
            }).catch((error) => {
              console.log("Save error", error);
              $scope.showClientSpinner = false;
              $scope.showErrorMessage = true;
              $scope.error.title = "Save Error";
              $scope.isSaving = false;
  
              $scope.error.message =
                "There seems to be a problem with saving your data. Please try to refresh the page and try again. If error still occurs Please contact an Adviser from Empower Wealth.";
            });
          }).catch((error) => {
            console.log("Save error", error);
            $scope.showClientSpinner = false;
            $scope.showErrorMessage = true;
            $scope.error.title = "Save Error";
            $scope.isSaving = false;

            $scope.error.message =
              "There seems to be a problem with saving your data. Please try to refresh the page and try again. If error still occurs Please contact an Adviser from Empower Wealth.";
          });;

      }

      $scope.closeError = function () {
        $scope.showErrorMessage = false;
      };

      /**
       * ============ MAP Data from Server to show on Forms
       */
      function mapIncomeData(personalIncomeData, incomeArrayHolder, clientNum) {
        if (personalIncomeData) {
          if (personalIncomeData.currentEmployment) {
            for (
              var i = 0;
              i < personalIncomeData.currentEmployment.length;
              i++
            ) {
              const employmentIncome = personalIncomeData.currentEmployment[i];
              const mappedIncomeData = {};
              setOwner(clientNum, mappedIncomeData);

              switch (employmentIncome.employmentType) {
                case "PAYG":
                  mappedIncomeData.type = "PAYG";

                  mappedIncomeData.amount = _.get(
                    employmentIncome,
                    "job.annualSalary",
                    0
                  );

                  mappedIncomeData.description = employmentIncome.job.nature;
                  break;
                case "Business":
                  mappedIncomeData.type = "Business";

                  mappedIncomeData.amount = _.get(
                    employmentIncome,
                    "business.earnings[0].salary",
                    0
                  );

                  mappedIncomeData.description =
                    employmentIncome.business.natureOfWork;

                  break;
                case "Self Employed":
                  mappedIncomeData.type = "Self Employed";

                  mappedIncomeData.amount = _.get(
                    employmentIncome,
                    "business.earnings[0].salary",
                    0
                  );

                  mappedIncomeData.description =
                    employmentIncome.business.natureOfWork;

                  break;
              }

              if (mappedIncomeData.type) {
                $scope.incomes.push(mappedIncomeData);
              }
            }
          }

          if (personalIncomeData.other) {
            for (var i = 0; i < personalIncomeData.other.length; i++) {
              const otherIncome = personalIncomeData.other[i];
              const mappedOtherIncome = {};
              mappedOtherIncome.type = "Government Income";
              mappedOtherIncome.amount = otherIncome.amount;
              mappedOtherIncome.description = otherIncome.description;
              mappedOtherIncome.isTaxFree = otherIncome.isTaxFree;

              setOwner(clientNum, mappedOtherIncome);
              $scope.incomes.push(mappedOtherIncome);
            }
          }
        }
      }

      function hasAtLeastOneIncome(dataIncome) {
        if (
          dataIncome.currentEmployment &&
          dataIncome.currentEmployment.length > 0
        ) {
          return true;
        }

        if (dataIncome.other && dataIncome.other.length > 0) {
          return true;
        }

        return false;
      }

      function setOwner(clientNum, object) {
        if (clientNum == "1") {
          object.owner = $scope.personalInfo.client1._id;
        } else if (clientNum == "2") {
          object.owner =
            $scope.personalInfo.client2 && $scope.personalInfo.client2._id
              ? $scope.personalInfo.client2._id
              : $scope.personalInfo.client1._id;
        } else {
          object.owner = ""
        }
      }

      function mapRetrieveExpenseData(expensesData, expenseArrayHolder) {
        for (var expenseData of expensesData) {
          if (!expenseData.frequency || expenseData.frequency.length === 0) {
            expenseData.frequency = "Monthly";
          }

          if (
            (expenseData.isOnboardingOtherExpense == true ||
              expenseData.desc === "Additional Expenses") &&
            !$scope.otherExpenses.includes(expenseData)
          ) {
            if (
              $scope.otherExpenses.filter(
                (item) => item._id === expenseData._id
              )?.length === 0
            ) {
              expenseData = {
                ...expenseData,
                desc: expenseData.desc || "Rent",
              };

              $scope.otherExpenses.push(expenseData);
            }
          } else {
            for (let j = 0; j < expenseArrayHolder.length; j++) {
              const holderExpense = expenseArrayHolder[j];

              if (
                expenseData.desc === holderExpense.desc &&
                !expenseData.isOnboardingOtherExpense
              ) {
                expenseArrayHolder[j] = expenseData;

                break;
              }
            }
          }
        }
      }

      function mapRetrievePropertyData(propertiesData) {
        if (propertiesData) {
          for (const property of propertiesData) {
            const mappedRentalIncome = {};
            mappedRentalIncome.type = "Rental Income";
            mappedRentalIncome.amount = property.grossRentalIncome;
            mappedRentalIncome.description = property.addlInfo;

            if (
              property.ownership &&
              property.ownership.owners &&
              property.ownership.owners[0]
            ) {
              mappedRentalIncome.owner = property.ownership.owners[0].owner;
            }
            $scope.incomes.push(mappedRentalIncome);

            return mappedRentalIncome
          }
          
        }
      }

      function checkPropertyData(propertiesData) {
        const data = []
        if (propertiesData) {
          for (const property of propertiesData) {
            const mappedRentalIncome = {};
            mappedRentalIncome.type = "Rental Income";
            mappedRentalIncome.amount = property.grossRentalIncome;
            mappedRentalIncome.description = property.addlInfo;

            if (
              property.ownership &&
              property.ownership.owners &&
              property.ownership.owners[0]
            ) {
              mappedRentalIncome.owner = property.ownership.owners[0].owner;
            }

            data.push(mappedRentalIncome)
            
          }
        }

        return data
      }

      function mapRetrieveInvestmentData(investmentData) {
        if (investmentData) {
          for (const investment of investmentData) {
            const mappedInvestmentIncome = {};
            mappedInvestmentIncome.type = "Investment Income";
            mappedInvestmentIncome.amount = investment.yearlyIncome;
            mappedInvestmentIncome.description = investment.addlInfo;

            if (
              investment.ownership &&
              investment.ownership.owners &&
              investment.ownership.owners[0]
            ) {
              mappedInvestmentIncome.owner =
                investment.ownership.owners[0].owner;
            }
            $scope.assetIncomes.push(mappedInvestmentIncome);
            $scope.incomes.push(mappedInvestmentIncome);
          }
        }
      }

      function checkInvestmentData(investmentData) {
        const data = [];
        if (investmentData) {
          for (const investment of investmentData) {
            const mappedInvestmentIncome = {};
            mappedInvestmentIncome.type = "Investment Income";
            mappedInvestmentIncome.amount = investment.yearlyIncome;
            mappedInvestmentIncome.description = investment.addlInfo;

            if (
              investment.ownership &&
              investment.ownership.owners &&
              investment.ownership.owners[0]
            ) {
              mappedInvestmentIncome.owner =
                investment.ownership.owners[0].owner;
            }
            data.push(mappedInvestmentIncome)
          }
        }
        return data

      }

      $scope.numbersOnly = function (object, propertyToChange) {
        let transformedText = "";
        let text = "";

        if (
          object[`${propertyToChange}Str`] &&
          object[`${propertyToChange}Str`].length > 0
        ) {
          text = object[`${propertyToChange}Str`];
        } else if (typeof object[propertyToChange] === "number") {
          text = object[propertyToChange].toString();
        } else {
        }

        if (text) {
          let countDots = (text.match(".") || []).length;
          let textArr = text.split(".");
          countDots = textArr.length;

          if (countDots > 2) {
            textArr = textArr.slice(0, -1);
            text = textArr.join(".");
          }

          transformedText = text.replace(/[^\d.]/g, "");

          transformedText =
            propertyToChange != "interestRate"
              ? `$${transformedText}`
              : `${transformedText}%`;
        }

        let valueStr = transformedText.replace("$", "");
        valueStr = valueStr.replace("%", "");
        object[`${propertyToChange}Str`] = transformedText;
        object[propertyToChange] = Number.parseFloat(valueStr);
      };
    }
  );
