// Secure Session Storage
// Wraps localStorage and encrypts values with a key stored as a session cookie

import Cookies from "js-cookie";
import crypto from "crypto";
import { Buffer } from "buffer";

const algorithm = "aes-256-cbc"; 
const storageKey = "__sec__";
const keyCookieName = "__sec__";

const init = () : string => {
    const key = Cookies.get(keyCookieName);
    let sessionKey = key ? key : undefined;
    if (!sessionKey || getValues(sessionKey) === undefined){
        sessionKey = crypto.randomBytes(32).toString('base64');
        Cookies.set(keyCookieName, sessionKey);
    }

    return sessionKey;
}

const setValues = (values : { [key: string]: any }, sessionKey : string) => {
    const initVector = crypto.randomBytes(16).toString('base64');
    const cipher = crypto.createCipheriv(algorithm, Buffer.from(sessionKey, "base64"), Buffer.from(initVector, "base64"));
    values.__sec_last_updated = (new Date()).getTime();

    const data = Buffer.concat([cipher.update(JSON.stringify(values)), cipher.final()]);
    localStorage.setItem(storageKey, initVector.substring(0, 22) + data.toString('base64'));
}

const getValues = (sessionKey : string) : any => {
    const data = localStorage.getItem(storageKey);
    if (!data) return undefined;

    const initVector = data.substring(0, 22);
    const payload = data.substring(22);

    try {
        const decipher = crypto.createDecipheriv(algorithm, Buffer.from(sessionKey, "base64"), Buffer.from(initVector, "base64"));
        let decryptedData = decipher.update(payload, "base64", "utf-8");
        decryptedData += decipher.final("utf-8");

        const values = JSON.parse(decryptedData);
        if (values.__sec_last_updated && typeof(values.__sec_last_updated) === "number"){
            return values;
        }
    } catch (ex : any) {
        return undefined;
    }

    return undefined;
}

export default class secureSessionStorage {
    static setItem (key : string, value : string){
        const values = getValues(init()) || {};
        values[key] = value;
        setValues(values, init());
    }
    
    static getItem (key : string){
        const values = getValues(init()) || {};
        return values[key];
    }

    static removeItem (key : string){
        const values = getValues(init()) || {};
        delete values[key];
        setValues(values, init());
    }

    static clear (){
        setValues({}, init());
    }

    static valueOf (){
        return getValues(init()) || {};
    }
}