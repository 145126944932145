// NOTE Copied from field-validation-modal

import secureSessionStorage from "../../../components/data/secure-session-storage";
import { BaseJWT, decodeJWT, verifyJWT } from "./jwt-lib";

const REFRESH_JWT_KEY = "rt";

export type RefreshJWT = BaseJWT & {
  sid: string;
  uid: string;
};

export function isRefreshJWT(
  jwt: BaseJWT & Partial<RefreshJWT>
): jwt is RefreshJWT {
  return typeof jwt.sid === "string" && typeof jwt.uid === "string";
}

export async function getEncodedRefreshJWT() {
  const encodedRefreshJWT = secureSessionStorage.getItem(REFRESH_JWT_KEY);

  if (encodedRefreshJWT) return encodedRefreshJWT;

  throw new Error("No Refresh JWT");
}

/**
 * @param encodedRefreshJWT
 * @param decode Base decoder. Use either `verifyJWT` or `decodeJWT`.
 * @returns
 */
export function decodeRefreshJWT(
  encodedRefreshJWT: string,
  decode: (
    // eslint-disable-next-line no-unused-vars
    encodedJWT: string
  ) => BaseJWT
) {
  const decodedRefreshJWT = decode(encodedRefreshJWT);

  if (isRefreshJWT(decodedRefreshJWT)) {
    return {
      encoded: encodedRefreshJWT,
      decoded: decodedRefreshJWT,
    };
  }

  throw new Error("Invalid Refresh JWT");
}

async function getRefreshJWT(
  decode: (
    // eslint-disable-next-line no-unused-vars
    encodedJWT: string
  ) => BaseJWT
) {
  const encodedRefreshJWT = await getEncodedRefreshJWT();

  return decodeRefreshJWT(encodedRefreshJWT, decode);
}

export default async function getVerifiedRefreshJWT() {
  return getRefreshJWT(verifyJWT);
}

/**
 * This does not check the expiration. Use `getVerifiedRefreshJWT` instead.
 */
export async function getDecodedRefreshJWT() {
  return getRefreshJWT(decodeJWT);
}
