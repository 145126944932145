import queryApi, { gql } from "../graphql/query-api";

const mutateSurveyStartedAt = (wrapper: string, args: string) => `
mutation ($data: ClientUpdateData!) {
    me {
        client{
            update(data: $data) {
                surveyStartedAt
            }
        }
        }
}
`;


export default async function mutationSurveyStartedAt() {
    const query = mutateSurveyStartedAt("", "");
    return queryApi<any>(
      query.trim().replace(/\s+/g, ' '),
      { data: { surveyStartedAt: new Date().toISOString(),
        page: "Client Survey" } }
    ).then((response) => {
      const client = response?.me?.client;
      if (response.__errors){
        return response;
      } else if (client) {
        return client;
      } else {
        return response;
      }
  
      return Promise.reject(new Error("invalid-response"));
    });
  }
  
