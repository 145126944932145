'use strict';
angular.module('meanApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('step1', {
        url: '/step1/:clientId',
        params: {
          clientId: {
              value: null,
              squash: false
          }
        },
        templateUrl: 'app/tpcPortal/step1/step1.html',
        controller: 'step1-controller',
        authenticate: ['user', 'admin']
      });
  });
