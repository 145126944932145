import { IRootScopeService } from "angular";
import { IStateService } from "angular-ui-router";
import { CrossLocation, location } from "../util/location";

// eslint-disable-next-line sonarjs/cognitive-complexity
function RouterDecoratorConfig(
  $rootScope: IRootScopeService & { reqData?: { [key: string]: any } },
  $state: IStateService,
  Auth: any,
  $transitions: any
) {
  "ngInject";

  import('FieldValidation').then((module) => {
    let { React, ReactDOM } = module;
    window.ModalUnansweredFields = module;

    if (
      process.env.API_ENDPOINT &&
      "ModalUnansweredFields" in window &&
      typeof ModalUnansweredFields === "object" &&
      typeof ModalUnansweredFields.App === "function"
    ) {
      // NOTE More at "field-validation-modal\src\App.tsx"

      ReactDOM.render(
        React.createElement(ModalUnansweredFields.App, {
          apiBaseUrl: process.env.API_ENDPOINT,
          graphqlUrl: process.env.MOORR_GRAPHQL_API_URL,
          onRequirementData(requirementData, clientSummary) {
            // eslint-disable-next-line no-param-reassign
            $rootScope.reqData = requirementData;

            $rootScope.$broadcast("reqData", requirementData);
            $rootScope.$broadcast("clientSummaryReqData", clientSummary);
          },
          location: {
            go(to: string, parameters?: { [key: string]: any }) {
              return $state.go(to, parameters);
            },

            // LINK field-validation-modal\src\routes\RouteChangeChecker.tsx
            setReactPush(newPush: CrossLocation["pushr"]) {
              location.pushr = newPush;
            },
          },
        }),
        document.querySelector("#react-root")
      );
    }
  })

  // Redirect to login if route requires auth and the user is not logged in, or doesn't have required role
  $rootScope.$on("$locationChangeSuccess", (event, newUrlString) => {
    if (typeof newUrlString === "string") {
      const newUrl = new URL(newUrlString);
      location.pushr(newUrl.pathname + newUrl.search);
    }
  });

  let hasAuthenticated = false;
  $transitions.onBefore({}, function(transition : any) {
    if (
      transition?.router?.viewService?._uiViews &&
      transition?.router?.viewService?._uiViews[0] && 
      !transition?.router?.viewService?._uiViews[0].name
    ){
      gtag('event', 'load_error', {
        'event_label': 'Invalid views',
        'event_category': "viewed"
      });
      window.dataLayer.push(function (){
        window.location.reload();
      })
      
      return true;
    }

    if (hasAuthenticated
      || transition.to().url == "/"
      || transition.from().url == "/"
      || transition.to().url.match(/^(\/public\/|\/invited|\/signup)/i)
      || transition.from().url.match(/^(\/public\/|\/invited|\/signup)/i)){
      return true;
    }

    return Auth.checkCurrentUser().then((user: any) => {
      if (user && user._id) {
        hasAuthenticated = true;
        document.querySelector("#react-root")?.classList.remove("isLoading");
        return true;
      }

      return transition.router.stateService.target('/public/accounts');
    });
  });

  $transitions.onFinish({}, function(transition : any) {
    if (hasAuthenticated ||
        transition.to().url == "/" ||
        transition.from().url == "/" ||
        transition.to().url.match(/^(\/public\/|\/invited|\/signup)/i) ||
        transition.from().url.match(/^(\/public\/|\/invited|\/signup)/i)){
      document.querySelector("#react-root")?.classList.remove("isLoading");
    }
  });
}

export default RouterDecoratorConfig;
