/* eslint-disable */
angular.module('meanApp')
  .controller('LoginCtrl', function ($scope, Auth, $location, $window, $state, $cookies, Client) {
    'use strict';
    $scope.isAdmin = false;
    Auth.isAdmin().then(isAdmin => ($scope.isAdmin = isAdmin));

    $scope.infoBanner=process.env.INFO_BANNER;
    
    $scope.user = {};
    $scope.errors = {};
    $scope.user.email = '';
    $scope.showSpinner = false;
    $scope.redirect = $state.params.redirect;

    // identify url
    $scope.urlType = Auth.getURLType();

    if ($location.$$search && $location.$$search.ffType && $location.$$search.ffType === 'Full') {
      Auth.setFFType($location.$$search.ffType);
    }

    if (Auth.getFFType() === 'Full' && $location.$$url.indexOf('ffType=Full') === -1) {
      $location.url('/?ffType=Full')
    }

    $scope.login = function (form) {
      $scope.submitted = true;
      $scope.showSpinner = true;
      if (form.$valid) {
        $scope.errors = {};
        Auth.login({
          email: $scope.user.email,
          password: $scope.user.password
        })
          .then(async user => {
            $scope.showSpinner = false;
            if (!user.authyConfirmed) {
              if (user.authenticator === "google") {
                $state.go('2fa-qr-register');
              } else {
                $state.go('2fa-register');
              }
            }
            else if (!Auth.getSameOriginToken() || user.authyConfirmed === false) {
              if(user.authenticator === "google"){
                $state.go('2fa-qr-verify', {redirect: $scope.redirect});
              } else {
                $state.go('2fa-verifycode', {redirect: $scope.redirect});
              }
            } else if ((!user.TCaccepted || !user.updateTCaccepted) && user.passwordReset && user.role == 'user') {
              $state.go('terms')
            } else {
              if (!user.passwordReset) {
                $state.go('resetPassword');
              } else {
                Auth.isAdmin().then(async isAdmin => {
                  if (isAdmin) {
                    $state.go('dashboard-search');
                  } else {
                    const client = await new Client.summary({
                      id : user.id
                    }).$promise
                    
                    if(!$scope.redirect || 
                      (client.clientSummary.surveyComplete && ($scope.redirect==="landing" || $scope.redirect==="lite-fact-find" || $scope.redirect==="litefactfind"))) {
                      $state.go('dashboard');
                    } else {
                      try {
                        $state.go($scope.redirect);
                      } catch (err) {
                        $state.go('login');
                      }
                    }
                  }
                });
              }
            }
          })
          .catch(function (err) {
            $scope.errors.other = err.errorType || err.message;
            $scope.showSpinner = false;
            if (Auth.getFFType() === 'Full' && $location.$$url.indexOf('ffType=Full') === -1) {
              $location.url('/?ffType=Full')
            }
          });
      }
    };

    $scope.changeEmail = function () {
      $scope.form.email.$setValidity('invalidEmail', true);
      $scope.errors.email = '';
    };

    $scope.changePassword = function () {
      $scope.form.password.$setValidity('invalidPassword', true);
      $scope.errors.password = '';
    };

    $scope.loginOauth = function (provider) {
      $window.location.href = '/auth/' + provider;
    };
  });
