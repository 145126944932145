angular.module('meanApp')
  .controller('settings-controller', function ($scope, $rootScope, $state, $stateParams, DataM, Auth, $uibModal) {
    $scope.isAdmin = false;
    Auth.isAdmin().then(isAdmin => ($scope.isAdmin = isAdmin));

    $scope.clientId = $stateParams.clientId;
    $scope.showSpinner = true;
    $scope.isSuperAdmin = false;
    $scope.isMBAdmin = false;
    $scope.showCurrentClientMoneySmarts = false;
    $scope.disableAddForm = true;
    $scope.showReferenceId = false;
    $scope.showActivatePartnerAccount = false;
    $scope.activatedPartnerDetails = {};
    $scope.personalInfo = {};
    $scope.isPartnerAccountActivated = false;

    // Initial settings Short fact find
    $scope.showShortFactFind = {
      isEnabled: true,
      name: "ShowShortFactFind",
      description: "Short Fact Find setting",
      settingType: "Switch"
    };

    // Initial settings Opti Mortgage Rate Message
    $scope.optiMortgageRateMessage = {
      isEnabled: false,
      name: "OptiMortgageRateMessage",
      description: "Opti Mortgage Rate Message",
      settingType: "Switch"
    };

    // Initial settings Money Smarts
    $scope.moneySmartsSetting = {
      isEnabled: true,
      selectedOption: "Individual",
      name: "MoneySmartsSetting",
      description: "Money Smarts setting",
      settingType: "Dropdown"
    };
    // Initial settings Document Collection
    $scope.documentCollectionSetting = {
      isEnabled: true,
      selectedOption: "Individual",
      name: "DocumentCollectionSetting",
      description: "Document Collection setting",
      settingType: "Dropdown"
    };
    // Initial settings Document Collection
    $scope.propertyPortfolioSetting = {
      isEnabled: true,
      selectedOption: "Individual",
      name: "PropertyPortfolioSetting",
      description: "Property Portfolio setting",
      settingType: "Dropdown"
    };
    // Initial settings Document Collection
    $scope.maternityVersionSetting = {
      isEnabled: true,
      selectedOption: "Individual",
      name: "MaternityVersionSetting",
      description: "Maternity Version Setting",
      settingType: "Dropdown"
    };

    // set individual Money smarts
    $scope.individualMoneySmart = {
      uid: $scope.clientId,
      moneySmartsEnabled: false
    }
    $scope.individualSuitability = {
      uid: $scope.clientId,
      suitabilityEnabled: false
    }
    // Initial settings for sims options
    $scope.individualPropertyPortfolio = {
      uid: $scope.clientId,
      propertyPortfolioEnabled: false
    }
    $scope.individualMaternityOption = {
      uid: $scope.clientId,
      oldMaternityEnabled: false
    }

    // Initial settings Short fact find
    $scope.individualDocumentCollection = {
      uid: $scope.clientId,
      documentCollectionEnabled: false
    };

    // check if user is admin
    DataM.getMe().then(function (userData) {
      if (userData.role !== 'user' && userData.role !== 'guest') {
        $scope.isAdmin = true;
        if (userData.role === 'super_admin') {
          $scope.isSuperAdmin = true;
        }
        if (userData.role === "mb_admin") {
          $scope.isMBAdmin = true;
        }
      } else {
        // redirect if user
        $scope.isAdmin = false;
        $state.go('dashboard');
      }

    }).catch(function (err) {
      console.log('Retrieve current user error');
    });

    // Check if partner's account is activated
    DataM.getPartnerActivated().then(function (data) {
      $scope.isPartnerAccountActivated = _.get(data, 'isActivated', false);
    }).catch(function (err) {
      console.log('Retrieve current user error');
    });

    DataM.getSettings().then(function (settingsData) {
      $scope.showSpinner = false;

      for (var i = 0; i < settingsData.length; i++) {
        if ($scope.moneySmartsSetting.name === settingsData[i].name) {
          $scope.moneySmartsSetting = settingsData[i];
        }
        if ($scope.showShortFactFind.name === settingsData[i].name) {
          $scope.showShortFactFind = settingsData[i];
        }
        if (settingsData[i].name == "PropertyPortfolioSetting") {
          $scope.propertyPortfolioSetting.selectedOption = settingsData[i].selectedOption;
        }
        if (settingsData[i].name == "DocumentCollectionSetting") {
          $scope.documentCollectionSetting.selectedOption = settingsData[i].selectedOption;
        }
        if ($scope.optiMortgageRateMessage.name === settingsData[i].name) {
          $scope.optiMortgageRateMessage = settingsData[i];
        }
      }
    }).catch(function (err) {
      console.log('error in retrieving settings')
      $scope.showSpinner = false;
    });

    // Check if user has a partner that has an email address
    DataM.getPersonalInfo().then(function (data) {
      $scope.showSpinner = false;
      let partnerEmail = _.get(data, 'personalInfo.client2.email', null);
      if (partnerEmail) {
        $scope.showActivatePartnerAccount = true;
      }
      $scope.personalInfo = _.get(data, 'personalInfo', {});
    }).catch(function (err) {
      console.log('error in retrieving personalInfo')
      $scope.showSpinner = false;
    });

    DataM.getDocumentCollectionSetting($scope.individualDocumentCollection).then(function (settingData) {
      $scope.individualDocumentCollection.documentCollectionEnabled = settingData.documentCollectionEnabled;
    }).catch(function (err) {
      console.log(err);
      $scope.showSpinner = false;
    });

    DataM.getMoneySmartsSetting($scope.individualMoneySmart).then(function (settingData) {
      $scope.individualMoneySmart.moneySmartsEnabled = settingData.moneySmartsEnabled;
    }).catch(function (err) {
      console.log(err);
      $scope.showSpinner = false;
    });

    DataM.getPropertyPortfolioSetting($scope.individualPropertyPortfolio).then(function (settingData) {
      $scope.individualPropertyPortfolio.propertyPortfolioEnabled = settingData.propertyPortfolioEnabled;
    }).catch(function (err) {
      console.log(err);
      $scope.showSpinner = false;
    });

    DataM.getMaternityVersionSetting($scope.individualMaternityOption).then(function (settingData) {
      $scope.individualMaternityOption.oldMaternityEnabled = settingData.oldMaternityEnabled;
    }).catch(function (err) {
      console.log(err);
      $scope.showSpinner = false;
    });

    DataM.getSuitabilitySetting($scope.individualSuitability).then(function (settingData) {
      $scope.individualSuitability.suitabilityEnabled = settingData.suitabilityEnabled;
    }).catch(function (err) {
      console.log(err);
      $scope.showSpinner = false;
    });

    const updateMenu = function () {
      const reactRoot = $('#react-root');
      if (reactRoot.length) {
        const event = new Event('updateSettings');
        reactRoot[0].dispatchEvent(event);
      }
    }

    $scope.setIsShowFactFind = function () {
      // progress 
      $scope.showSpinner = true;
      DataM.saveSetting($scope.showShortFactFind).then(function (settingData) {
        updateMenu();
        $scope.showSpinner = false;

      }).catch(function (err) {
        console.log(err);
        $scope.showSpinner = false;
      });
    }

    $scope.setOptiMortgageRateMessage = function () {
      // progress 
      $scope.showSpinner = true;
      DataM.saveSetting($scope.optiMortgageRateMessage)
        .catch(function (err) {
          console.log(err);
        }).finally(function () {
          $scope.showSpinner = false;
        });
    }

    $scope.setMoneySmartsSetting = function () {
      // progress 
      $scope.showSpinner = true;
      DataM.saveSetting($scope.moneySmartsSetting).then(function (settingData) {
        updateMenu();
        $scope.showSpinner = false;
        $rootScope.$broadcast('onMoneySmartsSettingSaved');
      }).catch(function (err) {
        console.log(err);
        $scope.showSpinner = false;
      });
    }

    $scope.setPropertyPortfolioSetting = function () {
      // progress 
      $scope.showSpinner = true;
      DataM.saveSetting($scope.propertyPortfolioSetting).then(function (settingData) {
        updateMenu();
        $scope.showSpinner = false;
        $rootScope.$broadcast('onPropertyPortfolioSettingSaved');
      }).catch(function (err) {
        console.log(err);
        $scope.showSpinner = false;
      });
    }

    $scope.setMaternityVersionSetting = function () {
      // progress 
      $scope.showSpinner = true;
      DataM.saveSetting($scope.maternityVersionSetting).then(function (settingData) {
        updateMenu();
        $scope.showSpinner = false;
        $rootScope.$broadcast('onMaternityVersionSettingSaved');
      }).catch(function (err) {
        console.log(err);
        $scope.showSpinner = false;
      });
    }


    $scope.setDocumentCollectionSetting = function () {
      // progress 
      $scope.showSpinner = true;
      DataM.saveSetting($scope.documentCollectionSetting).then(function (settingData) {
        updateMenu();
        $scope.showSpinner = false;
        $rootScope.$broadcast('onDocumentCollectionSettingSaved');
      }).catch(function (err) {
        console.log(err);
        $scope.showSpinner = false;
      });
    }

    $scope.setShowClientMoneySmarts = function () {
      $scope.showSpinner = true;
      DataM.saveMoneySmartsSetting($scope.individualMoneySmart).then(function (settingData) {
        updateMenu();
        $scope.individualMoneySmart.moneySmartsEnabled = settingData.moneySmartsEnabled;
        $scope.showSpinner = false;
        $rootScope.$broadcast('onMoneySmartsSettingSaved');
      }).catch(function (err) {
        console.log(err);
        $scope.showSpinner = false;
      });
    }

    $scope.setShowPropertyPortfolio = function () {
      $scope.showSpinner = true;
      DataM.savePropertyPortfolioSetting($scope.individualPropertyPortfolio).then(function (settingData) {
        updateMenu();
        $scope.individualPropertyPortfolio.propertyPortfolioEnabled = settingData.propertyPortfolioEnabled;
        $scope.showSpinner = false;
        $rootScope.$broadcast('onPropertyPortfolioSettingSaved');
      }).catch(function (err) {
        console.log(err);
        $scope.showSpinner = false;
      });
    }

    $scope.setShowOldMaternity = function () {
      $scope.showSpinner = true;
      DataM.saveMaternityVersionSetting($scope.individualMaternityOption).then(function (settingData) {
        updateMenu();
        $scope.individualMaternityOption.oldMaternityEnabled = settingData.oldMaternityEnabled;
        $scope.showSpinner = false;
        $rootScope.$broadcast('onMaternityVersionSettingSaved');
      }).catch(function (err) {
        console.log(err);
        $scope.showSpinner = false;
      });
    }

    $scope.setShowDocumentCollection = function () {
      $scope.showSpinner = true;
      DataM.saveDocumentCollectionSetting($scope.individualDocumentCollection).then(function (settingData) {
        updateMenu();
        $scope.individualDocumentCollection.documentCollectionEnabled = settingData.documentCollectionEnabled;
        $scope.showSpinner = false;
        $rootScope.$broadcast('onDocumentCollectionSettingSaved');
      }).catch(function (err) {
        console.log(err);
        $scope.showSpinner = false;
      });
    }

    $scope.setShowSuitability = function () {
      $scope.showSpinner = true;
      DataM.saveSuitabilitySetting($scope.individualSuitability).then(function (settingData) {
        updateMenu();
        $scope.individualSuitability.suitabilityEnabled = settingData.suitabilityEnabled;
        $scope.showSpinner = false;
        $rootScope.$broadcast('onSuitabilitySettingSaved');
      }).catch(function (err) {
        console.log(err);
        $scope.showSpinner = false;
      });
    }

    $scope.addSuitabilityForm = function () {
      $scope.showSpinner = true;
      DataM.saveSuitability({ suitability: {} }).then(function (data) {
        updateMenu();
        $scope.showSpinner = false;
        getLatestSuitability();
      })
        .catch(function (err) {
          $scope.showSpinner = false;
          console.log('Save Suitability error', err);
        });
    }
    $scope.activatePartner = function () {
      $scope.showSpinner = true;
      let uid = $scope.clientId;
      let partnerEmail = _.get($scope.personalInfo, 'client2.email', null);

      if (partnerEmail && uid) {

        let payload = {
          email: partnerEmail,
          partnerUID: uid
        };

        DataM.createPartner(payload).then(function (data) {
          updateMenu();
          $scope.showSpinner = false;
          $scope.activatedPartnerDetails = data;
          $scope.activatedPartnerDetails.errorMessage = null;
          if (data.success == false) {
            let errorMessage = _.get(data, 'message', 'Unable to create partner account.');
            $scope.activatedPartnerDetails.errorMessage = errorMessage;
          } else {
            $scope.isPartnerAccountActivated = true;
          }
          // open result modal
          $scope.openCreatePartnerResult();
        })

        $scope.showSpinner = false;
      } else {
        $scope.showSpinner = false;
      }
    }
    $scope.openCreatePartnerResult = function () {
      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'app/settings/activate_partner_result.html',
        controller: 'ModalInstanceCtrl',
        resolve: {
          result: function () {
            return $scope.activatedPartnerDetails;
          }
        }
      });
    }
    $scope.openCreatePartner = function () {

      var modalInstance = $uibModal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'app/settings/activate_partner.html',
        controller: 'ModalInstanceCtrl',
        resolve: {
          result: function () {
            return {};
          }
        }
      });

      modalInstance.result.then(function (result) {
        if (result.activate == true) {
          // activate
          $scope.activatePartner();
        }
      }, function () {
        // if cancelled
      });
    };
  });

angular.module('meanApp').controller('ModalInstanceCtrl', function ($scope, $uibModalInstance, result) {

  $scope.result = result;
  $scope.activate = function () {
    $uibModalInstance.close({ activate: true });
  };

  $scope.close = function () {
    $uibModalInstance.dismiss('cancel');
  };
});