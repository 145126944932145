'use strict';

angular.module('meanApp')
  .factory('ReactWrapper', function () {
    const ReactWrapper = class {
      constructor (p_$scope, p_element, p_react, p_reactDOM){
        if (!p_$scope){
          console.error("Missing Scope new ReactWrapper($scope, [React], [ReactDOM]");
          return;
        }

        this.$scope = p_$scope;
        this.element =
          typeof(p_element) === "string" ?
          document.getElementById(p_element) : p_element;
        this.react = p_react;
        this.reactDOM = p_reactDOM;
        this.root = undefined;
        this.props = undefined;

        // Destructor
        p_$scope.$on("$destroy", () => {
          this.unmount();
        });
      }

      // Bind
      bind (p_element){
        this.unmount();
        this.element = typeof(p_element) === "string" ?
          document.getElementById(p_element) : p_element;
      }

      // Unmount
      unmount (){
        if (this.root){
          if (this.reactDOM.createRoot){
            // New React
            this.root.unmount();
            this.root = undefined;
          } else {
            // Legacy Support
            this.reactDOM.unmountComponentAtNode(this.element);
            this.root = undefined;
          }
        }
      }

      // Setup
      setup ({ React, ReactDOM }){
        this.react ||= React;
        this.reactDOM ||= ReactDOM;
      }

      // React DOM Helpers
      mount (p_component, p_props, p_element){
        // Dependency Check
        if (!this.$scope) return console.error('Missing $scope in ReactWrapper.mount');
        if (!this.react || !this.reactDOM) return console.error('Missing React/ReactDOM in ReactWrapper.mount');
        if (!this.element && !p_element) return console.error('Missing element in ReactWrapper.mount');
        if (!p_component) return console.error('Missing component in ReactWrapper.mount(component, props)');

        // Only mount if scope is still alive
        if (this.$scope.$$destroyed) return;
        if (p_element) this.bind(p_element);

        // Unmount
        this.unmount();

        // Re-bind Root & Render
        this.props = { ...p_props };
        let reactElement = this.react.createElement(p_component, this.props);

        if (this.reactDOM.createRoot){
          this.root = this.reactDOM.createRoot(this.element);
          this.root.render(reactElement);
        } else {
          this.reactDOM.render(reactElement, this.element);

          // Note - there is no root returned on this.reactDOM.render (in React < 18) so return a dummy root instead
          this.root = {};
        }
      }

      // React DOM Helpers
      update (p_props){
        // Only update if scope is still alive
        if (this.$scope.$$destroyed) return;

        // IMPORTANT NOTE - ONLY use this method if you have a hook / handler for componentWillReceiveProps to handle the updated props
        // In all other cases, it is safest to remount the component via ReactWrapper.mount
        this.props = { ...this.props, ...p_props };
        let reactElement = this.react.createElement(p_component, this.props);
        this.root.render(reactElement);
      }
    }

    return ReactWrapper;
  });

